import { React, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import "../../../assets/css/main.css";
import { useDispatch } from "react-redux";
import moment from "moment";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import SendIcon from "@mui/icons-material/Send";
import EditEventModal from "./EditEventModal";
import TransferEventModal from "./TransferEventModal";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
// import PlaybackModalEvent from "./PlaybackModalEvent";
import { ColorRing } from "react-loader-spinner";
import EventTableAction from "../../../redux/Action/EventTableActions";
import "./../../../assets/css/AlertEventCard.css";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction";
import { useSelector } from "react-redux";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";
import EventTableModal from "./EventTableModal";
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const EventsCard = ({ incrementCount, title, item }) => {
  const isDarkmode = useSelector((state) => state.commonData.appTheme);
  const playbackCamList = useSelector(
    (state) => state.vmsHelper.cameraIdsForPlayback
  );
  const [show, setShow] = useState(false);
  const [itemRemark, setitemRemark] = useState(item.remark);
  const [modalData, setmodalData] = useState({});
  const [access_permission] = useState(
    JSON.parse(localStorage.getItem("agx_permission")).Alerts ?? []
  );
  const [editModal, setEditModal] = useState(false);
  const [transferModel, setTransferModal] = useState(false);
  const [sendingNotification, setSendingNotification] = useState(false);
  var profile = JSON.parse(localStorage.getItem("profile"));
  const dispatch = useDispatch();
  const handleClick = (data, item) => {
    incrementCount(data, item);
  };
  const handleRemoveClick = (data, item) => {
    setitemRemark({ ...item, remark: "" });
    handleClick("remark", { ...item, remark: "" });
    setCardRemark("");
  };
  EventsCard.propTypes = {
    incrementCount: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    item: PropTypes.object.isRequired,
  };
  const handleClose = () => setShow(false);
  const handleShow = (item, data) => {
    setmodalData(data);
    setShow(true);
  };
  const handleEditEventModal = () => {
    setEditModal(true);
  };
  const handleTransferEventModal = () => {
    setTransferModal(true);
  };
  const [cardRemark, setCardRemark] = useState(item.remark);

  const [playbackModal, setPlaybackModal] = useState(false);

  const handlePlaybackModal = () => {
    setPlaybackModal(true);
  };

  const changeItemRemark = (e) => {
    setCardRemark(e.target.value);
  };

  const converDateInformate = (date) => {
    const dateString = date;
    const parts = dateString.split("T")[0].split("-");
    const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
    return formattedDate;
  };

  const sendNotificationToFacility = (eventId) => {
    setSendingNotification(true);
    dispatch(EventTableAction.sendNotificationToFacility(eventId))
      .then(() => setSendingNotification(false))
      .catch(() => setSendingNotification(false));
  };
  async function handleEventsAction(operation, id) {
    let patchData = {};
    if (operation == "dismiss") {
      patchData.is_false = true;
    }
    if (operation == "resolve") {
      patchData.is_resolved = true;
    }
    if (operation == "revert") {
      patchData.is_false = false;
      patchData.is_resolved = false;
    }
    if (operation == "add_remark") {
      patchData.remark = cardRemark;
      setitemRemark(cardRemark);
    }
    if (operation == "clear_remark") {
      patchData.remark = "";
      setitemRemark("");
    }
    await incrementCount(patchData, id);
    if (operation == "clear_remark") {
      setCardRemark("");
      setitemRemark("");
    }
  }
  const is_playback = playbackCamList.includes(item.camera[1]);
  return (
    <div>
      <div>
        <div key={item.id}>
          <br />
          <div
            className={
              "main-alert-event-card" + isDarkmode
                ? "card-shadow-alert-event-card-dark"
                : "card-shadow-alert-event-card-light"
            }
            style={{
              margin: "0px",
              borderRadius: "10px",
            }}
          >
            <div className="image-div-on-card">
              <div className="icons-above-table-card">
                {profile.services_selected.Alert.includes(
                  "alert_event_playback"
                ) &&
                  is_playback && (
                    <LightTooltip title="Notify Facility">
                      <div
                        className="table-card-icon"
                        onClick={handlePlaybackModal}
                      >
                        <OndemandVideoIcon sx={{ fontSize: "20px" }} />
                      </div>
                    </LightTooltip>
                  )}
                {profile.services_selected.Alert.includes("FacilityAlert") &&
                  access_permission.includes("send_notification_to_facility") &&
                  profile.account.config.Operation !== "True" && (
                    <LightTooltip title="Notify Facility">
                      <button className="table-card-icon">
                        {!sendingNotification ? (
                          <NotificationsActiveIcon
                            sx={{ fontSize: "20px", color: "#fff" }}
                            onClick={() => sendNotificationToFacility(item.id)}
                          />
                        ) : (
                          <ColorRing
                            visible={true}
                            height="35"
                            width="35"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                            colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                          />
                        )}
                      </button>
                    </LightTooltip>
                  )}

                {profile.account.config.Operation === "True" && (
                  <LightTooltip title="Edit Alert">
                    <div
                      className="table-card-icon"
                      onClick={handleEditEventModal}
                    >
                      <EditIcon sx={{ fontSize: "20px" }} />
                    </div>
                  </LightTooltip>
                )}

                {profile.account.config.Operation === "True" && (
                  <LightTooltip title="Transfer Alert">
                    <div
                      className="table-card-icon"
                      onClick={handleTransferEventModal}
                    >
                      <SendIcon sx={{ fontSize: "20px" }} />
                    </div>
                  </LightTooltip>
                )}
              </div>
              <img
                className="event-image-new"
                style={{ height: "160px", width: "100%" }}
                src={item.event_image}
                alt="Card image cap"
                onClick={() => handleShow(true, item)}
              />
            </div>
            <div className="card-details-on-card">
              <div className="alert-event-type-label mt-2">
                <div>
                  {item && item.event_type && (
                    <h4
                      style={{
                        fontSize: "14px",
                        fontWeight: 800,
                        fontFamily: "Roboto",
                      }}
                    >
                      {item.event_type === "pallet_not_use"
                        ? "Pallet Not Used"
                        : (item.event_type.length > 18
                            ? `${item.event_type.slice(0, 18)}...`
                            : item.event_type
                          )
                            .replace(/_/g, " ")
                            .replace(/\b\w/g, (c) => c.toUpperCase())}
                    </h4>
                  )}

                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "0px",
                      margin: "0",
                      color: "var(--card-text-color)",
                    }}
                  >
                    <StoreMallDirectoryIcon
                      sx={{ marginRight: "5px", fontSize: "20px" }}
                    />
                    {(item.facility[0].length > 14
                      ? item.facility[0].slice(0, 14) + ".."
                      : item.facility[0]
                    )
                      .replace(/_/g, " ")
                      .replace(/\b\w/g, (c) => c.toUpperCase())}
                  </p>
                </div>
                <div className="alert-card-time-date">
                  {moment.utc(item.event_recieved_at).format("h:mm A")}
                  <br />
                  {converDateInformate(item.event_recieved_at)}
                </div>
              </div>
              <div className="alert-event-type-label mt-2">
                <div>
                  <p
                    style={{
                      fontSize: "14px",
                      margin: "0",
                      color: "var(--card-text-color)",
                    }}
                  >
                    <CameraAltIcon
                      sx={{ marginRight: "5px", fontSize: "20px" }}
                    />
                    {(item.camera[0].length > 22
                      ? item.camera[0].slice(0, 22) + "..."
                      : item.camera[0]
                    )
                      .replace(/_/g, " ")
                      .replace(/\b\w/g, (c) => c.toUpperCase())}
                  </p>
                </div>
              </div>
              <div>
                {profile.account.config.Operation === "True" && (
                  <div className="alert-even-card-div-loc-chan-message">
                    <p
                      style={{
                        fontSize: "14px",
                        color: "var(--card-text-color)",
                      }}
                    >
                      {item.event_message
                        .replace(/_/g, " ")
                        .replace(/\b\w/g, (c) => c.toUpperCase())}
                    </p>
                  </div>
                )}
              </div>

              <div className="mt-3">
                <div className="col-12 alert-card-div">
                  <div
                    className="col-12 alerts"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="d-flex priority-div-event">
                      <OnlinePredictionIcon sx={{ marginRight: "4px" }} />{" "}
                      Normal
                    </div>
                    <div className="d-flex ">
                      <div style={{ margin: "0 4px" }}>
                        {title === "event" &&
                          access_permission.includes("can_resolve") && (
                            // <LightTooltip title="Resolve">
                            <button
                              onClick={() =>
                                handleEventsAction("resolve", item.id)
                              }
                              type="submit"
                              className="alert-card-reso-btn alert-card-resolve-dismiss"
                            >
                              {" "}
                              Resolve
                            </button>
                            /* </LightTooltip> */
                          )}
                      </div>

                      <div>
                        {title === "event" &&
                          access_permission.includes("can_dismiss") && (
                            // <LightTooltip title="Dismiss">
                            <button
                              onClick={() =>
                                handleEventsAction("dismiss", item.id)
                              }
                              type="submit"
                              className="alert-card-diss-btn alert-card-resolve-dismiss"
                            >
                              Dismiss
                            </button>
                            // </LightTooltip>
                          )}
                      </div>
                      <div>
                        {title === "dissmiss" && (
                          // <LightTooltip title="Revert">
                          <button
                            onClick={() =>
                              handleEventsAction("revert", item.id)
                            }
                            type="submit"
                            className="alert-card-revert-btn alert-card-resolve-dismiss"
                          >
                            {" "}
                            Revert
                          </button>
                          // </LightTooltip>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="pwd col-12  input-remark-alert-event-div"
                  style={{ marginTop: "15px" }}
                >
                  <input
                    className="remark-input col-10 col-xl-9 input-remark-alert-event"
                    value={cardRemark}
                    placeholder={"Remark"}
                    onChange={changeItemRemark}
                  />
                  <span className="pp-viewer col">
                    {itemRemark ? (
                      <CancelIcon
                        onClick={() =>
                          handleEventsAction("clear_remark", item.id)
                        }
                        style={{
                          color: " var(--card-text-color)",
                          fontSize: "30px",
                          marginTop: "2px",
                          cursor: "pointer",
                        }}
                      />
                    ) : (
                      <AddCircleIcon
                        onClick={() =>
                          handleEventsAction("add_remark", item.id)
                        }
                        style={{
                          color: "var(--card-text-color)",
                          fontSize: "30px",
                          marginTop: "2px",
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Modal
        centered
        show={show}
        onHide={handleClose}
        size="xl"
        style={{ zIndex: 9999 }}
      >
        <Modal.Body style={{ backgroundColor: "var( --side-bar-bg-color)" }}>
          <div
            className="card"
            style={{
              backgroundColor: "var( --side-bar-bg-color)",
              border: "none",
            }}
          >
            <div className="alertacard-close-button">
              {" "}
              <img src={cancel_icon} alt="cancel-icon" onClick={handleClose} />
            </div>
            <div className="row no-gutters">
              <div className="col-md-7 col-lg-8">
                <img
                  src={modalData.event_image}
                  className="card-img"
                  alt="Your image description"
                />
              </div>
              <div className="col-md-5 col-lg-4">
                <div
                  className="card-body d-flex flex-column justify-content-between"
                  style={{ height: "100%" }}
                >
                  {modalData && modalData.event_type && (
                    <h3>
                      {modalData.event_type === "pallet_not_use"
                        ? "Pallet Not Used"
                        : modalData.event_type
                            .replace(/_/g, " ")
                            .replace(/\b\w/g, (c) => c.toUpperCase())}
                    </h3>
                  )}

                  <div>
                    <h5 className="card-title">Ticket Number</h5>
                    <div className="modal-card-text-value">{modalData.id}</div>

                    <h5 className="card-title">Message</h5>
                    <div className="modal-card-text-value">
                      {modalData.event_message}
                    </div>
                  </div>
                  <div>
                    <h5 className="card-title">Date</h5>
                    <div className="modal-card-text-value">
                      {modalData.event_recieved_at
                        ? converDateInformate(modalData.event_recieved_at)
                        : ""}
                    </div>
                  </div>
                  <div>
                    <h5 className="card-title">Time</h5>
                    <div className="modal-card-text-value">
                      {" "}
                      {moment.utc(modalData.event_recieved_at).format("h:mm A")}
                    </div>
                  </div>
                  {modalData.camera && modalData.camera.length > 0 && (
                    <div>
                      <h5 className="card-title">Camera</h5>
                      <div className="modal-card-text-value">
                        {" "}
                        {modalData.camera &&
                          modalData.camera.length > 0 &&
                          modalData.camera[0]}
                      </div>
                    </div>
                  )}
                  <div>
                    <h5 className="card-title">Site</h5>
                    <div className="modal-card-text-value">
                      {" "}
                      {modalData.facility &&
                        modalData.facility.length > 0 &&
                        modalData.facility[0]}
                    </div>
                  </div>
                  <div></div>
                  <div className="d-flex justify-content-start ">
                    {" "}
                    {title === "event" && (
                      <button
                        onClick={() => {
                          handleClick("resolve", modalData);
                          handleClose();
                        }}
                        type="submit"
                        className="alert-card-reso-btn-modal"
                      >
                        <span className="alert-card-resolve-dismiss">
                          Resolve
                        </span>
                      </button>
                    )}
                    {title === "dissmiss" && (
                      <button
                        onClick={() => {
                          handleClick("revert", modalData);
                          handleClose();
                        }}
                        type="submit"
                        className="alert-revert-btn-modal"
                      >
                        <span className="alert-card-resolve-dismiss">
                          Revert
                        </span>
                      </button>
                    )}
                    {title === "event" && (
                      <button
                        onClick={() => {
                          handleClick("dismiss", modalData);
                          handleClose();
                        }}
                        type="submit"
                        className="alert-card-diss-btn"
                        style={{ marginLeft: "4px" }}
                      >
                        <span className="alert-card-resolve-dismiss">
                          Dismiss
                        </span>
                      </button>
                    )}
                    {title === "alert" && <div style={{ width: "100%" }}></div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}
      {show && (
        <EventTableModal
          show={show}
          handleClose={handleClose}
          item={item}
          title={title}
          incrementcount={incrementCount}
        />
      )}
      {editModal && (
        <EditEventModal
          item={item}
          show={editModal}
          incrementcount={incrementCount}
          handleClose={() => setEditModal(false)}
        />
      )}
      {transferModel && (
        <TransferEventModal
          item={item}
          show={transferModel}
          incrementcount={incrementCount}
          handleClose={() => setTransferModal(false)}
        />
      )}
      {/* {playbackModal && (
        <PlaybackModalEvent
          item={item}
          show={playbackModal}
          handleClose={() => setPlaybackModal(false)}
        />
      )} */}
    </div>
  );
};

export default EventsCard;
