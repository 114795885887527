import { BACKEND_URL } from "../../routes/config";
class CloudMonitoringEnpoints {
  constructor() {
    this.getNVRstatus = () => {
      return `${BACKEND_URL}/nvr/status/combined`;
    };
    //this api is used to get the camera online , offline statistics based on user profile , nvr and facility
    this.getCameraStats = () => {
      return `${BACKEND_URL}/nvr/camera_stats/`;
    };
  }
}

export default new CloudMonitoringEnpoints();
