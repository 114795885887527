import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import moment from "moment";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/material/styles";
import { useState } from "react";
const converDateInformate = (date) => {
  const dateString = date;
  const parts = dateString.split("T")[0].split("-");
  const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
  return formattedDate;
};

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import UndoIcon from "@mui/icons-material/Undo";
import ReactCompareImage from "react-compare-image";
let leftImages = {
  10408:
    "https://analytics-data-collection.s3.ap-south-1.amazonaws.com/pantaloons_images/captured_image_channel6.jpg",
  10413:
    "https://analytics-data-collection.s3.ap-south-1.amazonaws.com/pantaloons_images/captured_image_channel11.jpg",
  10415:
    "https://analytics-data-collection.s3.ap-south-1.amazonaws.com/pantaloons_images/captured_image_channel13.jpg",
  10416:
    "https://analytics-data-collection.s3.ap-south-1.amazonaws.com/pantaloons_images/captured_image_channel14.jpg",
  8441: "https://analytics-data-collection.s3.ap-south-1.amazonaws.com/pantaloons_images/captured_image_channel6.jpg",
  8446: "https://analytics-data-collection.s3.ap-south-1.amazonaws.com/pantaloons_images/captured_image_channel11.jpg",
  8448: "https://analytics-data-collection.s3.ap-south-1.amazonaws.com/pantaloons_images/captured_image_channel13.jpg",

  8449: "https://analytics-data-collection.s3.ap-south-1.amazonaws.com/pantaloons_images/captured_image_channel14.jpg",
};

const AlertTableModal = ({
  show,
  handleClose,
  item,
  title,
  incrementcount,
}) => {
  const [access_permission] = useState(
    JSON.parse(localStorage.getItem("agx_permission")).Alerts ?? []
  );
  const handleAlertChange = (action) => {
    const obj = {
      is_false: action === "dismiss",
      is_resolve: action === "resolve",
    };
    console.log(obj); // Replace this with your action logic
  };
  function handleAlertAction(operation, id) {
    let patchData = {};
    if (operation == "dismiss") {
      patchData.is_false = true;
    }
    if (operation == "resolve") {
      patchData.is_resolved = true;
    }
    if (operation == "revert") {
      patchData.is_false = false;
      patchData.is_resolved = false;
    }

    incrementcount(patchData, id);
  }
  const handleClick = (data, item) => {
    incrementcount(data, item);
    handleClose();
  };
  function formatText(inputText) {
    return inputText
      .replace(/_/g, " ")
      .replace(/\b\w/g, (c) => c.toUpperCase());
  }

  const imageSrc =
    item.alert_type === "device_offline_alert"
      ? "https://analytics-data-collection.s3.ap-south-1.amazonaws.com/alerts/device_offline_alert.png"
      : `https://backendnarayana.agrexai.com${item.alert_image}`;
  const isNVRAlertType = [
    "nvr_offline_alert",
    "hdd_not_recording",
    "hdd_health",
  ].includes(item.alert_type);
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={show}
      maxWidth={"lg"}
    >
      <DialogTitle
        sx={{ m: 0, p: 2 }}
        id="customized-dialog-title"
        className="header-background modal-title-color"
      >
        Alert
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "white",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        dividers
        style={{ backgroundColor: "var(--side-bar-bg-color)" }}
      >
        <div
          className="card"
          style={{
            backgroundColor: "var(--side-bar-bg-color)",
            border: "none",
          }}
        >
          <div className="row no-gutters">
            <div className="col-md-7 col-lg-8">
              {item.alert_type !== "layout_change_alert" ? (
                <img src={imageSrc} className="card-img" alt="Alert" />
              ) : leftImages[item.camera[1]] ? (
                <div className="alert-compare-image">
                  <ReactCompareImage
                    leftImage={leftImages[item.camera[1]]}
                    rightImage={imageSrc}
                    aspectRatio="wider"
                  />
                </div>
              ) : (
                <img src={imageSrc} className="card-img" alt="Alert" />
              )}
            </div>

            <div className="col-md-5 col-lg-4">
              <div
                className="card-body d-flex flex-column justify-content-between col-10 "
                style={{ height: "100%", minWidth: "100%" }}
              >
                {item.alert_type && <h4>{formatText(item.alert_type)}</h4>}

                <AlertField title="Ticket Number" value={item.id} />
                <AlertField
                  title="Site"
                  value={
                    item.facility && item.facility.length > 0
                      ? formatText(item.facility[0])
                      : ""
                  }
                />
                {isNVRAlertType && item.nvr && item.nvr.name ? (
                  <AlertField
                    title="NVR/DVR"
                    value={formatText(item.nvr.name)}
                  />
                ) : (
                  item.camera &&
                  item.camera.length > 0 && (
                    <AlertField
                      title="Camera"
                      value={formatText(item.camera[0])}
                    />
                  )
                )}

                <AlertField
                  title="Message"
                  value={item.alert_message
                    .replace(/_/g, " ")
                    .replace(/\b\w/g, (c) => c.toUpperCase())}
                />
                <AlertField
                  title="Date"
                  value={converDateInformate(item.alert_recieved_at)}
                  icon={<CalendarTodayIcon />}
                />
                <AlertField
                  title="Time"
                  value={moment.utc(item.alert_recieved_at).format("h:mm A")}
                  icon={<AccessTimeIcon />}
                />

                <div className="d-flex justify-content-end">
                  {title === "alert" &&
                    access_permission.includes("can_resolve") && (
                      <button
                        onClick={() => {
                          handleAlertAction("resolve", item.id);
                          handleClose();
                        }}
                        type="submit"
                        className="alert-card-reso-btn alert-card-resolve-dismiss"
                      >
                        <DoneIcon />
                        Resolve
                      </button>
                    )}
                  {title === "alert" &&
                    access_permission.includes("can_dismiss") && (
                      <button
                        onClick={() => {
                          handleAlertAction("dismiss", item.id);
                          handleClose();
                        }}
                        type="submit"
                        className="alert-card-diss-btn alert-card-resolve-dismiss"
                        style={{ marginLeft: "4px" }}
                      >
                        <CloseIcon />
                        Dismiss
                      </button>
                    )}
                  {title === "dissmiss" && (
                    <button
                      onClick={() => {
                        handleAlertAction("revert", item.id);
                        handleClose();
                      }}
                      type="submit"
                      className="alert-card-revert-btn alert-card-resolve-dismiss"
                    >
                      <UndoIcon />
                      Revert
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const AlertField = ({ title, value, icon }) => (
  <div className="alert-main-field-modal">
    {icon && <div className="alert-field-icon">{icon}</div>}
    <div className="card-title-modal">{title}: </div>
    <div className="modal-card-text-value">{value}</div>
  </div>
);
AlertField.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  icon: PropTypes.element,
};

AlertTableModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  incrementcount: PropTypes.func.isRequired,
};

export default AlertTableModal;
