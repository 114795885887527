import axios from "axios";
import EventApiSpec from "../features/EventEndPoints";
import {
  notifiToasterSuccess,
  notifiToasterWarn,
} from "../../components/common/UseToast";

// get Store

class EventAction {
  // total Events hourly funtions
  static getTotalEventByHourlyByAccount(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };

      let graph_type = "total";
      axios
        .get(
          EventApiSpec.getTotalEventHourlyByAccount(
            data.id,
            graph_type,
            data.Event_type,
            data.date
          ),
          { headers }
        )
        .then((response) => {
          dispatch({
            type: "TOTAL_EVENT_HOURLY",
            payload: response.data,
            date: data.date,
          });
        });
    };
  }
  static getTotalEventByHourlyByStore(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      let graph_type = "total";
      axios
        .get(
          EventApiSpec.getTotalEventHourlyByStore(
            data.id,
            graph_type,
            data.Event_type,
            data.date
          ),
          { headers }
        )
        .then((response) => {
          dispatch({
            type: "TOTAL_EVENT_HOURLY",
            payload: response.data,
            date: data.date,
          });
        });
    };
  }
  static getTotalEventByHourlyByCamera(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      let graph_type = "total";
      axios
        .get(
          EventApiSpec.getTotalEventHourlyByCamera(
            data.id,
            graph_type,
            data.Event_type,
            data.date
          ),
          { headers }
        )
        .then((response) => {
          dispatch({
            type: "TOTAL_EVENT_HOURLY",
            payload: response.data,
            date: data.date,
          });
        });
    };
  }

  // total weekly resolved and unresolved

  static getWeeklyEventByHourlyByAccount(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };

      axios
        .get(
          EventApiSpec.getTotalEventWeeklyByAccount(
            data.id,
            data.Event_type,
            data.date
          ),
          { headers }
        )
        .then((response) => {
          dispatch({
            type: "TOTAL_WEEKLY_EVENETS",
            payload: response.data,
            date: data.date,
          });
        });
    };
  }
  static getWeeklyEventByHourlyByStore(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      axios
        .get(
          EventApiSpec.getTotalEventWeeklyByStore(
            data.id,
            data.Event_type,
            data.date
          ),
          { headers }
        )
        .then((response) => {
          dispatch({
            type: "TOTAL_WEEKLY_EVENETS",
            payload: response.data,
            date: data.date,
          });
        });
    };
  }
  static getWeeklyEventByHourlyByCamera(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      axios
        .get(
          EventApiSpec.getTotalEventWeeklyByCamera(
            data.id,
            data.Event_type,
            data.date
          ),
          { headers }
        )
        .then((response) => {
          dispatch({
            type: "TOTAL_WEEKLY_EVENETS",
            payload: response.data,
            date: data.date,
          });
        });
    };
  }
  // top seven site Events

  static getTopSevenEvents(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      axios
        .get(EventApiSpec.getTopSevenEvenSitesevents(data.id, data.date), {
          headers,
        })
        .then((response) => {
          dispatch({
            type: "TOP_SEVEN_SITE_EVENTS",
            payload: response.data,
            date: data.date,
          });
        });
    };
  }

  // get total Events type funtion
  static getTotalEventTypeByAccount(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };

      axios
        .get(EventApiSpec.getTotalEventTypeByAccount(data.id, data.date), {
          headers,
        })
        .then((response) => {
          dispatch({
            type: "TOTAL_EVENTS_TYPE_DATA",
            payload: response.data,
            date: data.date,
          });
        });
    };
  }
  static getTotalEventTypeByStore(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      axios
        .get(EventApiSpec.getTotalEventTypeByStore(data.id, data.date), {
          headers,
        })
        .then((response) => {
          dispatch({
            type: "TOTAL_EVENTS_TYPE_DATA",
            payload: response.data,
            date: data.date,
          });
        });
    };
  }
  static getTotalEventTypeByCamera(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      axios
        .get(EventApiSpec.getTotalEventTypeByCamera(data.id, data.date), {
          headers,
        })
        .then((response) => {
          dispatch({
            type: "TOTAL_EVENTS_TYPE_DATA",
            payload: response.data,
            date: data.date,
          });
        });
    };
  }

  // get ten side Events rendom

  static getTenSiteEventsAccount(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };

      axios
        .get(EventApiSpec.getTotalEventTypeByAccount(data.id, data.date), {
          headers,
        })
        .then((response) => {
          dispatch({
            type: "TOTAL_WEEKLY_EVENTS",
            payload: response.data,
            date: data.date,
          });
        });
    };
  }
  static getTenSiteEventsStore(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      axios
        .get(EventApiSpec.getTotalEventTypeByStore(data.id, data.date), {
          headers,
        })
        .then((response) => {
          dispatch({
            type: "TOTAL_WEEKLY_EVENTS",
            payload: response.data,
            date: data.date,
          });
        });
    };
  }
  // funstion to get search alerts

  static getEventsSearchByAccount(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      axios
        .get(
          EventApiSpec.getEventSearchByAccount(
            data.id,
            data.event_type,
            data.search_item,
            data.page_no,
            data.start_time,
            data.end_time,
            data.if_false,
            data.is_resolve,
            data.date,
            data.page_size
          ),

          {
            headers,
          }
        )
        .then((response) => {
          dispatch({
            type: "TABLE_DATA_EVENT",
            payload: response.data,
            date: data.date,
          });
        });
    };
  }
  static getEventsSearchByStore(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      axios
        .get(
          EventApiSpec.getEventSearchByStore(
            data.id,
            data.event_type,
            data.search_item,
            data.page_no,
            data.start_time,
            data.end_time,
            data.if_false,
            data.is_resolve,
            data.date,
            data.page_size
          ),

          {
            headers,
          }
        )
        .then((response) => {
          dispatch({
            type: "TABLE_DATA_EVENT",
            payload: response.data,
            date: data.date,
          });
        });
    };
  }
  static getEventsSearchByCamera(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      axios
        .get(
          EventApiSpec.getEventSearchByCamera(
            data.id,
            data.event_type,
            data.search_item,
            data.page_no,
            data.start_time,
            data.end_time,
            data.if_false,
            data.is_resolve,
            data.date,
            data.page_size
          ),

          {
            headers,
          }
        )
        .then((response) => {
          dispatch({
            type: "TABLE_DATA_EVENT",
            payload: response.data,
            date: data.date,
          });
        });
    };
  }
  static getEventsSearchByAccountDateRange(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      axios
        .get(
          EventApiSpec.getEventSearchByAccountDateRange(
            data.id,
            data.event_type,
            data.search_item,
            data.page_no,
            data.start_date,
            data.end_date,
            data.if_false,
            data.is_resolve,
            data.page_size
          ),

          {
            headers,
          }
        )
        .then((response) => {
          dispatch({
            type: "TABLE_DATA_EVENT",
            payload: response.data,
            date: data.date,
          });
        });
    };
  }
  static getEventsSearchByStoreDateRange(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      axios
        .get(
          EventApiSpec.getEventSearchByStoreDateRange(
            data.id,
            data.event_type,
            data.search_item,
            data.page_no,
            data.start_date,
            data.end_date,
            data.if_false,
            data.is_resolve,
            data.page_size
          ),

          {
            headers,
          }
        )
        .then((response) => {
          dispatch({
            type: "TABLE_DATA_EVENT",
            payload: response.data,
            date: data.date,
          });
        });
    };
  }
  static getEventsSearchByCameraDateRange(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      axios
        .get(
          EventApiSpec.getEventSearchByCameraDateRange(
            data.id,
            data.event_type,
            data.search_item,
            data.page_no,
            data.start_date,
            data.end_date,
            data.if_false,
            data.is_resolve,
            data.page_size
          ),

          {
            headers,
          }
        )
        .then((response) => {
          dispatch({
            type: "TABLE_DATA_EVENT",
            payload: response.data,
            date: data.date,
          });
        });
    };
  }
}

export default EventAction;
export const getLocalDataDownloadEventByAccount = (data) => {
  return new Promise((resolve, reject) => {
    var token = localStorage.getItem("token");
    const profile = JSON.parse(localStorage.getItem("profile"));
    let headers = {
      Authorization: `Token ${token}`,
    };
    axios
      .get(
        EventApiSpec.getAlertsDataDownloadByAccount(
          data.id,
          data.event_type,
          data.date
        ),
        {
          headers,
        }
      )
      .then((response) => {
        notifiToasterSuccess("Success");
        resolve(response);
      })
      .catch((error) => {
        notifiToasterWarn("failed");
        reject(error);
      });
  });
};

export const getLocalDataDownloadEventByStore = (data) => {
  return new Promise((resolve, reject) => {
    var token = localStorage.getItem("token");
    const profile = JSON.parse(localStorage.getItem("profile"));
    let headers = {
      Authorization: `Token ${token}`,
    };
    axios
      .get(
        EventApiSpec.getAlertsDataDownloadByStore(
          data.id,
          data.event_type,
          data.date
        ),
        {
          headers,
        }
      )
      .then((response) => {
        notifiToasterSuccess("Success");
        resolve(response);
      })
      .catch((error) => {
        notifiToasterWarn("failed");
        reject(error);
      });
  });
};
export const getLocalDataDownloadEventByCamera = (data) => {
  return new Promise((resolve, reject) => {
    var token = localStorage.getItem("token");

    let headers = {
      Authorization: `Token ${token}`,
    };
    axios
      .get(
        EventApiSpec.getAlertsDataDownloadByCamera(
          data.id,
          data.event_type,
          data.date
        ),
        {
          headers,
        }
      )
      .then((response) => {
        notifiToasterSuccess("Success");
        resolve(response);
      })
      .catch((error) => {
        notifiToasterWarn("failed");
        reject(error);
      });
  });
};

export const updateEventsObjByAccount = (obj, data) => {
  return new Promise((resolve, reject) => {
    var token = localStorage.getItem("token");
    let headers = {
      Authorization: `Token ${token}`,
    };
    axios
      .put(
        EventApiSpec.updatEventsObjByAccount(
          obj.id,
          obj.event_type,
          obj.date,
          obj.start_time,
          obj.end_time,
          obj.page_no,
          obj.if_false,
          obj.is_resolve,
          obj.page_size
        ),
        data,
        { headers }
      )
      .then((response) => {
        notifiToasterSuccess("Updated");
        resolve(response);
      })
      .catch((error) => {
        notifiToasterWarn("failed");
        reject(error);
      });
  });
};

export const updateEventsObjByStore = (obj, data) => {
  return new Promise((resolve, reject) => {
    var token = localStorage.getItem("token");
    let headers = {
      Authorization: `Token ${token}`,
    };
    axios
      .put(
        EventApiSpec.updateEventObjByStore(
          obj.id,
          obj.event_type,
          obj.date,
          obj.start_time,
          obj.end_time,
          obj.page_no,
          obj.if_false,
          obj.is_resolve,
          obj.page_size
        ),
        data,
        { headers }
      )
      .then((response) => {
        notifiToasterSuccess("Updated");
        resolve(response);
      })
      .catch((error) => {
        notifiToasterWarn("failed");
        reject(error);
      });
  });
};

export const updateEventsObjByCamera = (obj, data) => {
  return new Promise((resolve, reject) => {
    var token = localStorage.getItem("token");
    let headers = {
      Authorization: `Token ${token}`,
    };
    axios
      .put(
        EventApiSpec.updateEventsObjByCamera(
          obj.id,
          obj.event_type,
          obj.date,
          obj.start_time,
          obj.end_time,
          obj.page_no,
          obj.if_false,
          obj.is_resolve,
          obj.page_size
        ),
        data,
        { headers }
      )
      .then((response) => {
        notifiToasterSuccess("Updated");
        resolve(response);
      })
      .catch((error) => {
        notifiToasterWarn("failed");
        reject(error);
      });
  });
};
