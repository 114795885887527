import React, { useState, useEffect } from "react";
import EmailIcon from "@mui/icons-material/Email";
import DownloadIcon from "@mui/icons-material/Download";
import { ColorRing } from "react-loader-spinner";
import { reportDownloadByMail } from "../../redux/Action/CommonAction";
import cloudMonitoringAction from "../../redux/Action/CloudMonitoringAction";
import mainCss from "../../assets/css/mainCss";
import PropTypes from "prop-types";

const DownloadAndEmailReport = ({
  selectedType,
  selectedStore,
  selectedNvr,
  searchValue,
}) => {
  const [access_permission, setAccessPermission] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingg, setIsLoadingg] = useState(false);

  useEffect(() => {
    try {
      const permissions =
        JSON.parse(localStorage.getItem("agx_permission")).Remote_monitoring ??
        [];
      setAccessPermission(permissions);
    } catch (error) {
      console.error("Error parsing permissions from localStorage", error);
    }
  }, []);

  const handleLocalDownloadData = async () => {
    setIsLoading(true);
    try {
      const profile = JSON.parse(localStorage.getItem("profile"));
      const params = {
        page_num: 1,
        user_profile_id: profile.id,
        used_for: "report_download",
        state:
          selectedType.value === "hddabnormal"
            ? "hdd_abnormal"
            : selectedType.value,
        ...(selectedStore.value !== "all" &&
          selectedNvr.value === "all" && { facility_id: selectedStore.value }),
        ...(selectedNvr.value !== "all" && { nvr_id: selectedNvr.value }),
        ...(searchValue !== "" && { nvr_id: searchValue }),
      };
      const json_data =
        await cloudMonitoringAction.downloadNvrStatusReportWithParams(params);
      downloadReportToSystem(makeDownloadableData(json_data));
    } catch (error) {
      console.error("Error during local download data", error);
    } finally {
      setIsLoading(false);
    }
  };

  const convertJsonToCSV = (objArray) => {
    const array =
      typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    array.forEach((item) => {
      let line = Object.values(item).join(",");
      str += `${line}\r\n`;
    });
    return str;
  };

  const makeDownloadableData = (data) => {
    return data.map(
      ({
        nvr,
        facility,
        total_cameras,
        nvr_online,
        hdd_working,
        is_recording,
        cameras_offline,
        last_online,
        last_recording_available,
        reason,
      }) => ({
        nvr,
        facility,
        total_cameras,
        nvr_online,
        hdd_working,
        is_recording,
        cameras_offline: cameras_offline ? cameras_offline.join("/") : "",
        last_online,
        last_recording_available: Array.isArray(last_recording_available)
          ? last_recording_available[0]
          : "",
        reason,
      })
    );
  };

  const downloadReportToSystem = (csvStr) => {
    try {
      csvStr.unshift({
        nvr: "NVR/DVR",
        facility: "Store",
        total_cameras: "Total Cameras",
        nvr_online: "NVR ONLINE",
        hdd_working: "HDD Working",
        is_recording: "Recording",
        cameras_offline: "Offline Camera",
        last_online: "Last Online",
        last_recording_available: "Last Recording Available",
        reason: "Offline Reason",
      });
      const downloadLink = document.createElement("a");
      const blob = new Blob(["\ufeff", convertJsonToCSV(csvStr)]);
      const url = URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = `NVR_Health_Report.csv`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (e) {
      console.error("Error during file download", e);
    }
  };

  const handleSendEmailReport = async () => {
    setIsLoadingg(true);
    try {
      const profile = JSON.parse(localStorage.getItem("profile"));
      const params = {
        page_num: 1,
        user_profile_id: profile.id,
        used_for: "email_report",
        state:
          selectedType.value === "hddabnormal"
            ? "hdd_abnormal"
            : selectedType.value,
        ...(selectedStore.value !== "all" &&
          selectedNvr.value === "all" && { facility_id: selectedStore.value }),
        ...(selectedNvr.value !== "all" && { nvr_id: selectedNvr.value }),
        ...(searchValue !== "" && { nvr_id: searchValue }),
      };
      await cloudMonitoringAction.emailNvrStatusReportWithParams(params);
    } catch (error) {
      console.error("Error during email report send", error);
    } finally {
      setIsLoadingg(false);
    }
  };

  return (
    <div>
      {access_permission.includes("nvr_email_download_report") && (
        <div className="col mt-4">
          <div className="d-flex justify-content-end">
            <div
              className="btn  btn-sm m-1 shadow-none"
              style={mainCss.downloadButton}
              onClick={handleLocalDownloadData}
            >
              {!isLoading ? (
                <DownloadIcon sx={mainCss.downloadIcon} />
              ) : (
                <ColorRing
                  visible={true}
                  height="40"
                  width="40"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={["#ffff", "#ffff", "#ffff", "#ffff", "#ffff"]}
                />
              )}
            </div>

            <div
              className="btn  btn-sm m-1 shadow-none"
              style={mainCss.downloadButton}
              onClick={handleSendEmailReport}
            >
              {!isLoadingg ? (
                <EmailIcon sx={mainCss.downloadIcon} />
              ) : (
                <ColorRing
                  visible={true}
                  height="40"
                  width="40"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={["#ffff", "#ffff", "#ffff", "#ffff", "#ffff"]}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

DownloadAndEmailReport.propTypes = {
  selectedStore: PropTypes.object.isRequired,
  selectedNvr: PropTypes.object.isRequired,
  selectedType: PropTypes.object.isRequired,
  searchValue: PropTypes.string.isRequired,
};

export default DownloadAndEmailReport;
