import axios from "axios";

import AlertEventMergedEndpoints from "../features/AlertEventMergedEndpoints";
import AlertEndPoints from "../features/AlertEndPoints";
// get Store
function buildUrlWithParams(params, base_url) {
  // Initialize URL with the base part
  const url = new URL(base_url);

  // Iterate over the params object
  Object.keys(params).forEach((key) => {
    if (params[key] !== undefined && params[key] !== null) {
      // Append only if the parameter is not undefined or null
      url.searchParams.append(key, params[key]);
    }
  });

  // Return the final URL
  return url.toString();
}
function getReducerType(graph_type, start_date, end_date, isWeekly) {
  if (isWeekly) {
    return "TOTAL_WEEKLY_ALERTS";
  } else if (graph_type == "total" && start_date == end_date) {
    return "TOTAL_ALERT_HOURLY";
  } else if (graph_type == "resolved" && start_date == end_date) {
    return "RESOLVED_ALERT_HOURLY";
  } else if (graph_type == "unresolved" && start_date == end_date) {
    return "UNRESOLVED_ALERT_HOURLY";
  } else if (graph_type == "total" && start_date != end_date) {
    return "TOTAL_ALERT_DATE_RANGE";
  } else if (graph_type == "unresolved" && start_date != end_date) {
    return "UNRESOLVED_ALERT_DATE_RANGE";
  } else if (graph_type == "resolved" && start_date != end_date) {
    return "RESOLVED_ALERT_DATE_RANGE";
  }
}

class AlertEventChartActions {
  /**
   * Fetches and dispatches data for total alerts and events, resolved and unresolved alerts and events,
   * filtered by hourly and date range. Additionally, it provides weekly statistics for resolved and unresolved alerts
   * and events for both high and normal priorities for facility, user profile and camera also.
   *
   * The function builds a request using parameters to fetch the relevant data and then dispatches it to the Redux store.
   * If the fetched data is not in the expected array format or if an error occurs during the request,
   * an empty array is dispatched to ensure the application's state remains consistent.
   *
   * @param {Object} params - Parameters to define the request, including graph type, priority, and alert/event type.
   *                         It also includes date range and weekly statistics flags.
   * @returns {Function} A thunk action that, when dispatched, performs the asynchronous data fetching and updates the store.
   */
  static AlertEventMergedActionForDashboard(params) {
    return async (dispatch) => {
      try {
        // Retrieve the user token from localStorage
        var token = localStorage.getItem("token");
        let headers = {
          Authorization: `Token ${token}`,
        };

        // Construct the base URL based on the parameters provided
        let getBaseUrl =
          AlertEventMergedEndpoints.getAlertEventMergedDataForDashboard(
            params.graph_type,
            params.priority,
            params.alertOrEvent
          );

        // Execute the GET request with the constructed URL and headers
        const response = await axios.get(
          buildUrlWithParams(params, getBaseUrl),
          {
            headers,
          }
        );

        // Dispatch the data to the store, checking if the response data is an array
        dispatch({
          type: getReducerType(
            params.graph_type,
            params.start_date,
            params.end_date,
            Boolean(params.alert_weekly) || Boolean(params.event_weekly)
          ),
          payload: Array.isArray(response.data) ? response.data : [],
          ...(params.start_date === params.end_date
            ? { date: params.start_date }
            : {}),
        });
      } catch (error) {
        // In case of an error, dispatch an empty array to ensure consistency
        dispatch({
          type: getReducerType(
            params.graph_type,
            params.start_date,
            params.end_date,
            Boolean(params.alert_weekly) || Boolean(params.event_weekly)
          ),
          payload: [],
        });
        console.error("Failed to fetch alert/event merged data:", error);
      }
    };
  }

  /**
   * Fetches and dispatches weekly statistics for total resolved and unresolved alerts and events based on the provided parameters.
   * This function aims to retrieve data for a specified date and date range for merged data only when priority is all and then dispatch it to the Redux store
   * to update the application's state with the latest weekly alerts and events statistics.
   *
   * If an error occurs during the data fetching process or if the response data format is unexpected,
   * the function ensures the application's state remains consistent by handling these exceptions gracefully.
   *
   * @param {Object} params - Parameters to define the request, including the start date and potentially other filters.
   * @returns {Function} A thunk action that, when dispatched, performs the asynchronous data fetching and updates the store.
   */
  static getWeeklyAlertEvent(params) {
    return async (dispatch) => {
      try {
        // Retrieve the user token from localStorage
        var token = localStorage.getItem("token");
        let headers = {
          Authorization: `Token ${token}`,
        };

        // Execute the GET request to fetch total weekly alert/event statistics
        const response = await axios.get(
          AlertEventMergedEndpoints.getTotalAlertEvent(params),
          { headers }
        );

        // Check if the response data is an array, dispatching accordingly
        dispatch({
          type: "TOTAL_WEEKLY_ALERTS",
          payload: Array.isArray(response.data) ? response.data : [],
          date: params.start_date,
        });
      } catch (error) {
        // In case of an error, log the error and dispatch an appropriate fallback action
        console.error("Failed to fetch weekly alert/event data:", error);
        dispatch({
          type: "TOTAL_WEEKLY_ALERTS",
          payload: [], // Dispatching an empty array as a fallback
          date: params.start_date,
        });
      }
    };
  }

  /**
   * Fetches and dispatches data for the top 7 sites with the most alerts and events across all priorities,
   * facilities, cameras, and user profiles, filtered by a specific day and date range.
   * This function aims to retrieve relevant data based on the provided parameters and then dispatch it to
   * the Redux store to update the application's state with the latest statistics for these top sites.
   *
   * In case of an error during the data fetching process or if the response data does not match the expected format,
   * the function handles these exceptions gracefully to ensure the application's state remains consistent.
   *
   * @param {Object} params - Parameters to define the request, including date range and potentially other filters.
   * @returns {Function} A thunk action that, when dispatched, performs the asynchronous data fetching and updates the store.
   */
  static getTop7SiteAlertEvent(params) {
    return async (dispatch) => {
      try {
        dispatch({
          type: "TOP_SEVEN_SITE_ALERTS",
          payload: [],
          date: params.start_date,
        });
        // Retrieve the user token from localStorage
        var token = localStorage.getItem("token");
        let headers = {
          Authorization: `Token ${token}`,
        };

        // Construct the URL with parameters for the request
        const response = await axios.get(
          buildUrlWithParams(
            params,
            AlertEventMergedEndpoints.getTop7SiteAlertEvent()
          ),
          { headers }
        );

        // Dispatch the data to the store, ensuring the payload is always an array
        dispatch({
          type:
            params.start_date == params.end_date
              ? "TOP_SEVEN_SITE_ALERTS"
              : "TOP_SEVEN_SITE_ALERTS_DATE_RANGE",
          payload: Array.isArray(response.data) ? response.data : [],
          date: params.start_date,
        });
      } catch (error) {
        // In case of an error, log the error and dispatch a fallback action with an empty array
        console.error("Failed to fetch top 7 site alert/event data:", error);
        dispatch({
          type:
            params.start_date == params.end_date
              ? "TOP_SEVEN_SITE_ALERTS"
              : "TOP_SEVEN_SITE_ALERTS_DATE_RANGE",
          payload: [],
          date: params.start_date,
        });
      }
    };
  }

  /**
   * Fetches and dispatches classification data for alerts and events, including the types of alerts and events
   * and their respective counts, based on a specified day and date range, for facilities, cameras, and user profiles.
   * Depending on the priority, the response may include data for both alerts and events (when priority is 'all'),
   * only alerts (when priority is 'high'), or only events (when priority is 'normal'). The function transforms
   * this data into a unified format before dispatching it to the Redux store.
   *
   * If the response does not contain data in the expected dictionary format, or if an error occurs during the data fetching process,
   * the function dispatches an empty array to maintain the consistency of the application's state.
   *
   * @param {Object} params - Parameters defining the request, including priority, date range, and filters for facility, camera, and user profile.
   * @returns {Function} A thunk action that, when dispatched, performs the asynchronous data fetching and updates the store.
   */
  static getAlertEventClassificationMerged(params) {
    return async (dispatch) => {
      try {
        //clear the existing data
        dispatch({
          type:
            params.start_date !== params.end_date
              ? "TOTAL_ALERTS_TYPE_DATA_DATE_RANGE"
              : "TOTAL_ALERTS_TYPE_DATA",
          payload: [],
          date: params.start_date,
        });

        // Retrieve the user token from localStorage
        var token = localStorage.getItem("token");
        let headers = {
          Authorization: `Token ${token}`,
        };

        // Execute the GET request to fetch alert/event classification data
        const response = await axios.get(
          buildUrlWithParams(
            params,
            AlertEventMergedEndpoints.getAlertEventTypeClassification()
          ),
          { headers }
        );

        // Validate the response data format
        if (
          typeof response.data !== "object" ||
          response.data === null ||
          Array.isArray(response.data)
        ) {
          throw new Error("Response data is not in expected dictionary format");
        }

        // Process and combine data based on priority
        let combinedData = [];
        if (params.priority === "all") {
          combinedData = [
            ...(response.data.alert ?? []),
            ...(response.data.event?.map((item) => ({
              alert_type: item.event_type,
              alert_count: item.event_count,
            })) ?? []),
          ];
        } else if (params.priority === "high") {
          combinedData = response.data.alert ?? [];
        } else {
          // Assuming priority is "normal"
          combinedData =
            response.data.event?.map((item) => ({
              alert_type: item.event_type,
              alert_count: item.event_count,
            })) ?? [];
        }

        // Dispatch the transformed data to the store
        dispatch({
          type:
            params.start_date !== params.end_date
              ? "TOTAL_ALERTS_TYPE_DATA_DATE_RANGE"
              : "TOTAL_ALERTS_TYPE_DATA",
          payload: combinedData,
          date: params.start_date,
        });
      } catch (error) {
        // Log the error and dispatch an empty array as a fallback
        console.error(
          "Failed to fetch alert/event classification data:",
          error
        );
        dispatch({
          type:
            params.start_date !== params.end_date
              ? "TOTAL_ALERTS_TYPE_DATA_DATE_RANGE"
              : "TOTAL_ALERTS_TYPE_DATA",
          payload: [],
          date: params.start_date,
        });
      }
    };
  }

  /**
   * Fetches the pizza count statistics for a specific user facility camera based on the given parameters.
   * Clears existing data before making the request.
   * Dispatches an action to update the state with the fetched data or empty data in case of an error.
   *
   * @param {Object} params - The parameters for filtering the statistics (start_date, end_date, etc.).
   * @returns {Function} Redux thunk function for asynchronous dispatching.
   */
  static getPizzaCountUserFacilityCamera(params) {
    return async (dispatch) => {
      try {
        // Initialize the pizza count statistics to default values
        dispatch({
          type: "UPDATE_PIZZA_COUNT_STATS",
          payload: { count: 0, distribution: [] },
          date: params.start_date,
        });

        // Retrieve the authorization token from localStorage
        const token = localStorage.getItem("token");

        // Check if the token is available, otherwise handle the error immediately
        if (!token) {
          console.warn("Authorization token not found. Please log in.");
          throw new Error("Authorization token not found");
        }

        // Set up the request headers with the authorization token
        const headers = {
          Authorization: `Token ${token}`,
        };

        // Build the full URL with the provided parameters
        const url = buildUrlWithParams(
          params,
          AlertEndPoints.getPizzaCountByUserProfileFacilityCameras()
        );

        // Make the API request to fetch the pizza count statistics
        const response = await axios.get(url, { headers });
        const data = response.data;

        // Prepare the payload based on whether the time period spans a single day or multiple days
        const payload = {
          count: data.total_pizza_count,
          distribution:
            params.start_date !== params.end_date
              ? data.day_wise_counts
              : data.hourly_pizza_counts,
        };

        // Dispatch the action to update the state with the fetched data
        dispatch({
          type: "UPDATE_PIZZA_COUNT_STATS",
          payload: payload,
          date: params.start_date,
        });
      } catch (error) {
        // Log the error and dispatch an action to reset the statistics
        console.error("Failed to fetch pizza count statistics:", error);

        // Provide fallback data in case of an error
        dispatch({
          type: "UPDATE_PIZZA_COUNT_STATS",
          payload: { count: 0, distribution: [] },
          date: params.start_date,
        });

        // Optionally, handle specific error scenarios here
        if (error.response) {
          // Server responded but with an error status
          const { status, data } = error.response;
          console.warn(
            `Error ${status}: ${data.message || "An error occurred"}`
          );
        } else {
          // No response was received (network error, etc.)
          console.warn("Network error: Unable to fetch pizza count statistics");
        }
      }
    };
  }
}
export default AlertEventChartActions;
