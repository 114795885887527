import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import moment from "moment";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import ReactCompareImage from "react-compare-image";
const converDateInformate = (date) => {
  const dateString = date;
  const parts = dateString.split("T")[0].split("-");
  const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
  return formattedDate;
};

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import UndoIcon from "@mui/icons-material/Undo";

const EventTableModal = ({
  show,
  handleClose,
  item,
  title,
  incrementcount,
}) => {
  const [access_permission] = useState(
    JSON.parse(localStorage.getItem("agx_permission")).Alerts ?? []
  );
  const handleClick = (data, item) => {
    incrementcount(data, item);
    handleClose();
  };
  function formatText(inputText) {
    return inputText
      .replace(/_/g, " ")
      .replace(/\b\w/g, (c) => c.toUpperCase());
  }
  function handleAlertAction(operation, id) {
    let patchData = {};
    if (operation == "dismiss") {
      patchData.is_false = true;
    }
    if (operation == "resolve") {
      patchData.is_resolved = true;
    }
    if (operation == "revert") {
      patchData.is_false = false;
      patchData.is_resolved = false;
    }

    incrementcount(patchData, id);
  }
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={show}
      maxWidth={"lg"}
    >
      <DialogTitle
        sx={{ m: 0, p: 2 }}
        id="customized-dialog-title"
        className="header-background modal-title-color"
      >
        Alert
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "white",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        dividers
        style={{ backgroundColor: "var(--side-bar-bg-color)" }}
      >
        <div
          className="card"
          style={{
            backgroundColor: "var(--side-bar-bg-color)",
            border: "none",
          }}
        >
          <div className="row no-gutters">
            <div className="col-md-7 col-lg-8">
              {item.event_type !== "vehicle_tat" ? (
                <img src={item.event_image} className="card-img" alt="Alert" />
              ) : (
                <div className="alert-compare-image">
                  <ReactCompareImage
                    leftImage={item.event_image}
                    rightImage={item.event_image2}
                    aspectRatio="taller"
                  />
                </div>
              )}
            </div>
            <div className="col-md-5 col-lg-4">
              <div
                className="card-body d-flex flex-column justify-content-between col-10"
                style={{ height: "100%", minWidth: "100%" }}
              >
                {item.event_type && <h4>{formatText(item.event_type)}</h4>}

                <EventField title="Ticket Number" value={item.id} />
                <EventField
                  title="Site"
                  value={
                    item.facility && item.facility.length > 0
                      ? formatText(item.facility[0])
                      : ""
                  }
                />
                {item.camera && item.camera.length > 0 && (
                  <EventField
                    title="Camera"
                    value={formatText(item.camera[0])}
                  />
                )}

                <EventField
                  title="Message"
                  value={item.event_message
                    .replace(/_/g, " ")
                    .replace(/\b\w/g, (c) => c.toUpperCase())}
                />
                <EventField
                  title="Date"
                  value={converDateInformate(item.event_recieved_at)}
                  icon={<CalendarTodayIcon />}
                />
                <EventField
                  title="Time"
                  value={moment.utc(item.event_recieved_at).format("h:mm A")}
                  icon={<AccessTimeIcon />}
                />

                <div className="d-flex justify-content-end">
                  {title === "event" &&
                    access_permission.includes("can_resolve") && (
                      <button
                        onClick={() => {
                          handleAlertAction("resolve", item.id);
                          handleClose();
                        }}
                        type="submit"
                        className="alert-card-reso-btn alert-card-resolve-dismiss"
                      >
                        <DoneIcon />
                        Resolve
                      </button>
                    )}
                  {title === "event" &&
                    access_permission.includes("can_dismiss") && (
                      <button
                        onClick={() => {
                          handleAlertAction("dismiss", item.id);
                          handleClose();
                        }}
                        type="submit"
                        className="alert-card-diss-btn alert-card-resolve-dismiss"
                        style={{ marginLeft: "4px" }}
                      >
                        <CloseIcon />
                        Dismiss
                      </button>
                    )}
                  {title === "dissmiss" && (
                    <button
                      onClick={() => {
                        handleAlertAction("revert", item.id);
                        handleClose();
                      }}
                      type="submit"
                      className="alert-card-revert-btn alert-card-resolve-dismiss"
                    >
                      <UndoIcon />
                      Revert
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const EventField = ({ title, value, icon }) => (
  <div className="alert-main-field-modal">
    {icon && <div className="alert-field-icon">{icon}</div>}
    <div className="card-title-modal">{title}: </div>
    <div className="modal-card-text-value">{value}</div>
  </div>
);
EventField.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  icon: PropTypes.element,
};

EventTableModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  incrementcount: PropTypes.func.isRequired,
};

export default EventTableModal;
