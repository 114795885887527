import buildUrlWithParams from "./AddUrlsWIthParams";
import CloudMonitoringEndpoints from "../features/CloudMonitoringEndpoints";
import axios from "axios";
import moment from "moment";
import { nvrWithApi } from "../../configs/dataConfig";
import {
  notifiToasterSuccess,
  notifiToasterWarn,
} from "../../components/common/UseToast";

const restructureResponse = (el) => {
  try {
    const formattedBrand = el.brand
      .replace(/[^\w\s]/gi, "")
      .replace(/\s+/g, "")
      .toLowerCase();

    const currentTime = moment()
      .subtract(15, "minutes")
      .format("YYYY-MM-DD HH:mm:ss");
    const last_online = moment(new Date(el.last_online))
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");

    const isOffline = currentTime > last_online || el.last_online === "NIL";

    el.last_online =
      el.last_online !== "NIL"
        ? moment(new Date(el.last_online)).utc().format("DD-MM-YYYY HH:mm:ss")
        : el.last_online;

    el.hdd_working = !nvrWithApi.includes(formattedBrand)
      ? "N/A"
      : el.hdd_working && !isOffline
      ? "NORMAL"
      : "ABNORMAL";

    el.is_recording = !nvrWithApi.includes(formattedBrand)
      ? "N/A"
      : el.is_recording && !isOffline
      ? "RECORDING"
      : "NOT RECORDING";

    el.nvr_online = !isOffline ? "ONLINE" : "OFFLINE";
  } catch (error) {
    console.error("Error restructuring response element:", error);
  }
  return el;
};

class cloudMonitoringAction {
  static getNvrStatusWithParams(params) {
    return async (dispatch) => {
      dispatch({ type: "LOADING", payload: true });

      try {
        const base_url = CloudMonitoringEndpoints.getNVRstatus();
        const url = buildUrlWithParams(params, base_url);
        const token = localStorage.getItem("token");

        const headers = { Authorization: `Token ${token}` };

        const response = await axios.get(url, { headers });

        dispatch({ type: "FETCH_NVR_STATUS_SUCCESS", payload: response.data });
        dispatch({ type: "TOTAL_NVR_COUNT", payload: response.data?.total });
        dispatch({ type: "TOTAL_NVR_ONLINE", payload: response.data?.online });

        if (Array.isArray(response.data.data)) {
          response.data.data.forEach((el) => {
            restructureResponse(el);
          });
        }

        dispatch({ type: "RHMS_STATUS_DATA", payload: response.data });
      } catch (error) {
        dispatch({ type: "FETCH_NVR_STATUS_FAILURE", error: error.message });
        console.error("Error fetching NVR status:", error);
      } finally {
        dispatch({ type: "LOADING", payload: false });
      }
    };
  }

  static getCameraStats(params) {
    return async (dispatch) => {
      dispatch({ type: "CAMERA_STATS_LOADING", payload: true });

      try {
        const base_url = CloudMonitoringEndpoints.getCameraStats();
        const url = buildUrlWithParams(params, base_url);
        const token = localStorage.getItem("token");

        const headers = { Authorization: `Token ${token}` };

        const response = await axios.get(url, { headers });
        dispatch({
          type: "UPDATE_CAMERA_COUNT_DATA",
          payload: {
            total: response.data.total_cameras,
            online: response.data.total_online_cameras,
            offline: response.data.total_offline_cameras,
          },
        });
      } catch (error) {
        console.error("Error fetching camera stats:", error);
      } finally {
        dispatch({ type: "CAMERA_STATS_LOADING", payload: false });
      }
    };
  }

  static async downloadNvrStatusReportWithParams(params) {
    try {
      const base_url = CloudMonitoringEndpoints.getNVRstatus();
      const url = buildUrlWithParams(params, base_url);
      const token = localStorage.getItem("token");

      const headers = { Authorization: `Token ${token}` };

      const response = await axios.get(url, { headers });

      return response.data?.data;
    } catch (error) {
      console.error("Error downloading NVR status report:", error);
      return [];
    }
  }

  static async emailNvrStatusReportWithParams(params) {
    try {
      const base_url = CloudMonitoringEndpoints.getNVRstatus();
      const url = buildUrlWithParams(params, base_url);
      const token = localStorage.getItem("token");

      const headers = { Authorization: `Token ${token}` };

      const response = await axios.get(url, { headers });
      if (response.data?.data?.status === "successful") {
        notifiToasterSuccess("Report Sent Successfully");
      } else {
        notifiToasterWarn("Failed to send Report");
      }
    } catch (error) {
      notifiToasterWarn("Failed to send Report");
      console.error("Error emailing NVR status report:", error);
    }
  }
}

export default cloudMonitoringAction;
