import axios from "axios";
import ApiSpec from "../features/EndPoints";
import {
  notifiToasterSuccess,
  notifiToasterWarn,
} from "../../components/common/UseToast";

export const getNewUserDetails = async () => {
  // Mark the function as async
  try {
    var token = localStorage.getItem("token");
    let headers = {
      Authorization: `Token ${token}`,
    };
    const response = await axios.get(ApiSpec.getuserprofile, { headers }); // Use await to wait for the response
    await localStorage.setItem("profile", JSON.stringify(response.data));
    await localStorage.setItem("profile_id", JSON.stringify(response.data.id));

    const response2 = await axios.get(
      ApiSpec.getPermissionsByUserprofile(response.data.id),
      {
        headers: {
          Authorization: `Token ${token}`, // Use `token` instead of `authToken`
        },
      }
    );

    localStorage.setItem("agx_permission", JSON.stringify(response2.data));
  } catch (error) {
    console.error("Error sending data for verification:", error.message);
    return false; // Properly return from the function in case of error
  }
};

export const fetchAccessToken = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(ApiSpec.login, {
        email: data.username,
        password: data.password,
      })
      .then(async (response) => {
        localStorage.setItem("token", response.data.token);
        var token = localStorage.getItem("token");
        await localStorage.setItem("isAuthticated", JSON.stringify(true));
        let headers = {
          Authorization: `Token ${token}`,
        };
        axios
          .get(ApiSpec.getuserprofile, { headers })
          .then(async (response) => {
            await localStorage.setItem(
              "profile",
              JSON.stringify(response.data)
            );
            await localStorage.setItem(
              "profile_id",
              JSON.stringify(response.data.id)
            );
            const response2 = await axios.get(
              ApiSpec.getPermissionsByUserprofile(response.data.id),
              {
                headers: {
                  Authorization: `Token ${token}`, // Use `token` instead of `authToken`
                },
              }
            );

            await localStorage.setItem(
              "agx_permission",
              JSON.stringify(response2.data)
            );
            const firstTrueKey = Object.keys(response.data).find(
              (key) => response.data[key] === true
            );
            if (firstTrueKey === "events") {
              resolve("alerts");
            } else {
              resolve(firstTrueKey);
            }
          });
      })
      .catch((error) => {
        let error2 = "";
        console.log(error);
        if (error.message.includes("400")) {
          error2 = "invalid";
        } else if (error.response.status == 402) {
          error2 = "payment";
        } else {
          error2 = "server";
        }
        reject(error2);
      });
  });
};

export const logout = () => {
  return async (dispatch) => {
    // Remove items from local storage
    localStorage.removeItem("token");
    localStorage.removeItem("isAuthticated"); // Note: There seems to be a typo here. Consider changing "isAuthticated" to "isAuthenticated" if that's the correct key.
    localStorage.removeItem("profile");
    localStorage.removeItem("profile_id");
    // Remove items from session storage
    sessionStorage.removeItem("mychats");
    sessionStorage.removeItem("selected_notification_type");
    // Disconnect the socket connection if exists
    if (window?.socket) {
      window.socket.disconnect();
    }
    // Dispatch actions to reset redux store states
    dispatch({ type: "RESET_RHMS_REDUCER" });
    dispatch({ type: "LOGOUT_USER" });
    dispatch({ type: "CLEAR_TABLE_STATE" });
    // Refresh the page to apply the logout fully
    window.location.reload();
  };
};
export const loginViaSSo = (access_token) => {
  return new Promise((resolve, reject) => {
    axios
      .get(ApiSpec.CreateOrGetUserViaSSO(access_token, "login"))
      .then(async (response) => {
        localStorage.setItem("token", response.data.token);
        var token = localStorage.getItem("token");
        await localStorage.setItem("isAuthticated", JSON.stringify(true));
        let headers = {
          Authorization: `Token ${token}`,
        };
        axios
          .get(ApiSpec.getuserprofile, { headers })
          .then(async (response) => {
            await localStorage.setItem(
              "profile",
              JSON.stringify(response.data)
            );
            await localStorage.setItem(
              "profile_id",
              JSON.stringify(response.data.id)
            );
            const response2 = await axios.get(
              ApiSpec.getPermissionsByUserprofile(response.data.id),
              {
                headers: {
                  Authorization: `Token ${token}`, // Use `token` instead of `authToken`
                },
              }
            );

            await localStorage.setItem(
              "agx_permission",
              JSON.stringify(response2.data)
            );
            const firstTrueKey = Object.keys(response.data).find(
              (key) => response.data[key] === true
            );
            if (firstTrueKey === "events") {
              resolve("alerts");
            } else {
              resolve(firstTrueKey);
            }
          });
      })
      .catch((error) => {
        let error2 = "";
        if (error.message.includes("400")) {
          error2 = "invalid";
        } else {
          error2 = "server";
        }
        reject(error2);
      });
  });
};
// get Forget password token and uid

export const forgetPassToken = (data) => {
  return async (dispatch) => {
    axios
      .post(ApiSpec.forgetPassword, data)
      .then((response) => {
        dispatch({ type: "FORGET_PASS_DATA_SUCCESS", payload: response.data });
      })
      .catch((error) => {
        notifiToasterWarn(error.response.data.detail);
      });
  };
};
export const signupUsingSSO = async (access_token) => {
  try {
    const response = await axios.get(
      ApiSpec.CreateOrGetUserViaSSO(access_token, "register")
    );
    const res = response.data;
    localStorage.setItem(
      "onboardUser",
      JSON.stringify({
        userId: res.user_id,
        userEmail: res.username,
        name: res.name,
        token: res.token,
      })
    );
    return true;
  } catch (error) {
    notifiToasterWarn(error.response.data.error);
    return false;
  }
};
// chage forget Password

export const changePassword = (data) => {
  return async (dispatch) => {
    axios
      .post(ApiSpec.forgetPassSubmit(data.data.uid, data.data.token), data.obj)
      .then((response) => {
        dispatch({
          type: "FORGET_PASS_SUCCESS_FLAG",
        });
        notifiToasterSuccess(response.data.detail);
      })
      .catch((error) => {
        console.log(error.message);
        if (error.message.includes("400")) {
          notifiToasterWarn(error.response.data.detail[0]);
        } else {
          notifiToasterWarn(error.response.data.detail);
        }
      });
  };
};
