// Action Type Constants
const UPDATE_SELECTED_MODE = "UPDATE_SELECTED_MODE_EVENT";
const UPDATE_FACILITY = "UPDATE_FACILITY_EVENT";
const UPDATE_CLIENT = "UPDATE_CLIENT_EVENT";
const UPDATE_DEVICE = "UPDATE_DEVICE_EVENT";
const UPDATE_EVENT_TYPE = "UPDATE_EVENT_TYPE_EVENT";
const UPDATE_SEARCH_VALUE = "UPDATE_SEARCH_VALUE_EVENT";
const UPDATE_SEARCH_VALUE_LENGTH = "UPDATE_SEARCH_VALUE_LENGTH_EVENT";
const UPDATE_TIME = "UPDATE_TIME_EVENT";
const UPDATE_CURRENT_PAGE = "UPDATE_CURRENT_PAGE_EVENT";
const UPDATE_TAB_VALUE = "UPDATE_TAB_VALUE_EVENT";
const UPDATE_TAB_NAME = "UPDATE_TAB_NAME_EVENT";
const UPDATE_EVENT_TYPES_SELECETED = "UPDATE_EVENT_TYPES_SELECETED";
const UPDATE_USECASE_SELECTED = "UPDATE_USECASE_SELECTED";
import axios from "axios";
import EventApiSpec from "../features/EventEndPoints";
import ApiSpec from "../features/EndPoints";
import {
  notifiToasterWarn,
  notifiToasterSuccess,
} from "../../components/common/UseToast";
import EndPoints from "../features/EndPoints";
class EventTableAction {
  static updateSelectedMode(data) {
    return {
      type: UPDATE_SELECTED_MODE,
      payload: data,
    };
  }

  static updateFacility(data) {
    return {
      type: UPDATE_FACILITY,
      payload: data,
    };
  }

  static updateClient(data) {
    return {
      type: UPDATE_CLIENT,
      payload: data,
    };
  }

  static updateDevice(data) {
    return {
      type: UPDATE_DEVICE,
      payload: data,
    };
  }

  static updateEventType(data) {
    return {
      type: UPDATE_EVENT_TYPE,
      payload: data,
    };
  }

  static updateSearchValue(data) {
    return {
      type: UPDATE_SEARCH_VALUE,
      payload: data,
    };
  }
  static updateSearchValueLength(data) {
    return {
      type: UPDATE_SEARCH_VALUE_LENGTH,
      payload: data,
    };
  }
  static updateTime(data) {
    return {
      type: UPDATE_TIME,
      payload: data,
    };
  }
  static updateCurrentPage(data) {
    return {
      type: UPDATE_CURRENT_PAGE,
      payload: data,
    };
  }
  static updateTabValue(data) {
    return {
      type: UPDATE_TAB_VALUE,
      payload: data,
    };
  }
  static updateTabName(data) {
    return {
      type: UPDATE_TAB_NAME,
      payload: data,
    };
  }
  static updateEventTypesSelected(data) {
    return {
      type: UPDATE_EVENT_TYPES_SELECETED,
      payload: data,
    };
  }
  static updateSelectedUseCase(data) {
    return {
      type: UPDATE_USECASE_SELECTED,
      payload: data,
    };
  }
  static updateOpeningTime(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      axios
        .get(EventApiSpec.getOpeningTimeByFacility(data.id, data.date), {
          headers,
        })
        .then((response) => {
          dispatch({
            type: "UPDATE_OPENING_TIME",
            payload: response.data,
          });
        });
    };
  }
  static updateClosingTime(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      axios
        .get(EventApiSpec.getClosingTimeByFacility(data.id, data.date), {
          headers,
        })
        .then((response) => {
          dispatch({
            type: "UPDATE_CLOSING_TIME",
            payload: response.data,
          });
        });
    };
  }
  static getAlertViaUSecase(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      axios
        .get(EndPoints.getAlertEventViaUsecase(data), {
          headers,
        })
        .then((response) => {
          console.log(response.data);
          dispatch({
            type: "TABLE_DATA_EVENT",
            payload: response.data,
            date: data.date,
          });
        });
    };
  }
  static getTableEventDataByAccount(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      axios
        .get(
          EventApiSpec.getTableEventDataByAccount(
            data.id,
            data.event_type,
            data.date,
            data.start_time,
            data.end_time,
            data.page_no,
            data.if_false,
            data.is_resolve,
            data.page_size
          ),
          {
            headers,
          }
        )
        .then((response) => {
          dispatch({
            type: "TABLE_DATA_EVENT",
            payload: response.data,
            date: data.date,
          });
        });
    };
  }

  static getTableEventDataByStore(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      axios
        .get(
          EventApiSpec.getTableEventDataByStore(
            data.id,
            data.event_type,
            data.date,
            data.start_time,
            data.end_time,
            data.page_no,
            data.if_false,
            data.is_resolve,
            data.page_size
          ),
          {
            headers,
          }
        )
        .then((response) => {
          dispatch({
            type: "TABLE_DATA_EVENT",
            payload: response.data,
            date: data.date,
          });
        });
    };
  }

  static getTableEventDataByCamera(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      axios
        .get(
          EventApiSpec.getTableEventDataByCamera(
            data.id,
            data.event_type,
            data.date,
            data.start_time,
            data.end_time,
            data.page_no,
            data.if_false,
            data.is_resolve,
            data.page_size
          ),
          {
            headers,
          }
        )
        .then((response) => {
          dispatch({
            type: "TABLE_DATA_EVENT",
            payload: response.data,
            date: data.date,
          });
        });
    };
  }

  // get table data by date range for table alerts

  static getTableEventDataByDateRangeByAccount(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      axios
        .get(
          EventApiSpec.getTableEventDataByDateRangeByAccount(
            data.id,
            data.event_type,
            data.start_date,
            data.end_date,
            data.page_no,
            data.if_false,
            data.is_resolve,
            data.page_size
          ),
          {
            headers,
          }
        )
        .then((response) => {
          dispatch({
            type: "TABLE_DATA_EVENT",
            payload: response.data,
            date: data.date,
          });
        });
    };
  }

  static getTableEventDataByDateRangeByStore(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      axios
        .get(
          EventApiSpec.getTableDataEventByDateRangeByStore(
            data.id,
            data.event_type,

            data.start_date,
            data.end_date,
            data.page_no,
            data.if_false,
            data.is_resolve,
            data.page_size
          ),
          {
            headers,
          }
        )
        .then((response) => {
          dispatch({
            type: "TABLE_DATA_EVENT",
            payload: response.data,
            date: data.date,
          });
        });
    };
  }

  static getTableDataEventByDateRangeByCamera(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      axios
        .get(
          EventApiSpec.getTableDataEventByDateRangeByCamera(
            data.id,
            data.event_type,
            data.start_date,
            data.end_date,
            data.page_no,
            data.if_false,
            data.is_resolve,
            data.page_size
          ),
          {
            headers,
          }
        )
        .then((response) => {
          dispatch({
            type: "TABLE_DATA_EVENT",
            payload: response.data,
            date: data.date,
          });
        });
    };
  }
  static getPlaybackStreamEvent(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      await axios
        .get(
          ApiSpec.getPlaybackStream(
            data.cam_id,
            data.date,
            data.startTime,
            data.endTime
          ),
          { headers }
        )
        .then((response) => {
          if (response.data.length == 0) {
            notifiToasterWarn(
              "Sorry, there is no recording available for the selected time."
            );
            dispatch({ type: "UPDATE_EVENT_PLAYBACK_STREAM", payload: [] });
          } else {
            dispatch({
              type: "UPDATE_EVENT_PLAYBACK_STREAM",
              payload: response.data,
            });
            const arr = JSON.parse(sessionStorage.getItem("NewItems")) || [];
            const res_data = arr.concat(response.data);
            sessionStorage.setItem("NewItems", JSON.stringify(res_data));
          }
        })
        .catch(() => {
          dispatch({
            type: "UPDATE_EVENT_PLAYBACK_STREAM",
            payload: [],
          });
        });
    };
  }
  static sendNotificationToFacility(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      await axios
        .get(EventApiSpec.sendEventNotification(data), { headers })
        .then((response) => {
          notifiToasterSuccess("Notification Send Successfully");
        })
        .catch(() => {
          notifiToasterWarn("Unable to send notification");
        });
    };
  }
}

export default EventTableAction;
