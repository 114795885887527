import axios from "axios";
import ApiSpec from "../features/EndPoints";
import { notifiToasterWarn } from "../../components/common/UseToast";
class LiveViewAction {
  static getLiveViewByIpCameras(data, quality) {
    const profile = JSON.parse(localStorage.getItem("profile"));
    return async (dispatch) => {
      try {
        const token = localStorage.getItem("token");
        const headers = {
          Authorization: `Token ${token}`,
        };

        if (data.id !== "all") {
          const response = await axios.get(
            ApiSpec.getLiveStreamWithControls(profile.id, data, quality),
            { headers }
          );

          if (response.data.length === 0) {
            notifiToasterWarn(
              "Unable to Process your request. Please Refresh the page"
            );
          } else {
            dispatch({
              type: "VMS_LIVE_FEEDS",
              payload: response.data,
            });
          }

          // Saving the response in sessionStorage
          const arr = JSON.parse(sessionStorage.getItem("NewItems")) || [];
          const res_data = arr.concat(response.data);
          sessionStorage.setItem("NewItems", JSON.stringify(res_data));
        }
      } catch (error) {
        notifiToasterWarn(
          "Unable to Process your request. Please Refresh the page"
        );
        dispatch({
          type: "EMPTY_LOADING_CAMERAS",
        });
      }
    };
  }

  //Action to stop the perticular stream
  static stopLiveStream(data) {
    return async (dispatch) => {
      const username = "demo";
      const password = "demo";
      const encodedCredentials = btoa(`${username}:${password}`);

      try {
        const response = await axios.get(
          ApiSpec.deleteLiveViewStreamHLS(data),
          {
            headers: {
              Authorization: `Basic ${encodedCredentials}`,
            },
          }
        );

        return response.data;
      } catch (error) {
        // Do nothing when the API call fails
      }
    };
  }

  static stopLiveStreamFfmpeg(data) {
    return async (dispatch) => {
      await axios.post(ApiSpec.stopLiveStream(data));
    };
  }
  //Action to clear the redux state
  static dissMissLiveData() {
    return async (dispatch) => {
      dispatch({
        type: "LIVE_FEEDS_REMOVE",
      });
    };
  }
  static removeCameraFromLiveViewData(data) {
    return async (dispatch) => {
      dispatch({
        type: "REMOVE_SELECTED_LIVE_FEEDS_FROM_PLAYLIST",
        payload: data,
      });
    };
  }
}

export default LiveViewAction;
