import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import ConfirmationDialog from "../../../../components/common/ConfirmationDailog";
import AddGroupDialog from "./AddEditGroupDialog";
import { useSelector, useDispatch } from "react-redux";
import AccessControlActions from "../../../../redux/Action/AccessControlAction";
import PropTypes from "prop-types";

const GroupTable = ({ searchValue }) => {
  const [isAddEditMemberOpen, setIsAddEditMemberOpen] = useState(false);
  const [isDeleteUserOpen, setIsDeleteUserOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(-1);
  const groupList = useSelector((state) => state.accessControl.groupList);
  const filteredGroupList = useSelector(
    (state) => state.accessControl.filteredGroupList
  );
  const [access_permission] = useState(
    JSON.parse(localStorage.getItem("agx_permission")).Profile ?? []
  );
  const dispatch = useDispatch();
  const profile_id = JSON.parse(localStorage.getItem("profile")).id;
  const isGroupLoading = useSelector(
    (state) => state.accessControl.groupLoading
  );
  useEffect(() => {
    dispatch(AccessControlActions.getGroupList(profile_id));
  }, []);

  const handleDeleteUser = (userId) => {
    setSelectedUserId(userId);
    setIsDeleteUserOpen(true);
  };

  function confirmDelete() {
    dispatch(
      AccessControlActions.DeleteGroupAccessControl(selectedUserId)
    ).then(() => {
      dispatch(AccessControlActions.getGroupList(profile_id));
    });
    setIsDeleteUserOpen(false);
  }

  const handleAddEditGroup = (userId) => {
    setSelectedUserId(userId);
    setIsAddEditMemberOpen(true);
  };

  return (
    <div>
      <TableContainer component={Paper} className="mt-3">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>S.No.</TableCell>
              <TableCell>Group Name</TableCell>
              <TableCell>No. Of User</TableCell>
              <TableCell>No. Of Facility</TableCell>
              {(access_permission.includes("edit_groups") ||
                access_permission.includes("delete_groups")) && (
                <TableCell align="center">Actions</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {isGroupLoading ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  Loading Data ...
                </TableCell>
              </TableRow>
            ) : searchValue.length > 0 && filteredGroupList.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No Data Found
                </TableCell>
              </TableRow>
            ) : (
              (searchValue.length === 0 && filteredGroupList.length === 0
                ? groupList
                : filteredGroupList
              ).map((group, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{group.group_name}</TableCell>
                  <TableCell>{group.user_list.length}</TableCell>
                  <TableCell>{group.selected_facility.length}</TableCell>
                  <TableCell align="center" sx={{ padding: 0, margin: 0 }}>
                    <span>
                      {access_permission.includes("edit_groups") && (
                        <IconButton
                          aria-label="edit"
                          onClick={() => handleAddEditGroup(group.id)}
                        >
                          <ModeEditIcon />
                        </IconButton>
                      )}
                      {access_permission.includes("delete_groups") && (
                        <IconButton
                          aria-label="delete"
                          onClick={() => handleDeleteUser(group.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </span>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <ConfirmationDialog
        title={"Delete Group"}
        dialogContent={"Are you sure to delete the group"}
        handleConfirm={confirmDelete}
        handleDisconfirm={() => setIsDeleteUserOpen(false)}
        isOpen={isDeleteUserOpen}
        handleClose={() => setIsDeleteUserOpen(false)}
        confirmButtonLabel={"Yes"}
        disconfirmButtonLabel={"No"}
        confirmButtonVariant={"contained"}
        disconfirmButtonVariant={"outlined"}
      />

      {isAddEditMemberOpen && (
        <AddGroupDialog
          isOpen={isAddEditMemberOpen}
          handleClose={() => setIsAddEditMemberOpen(false)}
          userId={selectedUserId}
          isEditable={true}
          groupList={groupList}
        />
      )}
    </div>
  );
};

GroupTable.propTypes = {
  searchValue: PropTypes.string,
};

export default GroupTable;
