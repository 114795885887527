import React from "react";
import { useSelector } from "react-redux";
import AlertsCard from "../../Alert/AlertsCard";
import LocalPizzaIcon from "@mui/icons-material/LocalPizza";

const iconFont = {
  fontSize: "50px",
  color: "#4318FF",
};

const PizzaCountCard = () => {
  const profile = JSON.parse(localStorage.getItem("profile") || "{}");
  const pizzaCount = useSelector(
    (state) => state.AlertDashboardStates.pizzaCount.count
  );

  return (
    <>
      {profile.services_selected?.Event?.includes("pizza_detected") && (
        <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-2">
          <AlertsCard
            icon={<LocalPizzaIcon style={iconFont} />}
            headText={pizzaCount}
            paraText="Pizza Count"
          />
        </div>
      )}
    </>
  );
};

export default PizzaCountCard;
