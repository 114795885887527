import axios from "axios";
import AccessControlEndpoints from "../features/AccessControlEndPoints";
import {
  notifiToasterInfo,
  notifiToasterSuccess,
  notifiToasterWarn,
} from "../../components/common/UseToast";

class AccessControlActions {
  /**
   * This function fetches data from a specified facility based on the given facility IDs and the target (either devices or NVRs).
   * It then dispatches the fetched data to the Redux store. Proper exception handling is included to manage errors gracefully.
   *
   * @param {Array} facilityIds - An array of facility IDs to fetch data from.
   * @param {String} target - Specifies the type of data to fetch ('nvr' or 'devices').
   * @returns {Function} A thunk action that dispatches the result to the Redux store.
   */
  static getDataFromFacility(facilityIds, target) {
    return async (dispatch) => {
      try {
        const token = localStorage.getItem("token");
        const headers = {
          Authorization: `Token ${token}`,
        };

        const response = await axios.get(
          AccessControlEndpoints.getDeviceOrNVRsFromFacility(
            facilityIds,
            target
          ),
          { headers }
        );

        dispatch({
          type:
            target === "nvr"
              ? "UPDATE_NVR_FROM_FACILITY_ACCESS_CONTROL"
              : "UPDATE_DEVICES_FROM_FACILITY_ACCESS_CONTROL",
          payload: response.data.data,
        });
      } catch (error) {
        console.error("Error fetching data from facility:", error);
        // Handle error appropriately, maybe dispatch an error action
      }
    };
  }
  static replaceDataFromFacility(facilityIds, target) {
    return async (dispatch) => {
      try {
        const token = localStorage.getItem("token");
        const headers = {
          Authorization: `Token ${token}`,
        };

        const response = await axios.get(
          AccessControlEndpoints.getDeviceOrNVRsFromFacility(
            facilityIds,
            target
          ),
          { headers }
        );

        dispatch({
          type:
            target === "nvr"
              ? "SET_NVR_FROM_FACILITY_ACCESS_CONTROL"
              : "SET_DEVICES_FROM_FACILITY_ACCESS_CONTROL",
          payload: response.data.data,
        });
      } catch (error) {
        console.error("Error fetching data from facility:", error);
        // Handle error appropriately, maybe dispatch an error action
      }
    };
  }
  /**
   * This function fetches camera data from a specified NVR based on the given NVR IDs.
   * It then dispatches the fetched data to the Redux store. Exception handling is included to manage any errors that occur during the fetch operation.
   *
   * @param {Array} nvr_ids - An array of NVR IDs to fetch camera data from.
   * @returns {Function} A thunk action that dispatches the result to the Redux store.
   */
  static getCameraFromNVR(nvr_ids) {
    return async (dispatch) => {
      try {
        const token = localStorage.getItem("token");
        const headers = {
          Authorization: `Token ${token}`,
        };

        const response = await axios.get(
          AccessControlEndpoints.getCameraFromNvrForAccessControll(nvr_ids),
          { headers }
        );

        dispatch({
          type: "UPDATE_CAMERA_FROM_ACCESS_CONTROL",
          payload: response.data.data,
        });
      } catch (error) {
        console.error("Error fetching cameras from NVR:", error);
        // Handle error appropriately, maybe dispatch an error action
      }
    };
  }
  static replaceCameraFromNVR(nvr_ids) {
    return async (dispatch) => {
      try {
        const token = localStorage.getItem("token");
        const headers = {
          Authorization: `Token ${token}`,
        };

        const response = await axios.get(
          AccessControlEndpoints.getCameraFromNvrForAccessControll(nvr_ids),
          { headers }
        );

        dispatch({
          type: "SET_CAMERA_FROM_ACCESS_CONTROL",
          payload: response.data.data,
        });
      } catch (error) {
        console.error("Error fetching cameras from NVR:", error);
        // Handle error appropriately, maybe dispatch an error action
      }
    };
  }
  /**
   * This function adds a new group to the access control system with the given data.
   * Upon successful addition, a success notification is shown to the user. If the operation fails, a warning notification is shown, and the error is logged.
   *
   * @param {Object} data - The data for the new access control group.
   * @returns {Function} A thunk action that handles the addition of the group.
   */
  static addGroupToAccessControl = (data) => async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Token ${token}`,
      };

      await axios.post(AccessControlEndpoints.addGroup(), data, {
        headers,
      });
      notifiToasterSuccess("Data saved successfully");
    } catch (error) {
      notifiToasterWarn("Failed to save data");
      console.error("Error adding group to access control:", error);
    }
  };

  /**
   * Fetches a list of groups associated with a given user profile ID and dispatches this data to the Redux store.
   * This function includes proper exception handling to manage errors gracefully and ensure robustness.
   *
   * @param {String} userprofileId - The user profile ID to fetch groups for.
   * @returns {Function} A thunk action that dispatches the group list to the Redux store.
   */
  static getGroupList = (userprofileId) => async (dispatch) => {
    try {
      dispatch({ type: "SET_LOADING_FOR_GROUP", payload: true });
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Token ${token}`,
      };

      const response = await axios.get(
        AccessControlEndpoints.groupListByUserprofileId(userprofileId),
        { headers }
      );

      dispatch({
        type: "ADD_DATA_TO_GROUP_LIST",
        payload: response.data,
      });
    } catch (error) {
      console.error("Error fetching group list:", error);
      // Optionally, dispatch an error action here
    } finally {
      dispatch({ type: "SET_LOADING_FOR_GROUP", payload: false });
    }
  };
  /**
   * Fetches a list of groups associated with a given user profile ID and dispatches this data to the Redux store.
   * This function includes proper exception handling to manage errors gracefully and ensure robustness.
   *
   * @param {String} userprofileId - The user profile ID to fetch groups for.
   * @returns {Function} A thunk action that dispatches the group list to the Redux store.
   */
  static getGroupDetailsById = async (groupId) => {
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Token ${token}`,
      };

      const response = await axios.get(
        AccessControlEndpoints.getGroupDetailsById(groupId),
        { headers }
      );

      return response.data.data;
    } catch (error) {
      console.error("Error fetching group list:", error);
      return null;
      // Optionally, dispatch an error action here
    }
  };
  static getDetailsAccessControl = (userProfile_id) => async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Token ${token}`,
      };

      const response = await axios.get(
        AccessControlEndpoints.getDetailsAccessControl(userProfile_id),
        { headers }
      );

      return response.data.data;
    } catch (error) {
      console.error("Error fetching group list:", error);
      return {};
      // Optionally, dispatch an error action here
    }
  };

  /**
   * Updates the information for a specified access control group with the provided data.
   * This function handles the asynchronous request and includes error handling to manage potential issues.
   * Note: The original function contained an unnecessary nested async function; this has been corrected.
   *
   * @param {String} groupId - The ID of the group to update.
   * @param {Object} data - The new data for the group.
   * @returns {Function} A thunk action that performs the update operation.
   */
  static updateGroupInfoForAccessControl =
    (groupId, data) => async (dispatch) => {
      try {
        const token = localStorage.getItem("token");
        const headers = {
          Authorization: `Token ${token}`,
        };

        const response = await axios.put(
          AccessControlEndpoints.updateGroup(groupId),
          data,
          { headers }
        );

        // Dispatch appropriate action here if needed
      } catch (error) {
        console.error("Error updating group info:", error);
        // Optionally, dispatch an error action here
      }
    };
  static DeleteGroupAccessControl = (groupId) => async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Token ${token}`,
      };

      const response = await axios.delete(
        AccessControlEndpoints.groupAccessControl(groupId),
        { headers }
      );

      // Dispatch appropriate action here if needed
    } catch (error) {
      console.error("Error updating group info:", error);
      // Optionally, dispatch an error action here
    }
  };

  /**
   * Fetches a list of profiles associated with a given user profile ID and dispatches this data to the Redux store.
   * Includes proper exception handling to gracefully manage errors and ensure the robustness of the function.
   *
   * @param {String} userprofileId - The user profile ID to fetch profiles for.
   * @returns {Function} A thunk action that dispatches the profile list to the Redux store.
   */
  static getProfileListViaUserprofile = (userprofileId) => async (dispatch) => {
    try {
      dispatch({ type: "SET_LOADING_FOR_PROFILE", payload: true });
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Token ${token}`,
      };

      const response = await axios.get(
        AccessControlEndpoints.profileListByUserprofileId(userprofileId),
        { headers }
      );

      dispatch({
        type: "ADD_DATA_TO_PROFILE_LIST",
        payload: response.data,
      });
    } catch (error) {
      console.error("Error fetching profile list:", error);
      // Optionally, dispatch an error action here
    } finally {
      dispatch({ type: "SET_LOADING_FOR_PROFILE", payload: false });
    }
  };

  /**
   * Fetches a list of permissions and dispatches it to the Redux store.
   * Includes error handling to gracefully manage any issues that may arise during the API call.
   */
  static getPermissionList = () => async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Token ${token}` };
      const user_profile_id = JSON.parse(localStorage.getItem("profile")).id;
      const response = await axios.get(
        AccessControlEndpoints.getAdminPermissionList(user_profile_id),
        { headers }
      );
      dispatch({
        type: "ADD_DATA_TO_PERMISSION_LIST",
        payload: response.data.data,
      });
    } catch (error) {
      console.error("Error fetching permissions list:", error);
      // Optionally, dispatch an error action here
    }
  };

  /**
   * Adds a profile to access control.
   * On success, it shows a success notification.
   * If the operation fails, a warning notification is shown and the error is logged.
   * @param {Object} data - The data for the new profile.
   */
  static addProfileToAccessControl = (data) => async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Token ${token}` };

      await axios.post(AccessControlEndpoints.profileAcessControlById(), data, {
        headers,
      });
      notifiToasterSuccess("Data saved successfully");
    } catch (error) {
      notifiToasterWarn("Failed to save data");
      console.error("Error adding profile to access control:", error);
    }
  };

  /**
   * Updates a profile in access control by ID.
   * Shows a success notification upon successful update or a warning notification if the update fails.
   * @param {Object} data - The updated data for the profile.
   * @param {String} id - The ID of the profile to be updated.
   */
  static updateProfileToAccessControl =
    (data, id, method = "put") =>
    async (dispatch) => {
      try {
        const token = localStorage.getItem("token");
        const headers = { Authorization: `Token ${token}` };
        if (method == "put") {
          await axios.put(
            AccessControlEndpoints.profileAcessControlById(id),
            data,
            { headers }
          );
        } else {
          await axios.patch(
            AccessControlEndpoints.profileAcessControlById(id),
            data,
            { headers }
          );
        }
        notifiToasterSuccess("Data updated successfully");
      } catch (error) {
        notifiToasterWarn("Failed to update data");
        console.error("Error updating profile in access control:", error);
      }
    };
  static deleteProfileToAccessControl = (id) => async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Token ${token}` };

      await axios.delete(
        AccessControlEndpoints.deleteProfileInAccessControl(id),
        {
          headers,
        }
      );

      notifiToasterSuccess("Data Deleted successfully");
    } catch (error) {
      notifiToasterWarn("Failed to update data");
      console.error("Error updating profile in access control:", error);
    }
  };
  static deleteUserToAccessControl = (id) => async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Token ${token}` };

      await axios.delete(AccessControlEndpoints.deleteUserInAccessControl(id), {
        headers,
      });

      notifiToasterSuccess("Data Deleted successfully");
    } catch (error) {
      notifiToasterWarn("Failed to update data");
      console.error("Error updating profile in access control:", error);
    }
  };
  /**
   * Adds a user to access control.
   * Shows a success notification upon successful addition or a warning notification if the operation fails.
   * @param {Object} data - The data for the new user.
   */
  static addUserToAccessControl = (data) => async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Token ${token}` };

      await axios.post(AccessControlEndpoints.userAccessControlById(), data, {
        headers,
      });
      notifiToasterSuccess("Data saved successfully");
    } catch (error) {
      notifiToasterWarn("Failed to save data");
      console.error("Error adding user to access control:", error);
    }
  };
  static updateUserToAccessControl = (id, data) => async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Token ${token}` };

      await axios.patch(
        AccessControlEndpoints.upgradeUserDetailAccessControl(id),
        data,
        {
          headers,
        }
      );
      notifiToasterSuccess("Data saved successfully");
    } catch (error) {
      notifiToasterWarn("Failed to save data");
      console.error("Error adding user to access control:", error);
    }
  };

  static transformData(data) {
    // Initialize an empty array to hold the transformed objects
    let transformed = [];

    // Iterate through each item in the data array
    data.forEach((item) => {
      // Aggregate profile names and IDs, assuming profile_info is an array
      const profileNames = item.profile_info
        .map((profile) => profile.profile_name)
        .join(", ");
      const profileIds = item.profile_info.map((profile) => profile.id);

      // Aggregate group names and IDs, assuming group_table_info is an array
      const groupNames = item.group_table_info
        .map((group) => group.group_name)
        .join(", ");
      const groupIds = item.group_table_info.map((group) => group.id);

      transformed.push({
        name: item.user_profile.name,
        username: item.username,
        user_id: item.user_profile.user,
        user_profile_id: item.user_profile_id,
        // Join group names and IDs with comma if there are multiple, else default to empty string or -1
        group_name: groupNames.length > 0 ? groupNames : "",
        group_id: groupIds.length > 0 ? groupIds : [-1], // Group IDs as an array

        profile_name: profileNames,
        profile_id: profileIds.length > 0 ? profileIds : [-1], // Profile IDs as an array
        active_status: item.active_status,
      });
    });
    // Return the transformed array
    return transformed;
  }

  /**
   * Adds a user to access control.
   * Shows a success notification upon successful addition or a warning notification if the operation fails.
   * @param {Object} data - The data for the new user.
   */
  static getUserToAccessControlList = () => async (dispatch) => {
    try {
      dispatch({ type: "SET_LOADING_FOR_USER", payload: true });
      const userprofileId = JSON.parse(localStorage.getItem("profile")).id;
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Token ${token}` };

      let response = await axios.get(
        AccessControlEndpoints.userListByUserprofileById(userprofileId),
        {
          headers,
        }
      );
      let data = AccessControlActions.transformData(response.data.data);
      console.log(data, "--");
      dispatch({ type: "ADD_DATA_TO_USER_LIST", payload: data });
    } catch (error) {
      console.error("Error adding user to access control:", error);
    } finally {
      dispatch({ type: "SET_LOADING_FOR_USER", payload: false });
    }
  };
  static changeActiveStatus = (userId, data) => async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Token ${token}` };

      let response = await axios.patch(
        AccessControlEndpoints.userApi(userId),
        data,
        {
          headers,
        }
      );
      notifiToasterSuccess("Status Changed");
    } catch (error) {
      console.error("Error adding user to access control:", error);
    }
  };
  static EnableLiveview = (userprofile_Id) => async () => {
    try {
      const token = JSON.parse(localStorage.getItem("onboardUser")).token;
      const headers = { Authorization: `Token ${token}` };
      const data = { live_view: true };
      let response = await axios.patch(
        AccessControlEndpoints.userAccessControlById(userprofile_Id),
        data,
        {
          headers,
        }
      );
      notifiToasterSuccess("Status Changed");
    } catch (error) {
      console.error("Error adding user to access control:", error);
    }
  };
  static updateGroupData = (data) => async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Token ${token}` };

      await axios.post(AccessControlEndpoints.updateGroupData(), data, {
        headers,
      });
    } catch {
      null;
    }
  };
  /**
   * Fetches a list of permissions and dispatches it to the Redux store.
   * Includes error handling to gracefully manage any issues that may arise during the API call.
   */
  static getDetailsOfNvrCameraFacility = async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Token ${token}` };
      const user_profile_id = JSON.parse(localStorage.getItem("profile")).id;
      const response = await axios.get(
        AccessControlEndpoints.getAccessDetailsById(user_profile_id),
        { headers }
      );
      return response.data;
    } catch (error) {
      return [];
    }
  };
}

export default AccessControlActions;
