const initialState = {
  facilityList: [],
  nvrList: [],
  cameraList: { count: 0, data: [] },
};

export default function SystemParametersReducers(state = initialState, action) {
  switch (action.type) {
    case "SYSTEM_PARAMTER_ADD_FACILITY_LIST":
      return {
        ...state,
        facilityList: action.payload,
      };
    case "SYSTEM_PARAMTER_ADD_NVR_LIST":
      return {
        ...state,
        nvrList: action.payload,
      };
    case "SYSTEM_PARAMTER_ADD_CAMERA_LIST":
      return {
        ...state,
        cameraList: action.payload,
      };

    default:
      return state;
  }
}
