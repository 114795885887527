import React, { useEffect, useState } from "react";
import AlertChartFunction from "./AlertChartFunctions";
import AlertLineChart from "../../components/charts/AlertLineChart";
import { AlertPiechart } from "../../components/charts/AlertPiechart";
import AlertBarChart from "../../components/charts/AlertBarChart";
import { DashWeekBarChart } from "../../components/charts/DashWeekBarChart";
import Areachart from "../../components/charts/Areachart";
import { getTenSitesDataAction } from "../Alert/AlertChartData";
import WeeklyStaticsChart from "./WeeklyStaticsChart";
import { useDispatch, useSelector } from "react-redux";
import { MenuItem, Select } from "@mui/material";
import DashAreaChart from "../../components/charts/DashAreaChart";
import YumFoods from "../../components/charts/YumFoods";
const AlertCharts = (propTypes) => {
  const [selectedTenStore, setselectedTenStore] = useState([]);
  const state = useSelector((state) => state);
  const [yumFoodsData, setYumfoodData] = useState({});
  const [yumFoodsDataDateRange, setYumfoodDataDateRange] = useState({});
  const dispatch = useDispatch();
  const isDayWiseMode =
    state.AlertDashboardStates.selectedMode.value === "day-wise";
  const isSameDaySelected =
    state.commonData.dateRange.startDate == state.commonData.dateRange.endDate;
  const handleIpCamerasChange = (event) => {
    if (event.target.value.includes("all")) setselectedTenStore(["all"]);
    else setselectedTenStore(event.target.value);
  };
  useEffect(() => {
    setYumfoodData(
      AlertChartFunction.convertYumFoodsDataToChartData(
        state.AlertData.yumFoodsChartData,
        isSameDaySelected
      )
    );
  }, [state.AlertData.yumFoodsChartData]);
  useEffect(() => {
    setYumfoodDataDateRange(
      AlertChartFunction.convertYumFoodsDataToChartData(
        state.AlertData.yumFoodsChartDataDateRange,
        isSameDaySelected
      )
    );
  }, [state.AlertData.yumFoodsChartDataDateRange]);
  const profile = JSON.parse(localStorage.getItem("profile"));
  useEffect(() => {
    if (selectedTenStore.length > 0) {
      getTenSitesDataAction(
        profile.id,
        selectedTenStore,
        propTypes.selectedDate,
        state.AlertTableStates.selectedPriority.value,
        state.AlertDashboardStates.selectedAlertType.value,
        dispatch
      );
    }
  }, [selectedTenStore, state.AlertDashboardStates.selectedAlertType.value]);

  const inputselect = {
    boxShadow: "none",
    borderRadius: "8px",
    ".MuiOutlinedInput-notchedOutline": { border: 0 },
    MenuProps: {
      PaperProps: {
        style: {
          backgroundColor: "var(--top-bar-bg-color)",
          maxHeight: 350,
          width: 50,
          overflowX: "auto",
        },
      },
    },
  };

  const resolvedAlertData = isDayWiseMode
    ? state.AlertData.resolvedAlertHourly
    : state.AlertData.resolvedAlertDaywise;

  const unresolvedAlertData = isDayWiseMode
    ? state.AlertData.unresolvedAlertHourly
    : state.AlertData.unresolvedAlertDaywise;

  const totalAlertData = isDayWiseMode
    ? state.AlertData.totalAlertHourly
    : state.AlertData.totalAlertDaywise;

  const resolvedAlertCount =
    AlertChartFunction.totalAlertCount(resolvedAlertData);
  const unresolvedAlertCount =
    AlertChartFunction.totalAlertCount(unresolvedAlertData);
  const totalAlertCount = AlertChartFunction.totalAlertCount(totalAlertData);

  const resolvedPercentage = (
    (resolvedAlertCount / totalAlertCount) *
    100
  ).toFixed(2);
  const unresolvedPercentage = (
    (unresolvedAlertCount / totalAlertCount) *
    100
  ).toFixed(2);

  return (
    <div>
      <div className="row">
        {isDayWiseMode ? (
          <div className="col-xxl-8" style={{ overflow: "hidden" }}>
            <AlertLineChart
              classNameActive={true}
              data={AlertChartFunction.totalAlertsHourlyGraphData(
                totalAlertData
              )}
              title="People Count"
              type="hourly"
              head="Total Alerts"
              barsie={10}
            />
          </div>
        ) : (
          <div className="col-xxl-12">
            <AlertLineChart
              classNameActive={true}
              data={AlertChartFunction.totalAlertsHourlyGraphData(
                totalAlertData
              )}
              title="People Count"
              type="daterange"
              head="Total Alerts"
              barsie={10}
            />
          </div>
        )}
        {isDayWiseMode && (
          <div className="col-xxl-4">
            <WeeklyStaticsChart
              classNameActive={true}
              data={AlertChartFunction.totalWeeklyGraphData(
                state.AlertData.totalWeeklyAlerts
              )}
              title="Week Day Comparison"
            />
          </div>
        )}
      </div>
      {profile.services_selected.Alert.includes("temple_alert_type") && (
        <div className="chart-container">
          <div className="row">
            <div className="col-12">
              <DashAreaChart
                classNameActive={false}
                data={AlertChartFunction.alertTypeFilterMainFunction(
                  state.AlertData.alertTypeStatics
                )}
                type="hourly"
                title="Hourly Alert Type Report"
                labelToShowY="No Of Alerts"
              />
            </div>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-md-6">
          {isDayWiseMode ? (
            <div className="alertchartdiv">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: "50px",
                }}
              >
                <div className="divabovesideIcon">
                  <div className="spaceremoveofpara">Resolved Alerts</div>
                  <div className="head-text-app">{resolvedAlertCount}</div>
                  <span className="unResolveCount">
                    {isNaN(resolvedPercentage) ? "0" : resolvedPercentage}%
                  </span>
                </div>
              </div>
              <Areachart
                data={AlertChartFunction.resolvedHourly(resolvedAlertData)}
                type="hourly"
                color="#05CD99"
              />
            </div>
          ) : (
            <div className="alertchartdiv">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: "50px",
                }}
              >
                <div className="divabovesideIcon">
                  <div className="spaceremoveofpara">Resolved Alerts</div>
                  <div className="head-text-app">{resolvedAlertCount}</div>
                  <span className="unResolveCount">
                    {isNaN(resolvedPercentage) ? "0" : resolvedPercentage}%
                  </span>
                </div>
              </div>
              <Areachart
                data={AlertChartFunction.resolvedHourly(resolvedAlertData)}
                type="daterange"
                color="#05CD99"
              />
            </div>
          )}
        </div>

        <div className="col-md-6">
          {isDayWiseMode ? (
            <div className="alertchartdiv">
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: "50px",
                  width: "100%",
                }}
              >
                <div className="divabovesideIcon">
                  <div className="spaceremoveofpara">Unresolved Alerts</div>
                  <div className="head-text-app">{unresolvedAlertCount}</div>
                  <span className="resolveCountColor">
                    {isNaN(unresolvedPercentage) ? "0" : unresolvedPercentage}%
                  </span>
                </div>
              </div>
              <Areachart
                data={AlertChartFunction.unresolvedHourly(unresolvedAlertData)}
                color="#E91407"
                type="hourly"
              />
            </div>
          ) : (
            <div className="alertchartdiv">
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: "50px",
                  width: "100%",
                }}
              >
                <div className="divabovesideIcon">
                  <div className="spaceremoveofpara">Unresolved Alerts</div>
                  <div className="head-text-app">{unresolvedAlertCount}</div>
                  <span className="resolveCountColor">
                    {isNaN(unresolvedPercentage) ? "0" : unresolvedPercentage}%
                  </span>
                </div>
              </div>
              <Areachart
                data={AlertChartFunction.unresolvedHourly(unresolvedAlertData)}
                color="#E91407"
                type="daterange"
              />
            </div>
          )}
        </div>
      </div>

      <div className="row">
        {isDayWiseMode ? (
          <div className="col-xxl-6">
            <AlertBarChart
              title="Alerts"
              data={AlertChartFunction.topSevenSiteAlertsGraphData(
                state.AlertData.topSevenSiteAlertData
              )}
            />
          </div>
        ) : (
          <div className="col-xxl-6">
            <AlertBarChart
              title="Alerts"
              data={AlertChartFunction.topSevenSiteAlertsGraphData(
                state.AlertData.topSevenSiteAlertDataDateRange
              )}
            />
          </div>
        )}
        {isDayWiseMode ? (
          <div className="col-12 col-sm-12 col-xxl-6">
            <AlertPiechart
              head="Alerts"
              data={AlertChartFunction.separateAlertTypesAndCounts(
                state.AlertData.totalAlertTypeData
              )}
            />
          </div>
        ) : (
          <div className="col-12 col-sm-12 col-xxl-6">
            <AlertPiechart
              head="Alerts"
              data={AlertChartFunction.separateAlertTypesAndCounts(
                state.AlertData.totalAlertTypeDataDateRange
              )}
            />
          </div>
        )}
      </div>
      {isDayWiseMode && state.commonData.storeList.length > 8 && (
        <div className="row m-0 mt-4">
          <div className="chart-container">
            <div
              className="col-10 col-sm-6 col-md-4 col-lg-4 col-xl-2 col-xxl-2"
              style={{ marginLeft: "20px", paddingTop: "20px" }}
            >
              <div className="select-input-lebel">Select Max 30 Facility</div>
              <div className="select-input-lebel"></div>
              <Select
                multiple
                displayEmpty
                value={selectedTenStore}
                onChange={handleIpCamerasChange}
                sx={{ color: "var(--card-text-color)", ...inputselect }}
                className="select-input-field"
                MenuProps={inputselect.MenuProps}
                renderValue={(selectedTenStore) => {
                  if (selectedTenStore.length === 0) {
                    return <em>Select </em>;
                  }
                  return selectedTenStore.length;
                }}
              >
                <MenuItem disabled value="">
                  <em style={{ color: "var(--card-text-color)" }}>
                    Select ip camera
                  </em>
                </MenuItem>
                {state.commonData.tenStoreList.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    sx={{ color: "var(--card-text-color)" }}
                  >
                    {option.text}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <DashWeekBarChart
              classNameActive={false}
              data={AlertChartFunction.topTenSitesAlertData(
                state.AlertData.tenSitesAlerts
              )}
              xlabel="facility"
            />
          </div>
        </div>
      )}
      {profile.services_selected.Dashboard.includes("yum_foods_demo") && (
        <div className="col-xxl-12">
          {isSameDaySelected ? (
            <YumFoods
              seriesData={yumFoodsData.seriesData ?? []}
              label={yumFoodsData.label ?? []}
              mode={"day-wise"}
            />
          ) : (
            <YumFoods
              seriesData={yumFoodsDataDateRange.seriesData ?? []}
              label={yumFoodsDataDateRange.label ?? []}
              mode={"date-range"}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default AlertCharts;
