import { Outlet } from "react-router-dom";
import { Box, Toolbar } from "@mui/material";
import sizeConfigs from "../../configs/sizeConfigs";
import Sidebar from "../common/Sidebar";
import Topbar from "../common/Topbar";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import useWindowDimensions from "../common/useWindowDimensions";
import { ToastContainer } from "react-toastify";
import VideoSidebar from "../common/VideoSidebar";
import LiveViewAction from "../../redux/Action/LiveViewAction";
import { getNewUserDetails } from "../../redux/Action/Login";
import { useLocation } from "react-router-dom";
// import ReactGA from "react-ga";
import AppLoader from "../common/AppLoader";
import Account from "../../redux/Action/CommonAction";
import AlertTableActions from "../../redux/Action/AlertTableActions";
import VmsSidebar from "../../pages/VMS/VmsSidebar/VmsSideBar";
const MainLayout = () => {
  const commonData = useSelector((state) => state.commonData);
  const { width } = useWindowDimensions();

  const toggle = commonData.toggleSidebar;
  const dispatch = useDispatch();
  let widtht = toggle
    ? sizeConfigs.sidebarMin.width
    : sizeConfigs.sidebar.width;
  async function getAlertEventNotificationList() {
    var profile = JSON.parse(localStorage.getItem("profile"));
    let alertEventList =
      await AlertTableActions.getAlertEventNotificationList();
    const filteredOptions = alertEventList.filter((option) => {
      return (
        profile.services_selected.Event.includes(option.notification_type) ||
        profile.services_selected.Alert.includes(option.notification_type)
      );
    });
    dispatch(AlertTableActions.updateTotalAlertEvent(filteredOptions));
    dispatch(AlertTableActions.updateAlertTypesSelected(filteredOptions));
  }
  async function getAlertEventAll() {
    let alertEventList = await AlertTableActions.getAlertEventList();
    if (alertEventList.length > 0) {
      dispatch(AlertTableActions.updateTotalAlertEvent(alertEventList));
      dispatch(AlertTableActions.updateAlertTypesSelected(alertEventList));
    }
  }

  //Code to close all the previous stream which are not closed due to some reason
  useEffect(() => {
    let profile = JSON.parse(localStorage.getItem("profile"));
    let isTypesAlreadyStore =
      JSON.parse(sessionStorage.getItem("selected_notification_type")) ?? [];
    dispatch(Account.getStoresByAccount(profile.id));
    getNewUserDetails();
    if (isTypesAlreadyStore.length == 0) {
      getAlertEventNotificationList();
    }
    if (profile.account.config.Operation != "True") {
      getAlertEventAll();
    }
    try {
      const PrevRequestToStop = JSON.parse(sessionStorage.getItem("NewItems"));
      if (PrevRequestToStop) {
        PrevRequestToStop.map((item) => {
          const stop = {
            stream_id: item.stream_id,
            vpn_tunnel: item.vpn_tunnel,
          };
          LiveViewAction.stopLiveStream(stop)(dispatch);
        });
        sessionStorage.removeItem("NewItems");
      }
    } catch (error) {
      console.error(
        "An error occurred while executing the previous live stream request:",
        error
      );
    }
    const muteNotification =
      localStorage.getItem("muteNotification") === "true";
    if (muteNotification) {
      window?.socket?.disconnect();
    }
    let isDarkMode = localStorage.getItem("myapp_theme") == "dark";
    dispatch({ type: "UPDATE_THEME", payload: isDarkMode });
  }, []);
  const location = useLocation();
  // useEffect(() => {
  //   ReactGA.pageview(location.pathname + location.search);
  // }, [location]);
  return (
    <div>
      <ToastContainer
        className={
          commonData.muteNotification
            ? "my-toast-container d-none"
            : "my-toast-container"
        }
        limit={1}
        autoClose={1500}
      />
      <Box
        sx={{ display: "flex", backgroundColor: "var(--side-bar-bg-color)" }}
      >
        <Topbar />
        {width >= 800 ? (
          <Box
            component="nav"
            sx={{
              width: widtht,
              flexShrink: 0,
            }}
          >
            {commonData.toggleVideoWallSidebar ? <VmsSidebar /> : <Sidebar />}
          </Box>
        ) : (
          ""
        )}

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: `calc(100% - ${widtht})`,
            minHeight: "100vh",
            backgroundColor: "var(--top-bar-bg-color)",
          }}
        >
          <Topbar />
          <Toolbar />
          <Outlet />
        </Box>
      </Box>
    </div>
  );
};

export default MainLayout;
