const initialState = {
  selectedMode: { value: "day-wise" },
  selectedFacility: { value: "all" },
  selectedDevice: { value: "all" },
  selectedEventType: { value: "all" },
  time: [0, 23.99],
  timeRange: [0, 23.59],
  selectedClient: { value: "all" },
  searchValue: "",
  searchValueLength: 0,
  currentPage: 1,
  tabValue: [false, false],
  tabName: "event",
  EventTypes: [],
  facilityOpeningTime: "",
  facilityClosingTime: "",
  EventPlayback: [],
  selectedUseCase: { value: "all" },
};
export default function EventTableReducer(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_SELECTED_MODE_EVENT":
      return {
        ...state,
        selectedMode: action.payload,
      };
    case "UPDATE_FACILITY_EVENT":
      return {
        ...state,
        selectedFacility: action.payload,
      };
    case "UPDATE_DEVICE_EVENT":
      return {
        ...state,
        selectedDevice: action.payload,
      };
    case "UPDATE_CLIENT_EVENT":
      return {
        ...state,
        selectedClient: action.payload,
      };
    case "UPDATE_EVENT_TYPE_EVENT":
      return {
        ...state,
        selectedEventType: action.payload,
      };
    case "UPDATE_SEARCH_VALUE_EVENT":
      return {
        ...state,
        searchValue: action.payload,
      };
    case "UPDATE_SEARCH_VALUE_LENGTH_EVENT":
      return {
        ...state,
        searchValueLength: action.payload,
      };
    case "UPDATE_TIME_EVENT":
      return {
        ...state,
        timeRange: action.payload,
      };
    case "UPDATE_CURRENT_PAGE_EVENT":
      return {
        ...state,
        currentPage: action.payload,
      };
    case "UPDATE_TAB_VALUE_EVENT":
      return {
        ...state,
        tabValue: action.payload,
      };
    case "UPDATE_TAB_NAME_EVENT":
      return {
        ...state,
        tabName: action.payload,
      };
    case "UPDATE_EVENT_TYPES_SELECETED":
      return {
        ...state,
        EventTypes: action.payload,
      };
    case "UPDATE_OPENING_TIME":
      return {
        ...state,
        facilityOpeningTime: action.payload,
      };
    case "UPDATE_CLOSING_TIME":
      return {
        ...state,
        facilityClosingTime: action.payload,
      };
    case "UPDATE_EVENT_PLAYBACK_STREAM":
      return {
        ...state,
        EventPlayback: action.payload,
      };
    case "UPDATE_USECASE_SELECTED":
      return {
        ...state,
        selectedUseCase: action.payload,
      };

    default:
      return state;
  }
}
