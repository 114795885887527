import axios from "axios";

import ApiSpec from "../features/EndPoints";
import { notifiToasterSuccess } from "../../components/common/UseToast";

// get Store

class OperationModeAction {
  // people Count courly functions
  static getStoresByAccount(data) {
    // var profile = JSON.parse(localStorage.getItem("profile"));
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      await axios
        .get(ApiSpec.getStoresList(data), { headers })
        .then((response) => {
          let stores = [];
          response.data.forEach((element) => {
            stores.push({ text: element.facility_name, value: element.id });
          });
          dispatch({ type: "STORE_LIST_DATA_MODAL", payload: stores });
        });
    };
  }
  static getFacilitiesByAccountId(data) {
    return async (dispatch) => {
      // Retrieve the entire map from session storage
      const facilitiesMap = JSON.parse(
        sessionStorage.getItem("facilitiesMap") || "{}"
      );

      // Check if facilities for the specific account ID already exist
      if (facilitiesMap[data]) {
        // If they exist, dispatch the cached data
        dispatch({
          type: "FACILITY_BY_ACCOUNT_LIST_DATA_MODAL",
          payload: facilitiesMap[data],
        });
      } else {
        // If not, fetch from API
        var token = localStorage.getItem("token");
        let headers = {
          Authorization: `Token ${token}`,
        };
        await axios
          .get(ApiSpec.getAllFacilityByAccountOP(data), { headers })
          .then((response) => {
            let stores = [];
            response.data.forEach((element) => {
              stores.push({ label: element.facility_name, value: element.id });
            });
            // Update the map with the new data
            facilitiesMap[data] = stores;
            sessionStorage.setItem(
              "facilitiesMap",
              JSON.stringify(facilitiesMap)
            );
            dispatch({
              type: "FACILITY_BY_ACCOUNT_LIST_DATA_MODAL",
              payload: stores,
            });
          });
      }
    };
  }

  static getAllAccount(data) {
    return async (dispatch) => {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Token ${token}`,
      };

      // Check for storedAccData in sessionStorage
      const storedAccData = JSON.parse(sessionStorage.getItem("storedAccData"));

      if (storedAccData) {
        // If stored data exists, dispatch it
        dispatch({ type: "ACCOUNT_LIST_DATA_MODAL", payload: storedAccData });
      } else {
        // If stored data does not exist, fetch from API
        try {
          const response = await axios.get(ApiSpec.getAllAccount(data), {
            headers,
          });
          let stores = response.data.map((element) => ({
            label: element.name,
            value: element.account_id,
          }));

          // Store the fetched data in sessionStorage
          sessionStorage.setItem("storedAccData", JSON.stringify(stores));

          // Dispatch the fetched data
          dispatch({ type: "ACCOUNT_LIST_DATA_MODAL", payload: stores });
        } catch (error) {
          console.error("Error fetching accounts:", error);
          // Handle error appropriately (e.g., dispatch error action, show notification, etc.)
        }
      }
    };
  }

  static getCamerasByStore(data) {
    return async (dispatch) => {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Token ${token}`,
      };

      let storedCamData =
        JSON.parse(sessionStorage.getItem("storedCamData")) || {};
      let camData = [];

      if (storedCamData[data]) {
        camData = storedCamData[data];
      } else {
        try {
          const response = await axios.get(ApiSpec.getCamerasByStore(data), {
            headers,
          });
          camData = response.data.map((element) => ({
            label: element.camera_name,
            value: element.id,
          }));

          storedCamData[data] = camData;
          sessionStorage.setItem(
            "storedCamData",
            JSON.stringify(storedCamData)
          );
        } catch (error) {
          console.error("Error fetching cameras by store:", error);
          // Handle error appropriately (e.g., dispatch error action, show notification, etc.)
        }
      }
      dispatch({ type: "CAMERAS_LIST_DATA_MODAL", payload: camData });
    };
  }

  static transferAlert(data) {
    return async (dispatch) => {
      axios.post(ApiSpec.transferAlert(), data).then((response) => {
        notifiToasterSuccess("Alert Transferred");
      });
    };
  }
  static deleteAlerts(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      try {
        await axios.delete(ApiSpec.deleteAlerts(data), { headers });
        notifiToasterSuccess("Alert Deleted");
      } catch (error) {
        console.error("Error deleting alerts:", error);
      }
    };
  }
  static transferEvent(data) {
    return async (dispatch) => {
      axios.post(ApiSpec.transferEvent(), data).then((response) => {
        notifiToasterSuccess("Alert Transferred");
      });
    };
  }
  static deleteEvents(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      try {
        await axios.delete(ApiSpec.deleteEvents(data), { headers });
        notifiToasterSuccess("Event Deleted");
      } catch (error) {
        console.error("Error deleting events:", error);
      }
    };
  }
}

export default OperationModeAction;
