import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useSelector, useDispatch } from "react-redux";
import AlertTableActions from "../../../redux/Action/AlertTableActions";
import AlertDashAction from "../../../redux/Action/AlertDashAction";
const inputselect = {
  boxShadow: "none",
  borderRadius: "8px",
  ".MuiOutlinedInput-notchedOutline": { border: 0 },
  MenuProps: {
    PaperProps: {
      style: {
        backgroundColor: "var(--top-bar-bg-color)",
        maxHeight: 350,
        width: 50,
        overflowX: "auto",
      },
    },
  },
};
const AlertFilter = (propTypes) => {
  const AlertTableStates = useSelector((state) => state.AlertTableStates);
  const AlertDashStates = useSelector((state) => state.AlertDashboardStates);
  const dispatch = useDispatch();
  function handleAlertOrEvent(option) {
    dispatch(
      AlertTableActions.updateAlertOrEvent(option.alert_or_event.toLowerCase())
    );
  }

  const handelAlertTypeChange = (event) => {
    if (propTypes.type == "AlertDashboard") {
      dispatch(AlertDashAction.updateAlertType({ value: event.target.value }));
    } else {
      dispatch(
        AlertTableActions.updateAlertType({ value: event.target.value })
      );
    }
  };

  return (
    <div>
      <div className="select-input-lebel">Alert Type</div>
      <Select
        value={
          propTypes.type == "AlertDashboard"
            ? AlertDashStates.selectedAlertType.value
            : AlertTableStates.selectedAlertType.value
        }
        sx={{
          color: "var(--card-text-color)",
          ...inputselect,
        }}
        className="select-input-field"
        MenuProps={inputselect.MenuProps}
        onChange={handelAlertTypeChange}
      >
        <MenuItem
          value="all"
          sx={{ color: "var(--card-text-color)" }}
          onClick={() => handleAlertOrEvent({ alert_or_event: "all" })}
        >
          All
        </MenuItem>
        {AlertTableStates.AlertTypes.map((option) => (
          <MenuItem
            key={option.notification_type}
            value={option.notification_type}
            onClick={() => handleAlertOrEvent(option)}
            sx={{
              paddingTop: "2px",
              paddingBottom: "2px",
              color: "var(--card-text-color)",
            }}
          >
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default AlertFilter;
