import React from "react";
import io from "socket.io-client";
import { socketUrl } from "../../routes/config";
import DesktopNotification from "./Desktopnotification";
import { notifiToasterSuccess } from "../../components/common/UseToast";

let lastNotificationTime = 0;

const Layout = () => {
  // const desktopNotification = new DesktopNotification();
  // const profile = JSON.parse(localStorage.getItem("profile"));
  // const profile_id = JSON.parse(localStorage.getItem("profile_id"));
  // console.log(socket);
  // const socket = io(`https://staging.agrexai.com`);
  // window.socket = socket;
  // socket.on("connect", () => {
  //   console.log("Socket Connected");
  // });
  // socket.on("notification", (data) => {
  //   console.log(data);
  // });
  // socket.on("connect_error", (err) => {
  //   console.error("Socket connection error:", err);
  // });
  // socket.on("connect_error", (err) => {
  //   console.log("Socket connection error:", err);
  // });
  // Alerts socket reciever
  // socket.on(`alerts_${profile.id}`, (res) => {
  //   desktopNotification.sendDesktopNotification({
  //     title: "Alerts",
  //     message: `Store: ${res.data.facility}\nType: ${res.data.type}`,
  //     image: res?.data?.image,
  //   });
  //   {
  //     /* If profileId is 63(DPS) then dont show the notification for alerts*/
  //   }
  //   if (profile_id != 63) {
  //     notifiToasterSuccess(
  //       <div>
  //         <b>Alert</b> <br />
  //         Store: {res.data.facility} <br />
  //         Type: {res.data.type}
  //       </div>
  //     );
  //   }
  //   addSocketNotifications({
  //     ...res.data,
  //     keyword: "Alert",
  //     variant: "danger",
  //     url: "/alerts/",
  //   });
  // });
  // Events socket reciever
  // socket.on(`events_${profile.id}`, (res) => {
  //   desktopNotification.sendDesktopNotification({
  //     title: "Events",
  //     message: `Store: ${res.data.facility}\nType: ${res.data.type}`,
  //     image: res?.data?.image,
  //   });
  //   notifiToasterSuccess(
  //     <div>
  //       <b>Event</b> <br />
  //       Store: {res.data.facility} <br />
  //       Type: {res.data.type}
  //     </div>
  //   );
  //   addSocketNotifications({
  //     ...res.data,
  //     keyword: "Event",
  //     variant: "success",
  //     url: "/events/",
  //   });
  // });
  // socket.on("disconnect", (reason) => {
  //   console.log("Socket disconnected. Reason:", reason);
  // });
};

export default Layout;
