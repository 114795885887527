const initialState = {
  selectedMode: { value: "day-wise" },
  selectedFacility: { value: "all" },
  selectedDevice: { value: "all" },
  selectedAlertType: { value: "all" },
  time: [0, 23.99],
  timeRange: [0, 23.59],
  selectedClient: { value: "all" },
  AlertTypes: [],
  pizzaCount: { count: 0, distribution: [] },
};
export default function AlertDashReducer(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_SELECTED_MODE_ALERT_DASH":
      return {
        ...state,
        selectedMode: action.payload,
      };
    case "UPDATE_FACILITY_ALERT_DASH":
      return {
        ...state,
        selectedFacility: action.payload,
      };
    case "UPDATE_DEVICE_ALERT_DASH":
      return {
        ...state,
        selectedDevice: action.payload,
      };
    case "UPDATE_CLIENT_ALERT_DASH":
      return {
        ...state,
        selectedClient: action.payload,
      };
    case "UPDATE_ALERT_TYPE_ALERT_DASH":
      return {
        ...state,
        selectedAlertType: action.payload,
      };

    case "UPDATE_ALERT_TYPE_SELECTED_ALERT_DASH":
      return {
        ...state,
        AlertTypes: action.payload,
      };
    case "UPDATE_PIZZA_COUNT_STATS":
      return {
        ...state,
        pizzaCount: action.payload,
      };
    case "RESET_ALERT_DASH_STATES":
      return {
        ...state,
        selectedFacility: { value: "all" },
        selectedDevice: { value: "all" },
        selectedAlertType: { value: "all" },
        time: [0, 23.99],
        timeRange: [0, 23.59],
        selectedClient: { value: "all" },
      };
    default:
      return state;
  }
}
