import axios from "axios";
import ApiSpec from "../features/EndPoints";
import { notifiToasterWarn } from "../../components/common/UseToast";
import LiveViewAction from "./LiveViewAction";
function formatDateTime(dateTimeStr) {
  let date = dateTimeStr.split("T");
  return `${date[0]}, ${date[1].split("Z")[0]}`;
}
class VmsLiveviewActions {
  static getAllFacilitiesCameraLiveview(data) {
    // var profile = JSON.parse(localStorage.getItem("profile"));
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      await axios
        .get(ApiSpec.getAllFacilityCamera(data), { headers })
        .then((response) => {
          dispatch({
            type: "UPDATE_FACILITY_NVR_CAMERA_LIST_FOR_VMS",
            payload: response.data,
          });
        });
    };
  }
  static getAllFacilitiesCameraPlayback(data) {
    // var profile = JSON.parse(localStorage.getItem("profile"));
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      await axios
        .get(ApiSpec.getAllFacilityCameraPlayBack(data), { headers })
        .then((response) => {
          dispatch({
            type: "UPDATE_FACILITY_NVR_CAMERA_LIST_FOR_VMS",
            payload: response.data,
          });
        });
    };
  }
  static UpdateLiveviewFeature(data) {
    return async (dispatch) => {
      dispatch({
        type: "UPDATE_LIVEVIEW_FEATURE_VMS",
        payload: data,
      });
    };
  }
  // Dispatches an action to add an array of cameras to the playing camera list in the Redux store.

  static updateSelectedCameraList(data) {
    return async (dispatch) => {
      dispatch({
        type: "ADD_CAMERA_TO_SELECTED_CAMERA_LIST",
        payload: data,
      });
    };
  }
  /**
   * Dispatches an action to replace an array of cameras to the playing camera list in the Redux store.
   *
   * @param {Array} data - An array of camera objects to be replaced to the playing camera list.
   * @returns {Function} An async thunk action that dispatches the action to add cameras.
   */
  static replaceSelectedCameraList(data) {
    return async (dispatch) => {
      dispatch({
        type: "REPLACE_CAMERAS_TO_SELECTED_CAMERA_LIST",
        payload: data,
      });
    };
  }
  static removeCameraFromLiveViewData(data, playlist) {
    return async (dispatch) => {
      console.log("receive camera to remove", data);
      playlist.forEach((item) => {
        if (data.includes(item.cam_id)) {
          LiveViewAction.stopLiveStream({
            stream_id: item.stream_id,
            vpn_tunnel: item.vpn_tunnel,
          })(dispatch);
        }
      });

      dispatch({
        type: "REMOVE_SELECTED_LIVE_FEEDS_VMS",
        payload: data,
      });
    };
  }
  static replacePlayingList(data) {
    return async (dispatch) => {
      dispatch({
        type: "REPLACE_PLAYLIST_WITH_NEW_PLAYLIST",
        payload: data,
      });
    };
  }
  /**
   * Dispatches an action to update the current page in the Redux store.
   *
   * @param {number} page - The new page number to be set as the current page.
   * @returns {Function} An async thunk action that dispatches the action to update the current page.
   */
  static updateCurrentPage(page) {
    return async (dispatch) => {
      dispatch({
        type: "UPDATE_CURRENT_PAGE_VMS",
        payload: page,
      });
    };
  }
  static updateTempCurrentPage(page) {
    return async (dispatch) => {
      dispatch({
        type: "UPDATE_TEMP_CURRENT_PAGE_VMS",
        payload: page,
      });
    };
  }
  /**
   * Dispatches an action to update the selected grid size in the Redux store.
   *
   * @param {number} grid - The new grid size to be set as the selected grid.
   * @returns {Function} An async thunk action that dispatches the action to update the selected grid.
   */
  static updateSelectedGrid(grid) {
    return async (dispatch) => {
      dispatch({
        type: "UPDATE_SELECTED_GRID_VMS",
        payload: grid,
      });
    };
  }

  static updateSelectedQuality(data) {
    return async (dispatch) => {
      dispatch({
        type: "UPDATE_SELECTED_QUALITY_VMS",
        payload: data,
      });
    };
  }
  static updateShowAlertDialog(data) {
    return async (dispatch) => {
      dispatch({
        type: "SHOW_DIALOG_TO_CHNAGE_THE_CAMERA",
        payload: data,
      });
    };
  }
  static updateSelectedCameraForPlayback(data) {
    return async (dispatch) => {
      dispatch({
        type: "ADD_PLAYBACK_CAMERAS_IN_VMS",
        payload: data,
      });
    };
  }
  static removeSelectedCameraForPlayback(data) {
    return async (dispatch) => {
      dispatch({
        type: "REMOVE_SELECTED_CAMERA_FROM_PLAYBACK_LIST",
        payload: data,
      });
    };
  }

  static getPlaybackInformation(data) {
    return async (dispatch) => {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Token ${token}`,
      };

      try {
        const response = await axios.get(
          ApiSpec.getPlaybackStreamInfo(
            data.cam_id,
            data.date,
            data.startTime,
            data.endTime
          ),
          { headers }
        );

        if (response.data.length === 0) {
          notifiToasterWarn(
            "Sorry, unable to find continuous playback intervals for the selected time."
          );

          dispatch({
            type: "UPDATE_PLAYBACK_CHUNKS_AVAILABLE",
            payload: [],
          });
        } else {
          const formattedData = response.data.map(([start, end]) => [
            formatDateTime(start),
            formatDateTime(end),
          ]);

          dispatch({
            type: "UPDATE_PLAYBACK_CHUNKS_AVAILABLE",
            payload: formattedData,
          });
        }
      } catch (error) {
        dispatch({
          type: "UPDATE_PLAYBACK_CHUNKS_AVAILABLE",
          payload: [],
        });
      }
    };
  }
  static getPlaybackStream(data) {
    // var profile = JSON.parse(localStorage.getItem("profile"));
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      await axios
        .get(
          ApiSpec.getPlaybackStream(
            data.cam_id,
            data.date,
            data.startTime,
            data.endTime
          ),
          { headers }
        )
        .then((response) => {
          if (response.data.length == 0) {
            notifiToasterWarn(
              "Sorry, there is no recording available for the selected time."
            );
          } else {
            dispatch({
              type: "UPDATE_PLAYBACK_STREAM_VMS",
              payload: response.data,
            });
          }
        })
        .catch(() => {
          dispatch({
            type: "UPDATE_PLAYBACK_STREAM_VMS",
            payload: [],
          });
        });
    };
  }
  static removePlaybackStream() {
    return async (dispatch) => {
      dispatch({
        type: "REMOVE_PLAYBACK_STREAM_FOR_VMS",
      });
    };
  }
}

export default VmsLiveviewActions;
