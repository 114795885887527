import React from "react";
import mainCss from "../../assets/css/mainCss";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { MdiCctvOff } from "../../assets/icons/MdiCctvOff";
import { MdiCctv } from "../../assets/icons/MdiCctv";
const CameraMonitoringStatistics = () => {
  const {
    totalCameraCount,
    onlineCameraCount,
    offlineCameraCount,
    cameraStatsLoading,
  } = useSelector((state) => state.rhmsData);

  return (
    <div className="divbelowtopbarsecond">
      <div className="select-input-div card-custom card-stretch gutter-b">
        <div className="card-body-div">
          <div className="row">
            <div className="col-12 col-sm-6 col-xl-4">
              <div style={{ display: "flex" }}>
                <div>
                  <button
                    className="btn btn-sm m-1 shadow-none"
                    style={mainCss.bigButtonIcon}
                  >
                    <MdiCctv style={mainCss.bigIconInRhms} />
                  </button>
                </div>
                <div style={{ marginLeft: "10px", marginTop: "10px" }}>
                  <h5>Total Cameras</h5>
                  <h5>{!cameraStatsLoading ? totalCameraCount : "---"}</h5>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-4">
              <div style={{ display: "flex" }}>
                <div>
                  <button
                    className="btn btn-sm m-1 shadow-none"
                    style={mainCss.bigButtonIcon}
                  >
                    <MdiCctv style={mainCss.bigIconInRhms} />
                  </button>
                </div>
                <div style={{ marginLeft: "10px", marginTop: "10px" }}>
                  <h5>Online Cameras</h5>
                  <h5>{!cameraStatsLoading ? onlineCameraCount : "---"}</h5>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-4">
              <div style={{ display: "flex" }}>
                <div>
                  <button
                    className="btn btn-sm m-1 shadow-none"
                    style={mainCss.bigButtonIcon}
                  >
                    <MdiCctvOff style={mainCss.bigIconInRhms} />
                  </button>
                </div>
                <div style={{ marginLeft: "10px", marginTop: "10px" }}>
                  <h5>Offline Cameras</h5>
                  <h5>{!cameraStatsLoading ? offlineCameraCount : "---"}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CameraMonitoringStatistics;
