import { BACKEND_URL } from "../../routes/config";
class AccessControlEndpoints {
  constructor() {
    this.getDeviceOrNVRsFromFacility = (facility_ids, target_info) => {
      return `${BACKEND_URL}/controlled_access/device_nvr_facility_from_facility/${facility_ids}/${target_info}`;
    };
    this.getCameraFromNvrForAccessControll = (nvr_ids) => {
      return `${BACKEND_URL}/controlled_access/camera_nvr_from_nvr/${nvr_ids}`;
    };
    this.groupAccessControl = (id) => {
      if (id) {
        return `${BACKEND_URL}/controlled_access/group/${id}/`;
      }
      return `${BACKEND_URL}/controlled_access/group/`;
    };
    this.profileAcessControlById = (id) => {
      if (id) {
        return `${BACKEND_URL}/controlled_access/profile/${id}/`;
      }
      return `${BACKEND_URL}/controlled_access/profile/`;
    };
    this.userAccessControlById = (id) => {
      if (id) return `${BACKEND_URL}/operations/userprofiles/${id}/`;
      return `${BACKEND_URL}/controlled_access/create_user_and_userprofile`;
    };
    this.groupListByUserprofileId = (id) => {
      return `${BACKEND_URL}/controlled_access/group/?user_profile_id=${id}`;
    };
    this.profileListByUserprofileId = (id) => {
      if (!id) {
        return `${BACKEND_URL}/controlled_access/profile/`;
      }
      return `${BACKEND_URL}/controlled_access/profile/?user_profile_id=${id}`;
    };
    this.userListByUserprofileById = (id) => {
      return `${BACKEND_URL}/controlled_access/retrieve_user_and_userprofile/${id}`;
    };
    this.getPermissionsList = () => {
      return `${BACKEND_URL}/controlled_access/permissions/`;
    };
    this.userApi = (id) => {
      return `${BACKEND_URL}/operations/users/${id}/`;
    };
    this.getPermissionsByUserprofile = (id) => {
      return `${BACKEND_URL}/controlled_access/userprofile_permissions/${id}/`;
    };
    this.deleteProfileInAccessControl = (id) => {
      return `${BACKEND_URL}/controlled_access/profile_delete/${id}`;
    };
    this.upgradeUserDetailAccessControl = (id) => {
      return `${BACKEND_URL}/controlled_access/user_user_profile_patch/${id}`;
    };
    this.deleteUserInAccessControl = (id) => {
      return `${BACKEND_URL}/controlled_access/user_user_profile_delete/${id}`;
    };
    this.getGroupDetailsById = (id) => {
      return `${BACKEND_URL}/controlled_access/facility_device_camera_nvr_from_group/${id}`;
    };
    this.getAccessDetailsById = (id) => {
      return `${BACKEND_URL}/controlled_access/facility_device_camera_nvr_from_userprofile/${id}`;
    };
    this.addGroup = () => {
      return `${BACKEND_URL}/controlled_access/create_group`;
    };
    this.updateGroup = (id) => {
      return `${BACKEND_URL}/controlled_access/update_group/${id}`;
    };
    this.getAdminPermissionList = (id) => {
      return `${BACKEND_URL}/controlled_access/admin_permissions/${id}`;
    };
    this.updateGroupData = () => {
      return `${BACKEND_URL}/controlled_access/add_new_elements_to_group`;
    };
  }
}
export default new AccessControlEndpoints();
