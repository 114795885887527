import React, { useEffect, useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  IconButton,
  Paper,
  CircularProgress,
  TextField,
  Box,
  Button,
} from "@mui/material";
import { Edit, Delete, Add } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import SystemParametersActions from "../../../redux/Action/SystemParameterActions";
import CameraDialog from "./CameraDialog";
import ConfirmationDialog from "../../../components/common/ConfirmationDailog";
import { changeTopbarTitle } from "../../../redux/Action/ChangeTopbarTitle";
import AutocompleteCompUncontrollable from "../../../components/common/FormField/AutoCompleteUncontrollable";
import AutocompleteComp from "../../../components/common/FormField/Autocomplete";

const CameraTable = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedCamera, setSelectedCamera] = useState(null);
  const [isDeleteConfirmModelOpen, setDeleteOpen] = useState(false);
  const [selectDeletedId, setSelectedDeleteId] = useState(null);
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [selectedNvr, setSelectedNVR] = useState({
    label: "All",
    value: "all",
  });
  const dispatch = useDispatch();
  const facilitiesList = useSelector((state) => state.commonData.storeList);
  const NvrList = useSelector((state) => state.systemParameters.nvrList);
  const cameraList = useSelector((state) => state.systemParameters.cameraList);
  const [selectedFacility, setSelectedFacility] = useState({
    label: "All",
    value: "all",
  });

  // Fetch permissions and profile once
  const access_permission =
    JSON.parse(localStorage.getItem("agx_permission"))?.Customization ?? [];
  const profileId = JSON.parse(localStorage.getItem("profile"))?.id;

  const canAddCameras = access_permission.includes("add_cameras");
  const canEditCameras = access_permission.includes("edit_cameras");

  useEffect(() => {
    if (Array.isArray(facilitiesList)) {
      const facilities = facilitiesList.map((item) => ({
        value: item.value,
        label: item.text,
      }));
      setFacilityOptions(facilities);
    }
  }, [facilitiesList]);

  const handleFacilityChange = async (newValue) => {
    setSelectedFacility(newValue);
    setSelectedNVR({ label: "All", value: "all" });
    setLoading(true);
    try {
      if (newValue.label !== "All") {
        await dispatch(SystemParametersActions.getNVRsByStore(newValue.value));
        await dispatch(
          SystemParametersActions.getCamerasByUserProfile(
            profileId,
            newValue.value,
            selectedNvr.value,
            1,
            rowsPerPage,
            search
          )
        );
      } else {
        await dispatch(
          SystemParametersActions.getCamerasByUserProfile(
            profileId,
            newValue.value,
            selectedNvr.value,
            1,
            rowsPerPage,
            search
          )
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const handleNVRChange = async (e, newValue) => {
    setLoading(true);
    try {
      await dispatch(
        SystemParametersActions.getCamerasByUserProfile(
          profileId,
          selectedFacility.value,
          newValue.value,
          1,
          rowsPerPage,
          search
        )
      );
    } catch (error) {
      console.error("Error fetching cameras by user profile:", error);
    } finally {
      setSelectedNVR(newValue);
      setLoading(false);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    setLoading(true);
    dispatch(
      SystemParametersActions.getCamerasByUserProfile(
        profileId,
        selectedFacility.value,
        selectedNvr.value,
        newPage + 1,
        rowsPerPage,
        search
      )
    ).finally(() => setLoading(false));
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);

    setLoading(true);
    dispatch(
      SystemParametersActions.getCamerasByUserProfile(
        profileId,
        selectedFacility.value,
        selectedNvr.value,
        1,
        event.target.value,
        search
      )
    ).finally(() => setLoading(false));
  };

  useEffect(() => {
    dispatch(changeTopbarTitle("System Parameters"));
    setLoading(true);
    Promise.all([
      dispatch(SystemParametersActions.getFacilityByUserProfile()),
      dispatch(
        SystemParametersActions.getCamerasByUserProfile(
          profileId,
          "all",
          "all",
          page + 1,
          rowsPerPage,
          search
        )
      ),
      dispatch(SystemParametersActions.getNVRsByUserprofile(profileId)),
    ]).finally(() => setLoading(false));
  }, []);

  const handleEdit = (camera) => {
    setSelectedCamera(camera);
    setDialogOpen(true);
  };

  const handleAdd = () => {
    setSelectedCamera(null);
    setDialogOpen(true);
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await dispatch(SystemParametersActions.deleteFacility(id));
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleSearchKeyPress = (event) => {
    if (event.key === "Enter") {
      setPage(0);
      setLoading(true);
      dispatch(
        SystemParametersActions.getCamerasByUserProfile(
          profileId,
          selectedFacility.value,
          selectedNvr.value,
          1,
          rowsPerPage,
          search
        )
      ).finally(() => setLoading(false));
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleSave = async (camera) => {
    setLoading(true);
    try {
      if (camera.id) {
        await dispatch(
          SystemParametersActions.editCamera(camera.id, camera, rowsPerPage)
        );
      } else {
        await dispatch(SystemParametersActions.addCamera(camera, rowsPerPage));
      }
      setDialogOpen(false); // Close dialog after save
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box padding={2}>
      <div className="row">
        <div className="col-4">
          <TextField
            variant="outlined"
            placeholder="Search by Camera Name"
            value={search}
            size="small"
            fullWidth
            onChange={handleSearchChange}
            onKeyPress={handleSearchKeyPress}
          />
        </div>
        <div className="col-4">
          <AutocompleteCompUncontrollable
            onChange={handleFacilityChange}
            label="Select Facility"
            size="small"
            option={facilityOptions}
            fullWidth
          />
        </div>
        {selectedFacility.value !== "all" && (
          <div className="col-4">
            <AutocompleteComp
              option={NvrList}
              onChange={handleNVRChange}
              value={selectedNvr}
              label="Select NVR"
              size="small"
              showValue={false}
            />
          </div>
        )}
        {canAddCameras && (
          <div className="col-12 d-flex justify-content-end my-2">
            <Button
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={handleAdd}
            >
              Add Camera
            </Button>
          </div>
        )}
      </div>
      <TableContainer component={Paper} sx={{ marginTop: "10px" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>S.N</TableCell>
              <TableCell align="left" sx={{ width: "30%" }}>
                Camera Name
              </TableCell>
              <TableCell>Channel No</TableCell>
              <TableCell>Connected NVR</TableCell>
              {canEditCameras && <TableCell>Action</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : cameraList.data.length > 0 ? (
              cameraList.data.map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell align="left" sx={{ width: "30%" }}>
                    {row.camera_name}
                  </TableCell>
                  <TableCell>{row.channel_number || "N/A"}</TableCell>
                  <TableCell>{row.nvr_name || "Not Available"}</TableCell>
                  {canEditCameras && (
                    <TableCell>
                      <IconButton onClick={() => handleEdit(row)}>
                        <Edit />
                      </IconButton>
                      {/* <IconButton
                        onClick={() => {
                          setSelectedDeleteId(row.id);
                          setDeleteOpen(true);
                        }}
                      >
                        <Delete />
                      </IconButton> */}
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No data present
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {!loading && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[25, 50, 100]}
                  colSpan={5}
                  count={cameraList.count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
      <ConfirmationDialog
        title={"Delete Camera"}
        dialogContent={"Are you sure to delete the camera?"}
        handleConfirm={() => handleDelete(selectDeletedId)}
        handleDisconfirm={() => setDeleteOpen(false)}
        isOpen={isDeleteConfirmModelOpen}
        handleClose={() => setDeleteOpen(false)}
        confirmButtonLabel={"Yes"}
        disconfirmButtonLabel={"No"}
        confirmButtonVariant={"contained"}
        disconfirmButtonVariant={"outlined"}
      />
      <CameraDialog
        open={dialogOpen}
        handleClose={handleDialogClose}
        handleSave={handleSave}
        cameraData={selectedCamera}
        nvrList={NvrList}
      />
    </Box>
  );
};

export default CameraTable;
