import { BACKEND_URL } from "../../routes/config";
class RuleBasedActions {
  constructor() {
    /**
     * Returns the URL for creating new rules.
     * @returns {string} The URL for creating new rules.
     */
    this.createNewRules = () => {
      return `${BACKEND_URL}/rule_based_action/create_rules`;
    };
    /**
     * Returns the URL for fetching rules for alert and event rules.
     * @returns {string} The URL for fetching rules for alert and event rules.
     */
    this.RulesForAlertEvent = (user_profile) => {
      return `${BACKEND_URL}/rule_based_action/get_rules/alerts/userprofile/${user_profile}`;
    };
    /**
     * Returns the URL for Updating and deleting the alert Rule by Id.
     * @param {string} id - The ID of the attendance rule.
     * @returns {string} The URL for fetching rules for attendance by ID.
     */
    this.UpdateAndDeleteAlertRuleById = (id) => {
      return `${BACKEND_URL}/rule_based_action/update_rules/alerts/${id}`;
    };
    /**
     * Returns the URL for fetching rules for potential customers.
     * @returns {string} The URL for fetching rules for potential customers.
     */
    this.RulesForPotential = () => {
      return `${BACKEND_URL}/rule_based_action/potential_cust/`;
    };

    /**
     * Returns the URL for fetching rules for potential customers by ID.
     * @param {string} id - The ID of the potential customer.
     * @returns {string} The URL for fetching rules for potential customers by ID.
     */
    this.RulesForPotentialById = (id) => {
      return `${BACKEND_URL}/rule_based_action/potential_cust/${id}/`;
    };

    /**
     * Returns the URL for creating new rules for attendance.
     * @returns {string} The URL for creating new rules for attendance.
     */
    this.createNewRulesForAttendance = () => {
      return `${BACKEND_URL}/rule_based_action/attendance_rule/`;
    };

    /**
     * Returns the URL for fetching rules for attendance by ID.
     * @param {string} id - The ID of the attendance rule.
     * @returns {string} The URL for fetching rules for attendance by ID.
     */
    this.RulesForAttendanceById = (id) => {
      return `${BACKEND_URL}/rule_based_action/attendance_rule/${id}/`;
    };
  }
}

export default new RuleBasedActions();
