import { React } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import Account from "../../redux/Action/CommonAction";
import getChartData from "../Alert/AlertChartData";
import { getChartDataByDateRange } from "../Alert/AlertChartData";
import "../../assets/css/Login.css";
import "../../assets/css/main.css";
import { changeTopbarTitle } from "../../redux/Action/ChangeTopbarTitle";
import { getProfileIdByClient } from "../../redux/Action/CommonAction";
import AlertTopCards from "./AlertTopCards";
import AlertCharts from "./AlertCharts";
import Filter from "./../../components/common/FilterBar/Filter";
import AlertFilter from "../../components/common/FilterBar/AlertFilter";
import FacilityFilter from "../../components/common/FilterBar/FacilityFilter";
import DeviceFilter from "../../components/common/FilterBar/DeviceFilter";
import ClientFiler from "../../components/common/FilterBar/ClientFilter";
import AlertDashAction from "../../redux/Action/AlertDashAction";
import PrioityFilter from "../../components/common/FilterBar/PriorityFilter";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
const Alert = () => {
  const state = useSelector((state) => state);
  var profile = JSON.parse(localStorage.getItem("profile"));
  var profile_id = JSON.parse(localStorage.getItem("profile_id"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [access_permission] = useState(
    JSON.parse(localStorage.getItem("agx_permission")).Dashboard ?? []
  );
  const [noOfRender, setNoOfRerender] = useState(0);
  useEffect(() => {
    if (profile.alerts !== true) {
      navigate("/unauthorized");
    } else {
      dispatch(changeTopbarTitle("Alert Dashboard"));
      if (profile.account.config["client"] == "True") {
        var obj = JSON.parse(localStorage.getItem("profile"));
        obj.id = localStorage.getItem("profile_id");
        localStorage.setItem("profile", JSON.stringify(obj));
        Account.getClientList(profile_id)(dispatch);
        Account.getStoresByAccount(profile.id)(dispatch);
      } else {
        Account.getStoresByAccount(profile.id)(dispatch);
      }
    }
  }, []);

  useEffect(() => {
    if (noOfRender == 0) return;
    AlertDashAction.updateFacility({ value: "all" });
    AlertDashAction.updateDevice({ value: "all" });
    if (state.AlertDashboardStates.selectedClient.value !== "all") {
      getProfileIdByClient(
        state.AlertDashboardStates.selectedClient.value
      ).then((res) => {
        var profile = JSON.parse(localStorage.getItem("profile"));
        profile.id = res.data[0].account_owner;
        localStorage.setItem("profile", JSON.stringify(profile));
        Account.getStoresByAccount(res.data[0].account_owner)(dispatch);
        if (state.AlertDashboardStates.selectedMode.value === "day-wise") {
          getChartData(
            state.commonData.alerteventDate,
            state.AlertDashboardStates.selectedFacility.value,
            state.AlertDashboardStates.selectedDevice.value,
            state.AlertDashboardStates.selectedAlertType.value,
            state.AlertTableStates.selectedPriority.value,
            state.AlertTableStates.alertOrEvent,
            dispatch
          );
        } else {
          getChartDataByDateRange(
            state.commonData.dateRange.startDate,
            state.commonData.dateRange.endDate,
            state.AlertDashboardStates.selectedFacility.value,
            state.AlertDashboardStates.selectedDevice.value,
            state.AlertDashboardStates.selectedAlertType.value,
            state.AlertTableStates.selectedPriority.value,
            state.AlertTableStates.alertOrEvent,
            dispatch
          );
        }
      });
    } else {
      var obj = JSON.parse(localStorage.getItem("profile"));
      obj.id = localStorage.getItem("profile_id");
      localStorage.setItem("profile", JSON.stringify(obj));
      Account.getStoresByAccount(obj.id)(dispatch);

      if (state.AlertDashboardStates.selectedMode.value === "day-wise") {
        getChartData(
          state.commonData.alerteventDate,
          state.AlertDashboardStates.selectedFacility.value,
          state.AlertDashboardStates.selectedDevice.value,
          state.AlertDashboardStates.selectedAlertType.value,
          state.AlertTableStates.selectedPriority.value,
          state.AlertTableStates.alertOrEvent,
          dispatch
        );
      } else {
        getChartDataByDateRange(
          state.commonData.dateRange.startDate,
          state.commonData.dateRange.endDate,
          state.AlertDashboardStates.selectedFacility.value,
          state.AlertDashboardStates.selectedDevice.value,
          state.AlertDashboardStates.selectedAlertType.value,
          state.AlertTableStates.selectedPriority.value,
          state.AlertTableStates.alertOrEvent,
          dispatch
        );
      }
    }
  }, [state.AlertDashboardStates.selectedClient]);

  useEffect(() => {
    setNoOfRerender(noOfRender + 1);
    if (state.AlertDashboardStates.selectedMode.value === "day-wise") {
      getChartData(
        state.commonData.alerteventDate,
        state.AlertDashboardStates.selectedFacility.value,
        state.AlertDashboardStates.selectedDevice.value,
        state.AlertDashboardStates.selectedAlertType.value,
        state.AlertTableStates.selectedPriority.value,
        state.AlertTableStates.alertOrEvent,
        dispatch
      );
    } else {
      getChartDataByDateRange(
        state.commonData.dateRange.startDate,
        state.commonData.dateRange.endDate,
        state.AlertDashboardStates.selectedFacility.value,
        state.AlertDashboardStates.selectedDevice.value,
        state.AlertDashboardStates.selectedAlertType.value,
        state.AlertTableStates.selectedPriority.value,
        state.AlertTableStates.alertOrEvent,
        dispatch
      );
    }
  }, [
    state.AlertDashboardStates.selectedMode,
    state.commonData.dateRange,
    state.AlertDashboardStates.selectedFacility.value,
    state.AlertDashboardStates.selectedDevice.value,
    state.AlertDashboardStates.selectedAlertType.value,
    state.commonData.alerteventDate,
    state.AlertTableStates.selectedPriority.value,
  ]);

  return (
    <>
      <div>
        <div style={{ marginTop: "20px" }}>
          <AlertTopCards />
          {/*Alert Filters start  */}

          <div className="select-input-div card-custom card-stretch gutter-b">
            <div className="card-body-div">
              <div className="row">
                {access_permission.includes("day_time_range_selection") && (
                  <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-2 mt-2">
                    <Filter type="AlertDashboard" />
                  </div>
                )}
                {profile.account.config["client"] == "True" && (
                  <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-2 mt-2">
                    <ClientFiler type="AlertDashboard" />
                  </div>
                )}
                <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-2 mt-2">
                  <PrioityFilter type="AlertTable" />
                </div>
                <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-2 mt-2">
                  <AlertFilter type="AlertDashboard" />
                </div>

                <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-2 mt-2">
                  <FacilityFilter type="AlertDashboard" />
                </div>
                {state.AlertDashboardStates.selectedFacility.value !==
                  "all" && (
                  <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-2 mt-2">
                    <DeviceFilter type="AlertDashboard" />
                  </div>
                )}
              </div>
            </div>
          </div>

          <AlertCharts selectedDate={state.commonData.alerteventDate} />
        </div>
      </div>
    </>
  );
};

export default Alert;
