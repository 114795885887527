import React, { useState } from "react";
import "../../assets/css/main.css";
import UserTab from "./AccessControl/UserTab/UserTab.js";
import ProfileTabMain from "./AccessControl/ProfileTab/ProfileTabMain.js";
import GroupTabMain from "./AccessControl/GroupTab/GroupTabMain.js";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import AccessControlActions from "../../redux/Action/AccessControlAction.js";
const UserSettings = () => {
  const [selectedTab, setSelectedTab] = useState("Profile");
  const [access_permission] = useState(
    JSON.parse(localStorage.getItem("agx_permission")).Profile ?? []
  );
  const dispatch = useDispatch();
  // Function to handle tab click
  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };
  const userprofileId = JSON.parse(localStorage.getItem("profile")).id;
  useState(() => {
    dispatch(AccessControlActions.getProfileListViaUserprofile(userprofileId));
    dispatch(AccessControlActions.getUserToAccessControlList());
  }, []);

  return (
    <div className="profile-detail-form">
      <div className="d-flex justify-content-between col-12 col-md-8 col-lg-6 col-xl-4 my-3">
        {access_permission.includes("view_profiles") && (
          <Button
            variant={selectedTab === "Profile" ? "contained" : "outlined"}
            sx={{ paddingX: "30px" }}
            onClick={() => handleTabClick("Profile")}
          >
            Profile
          </Button>
        )}
        {access_permission.includes("view_user") && (
          <Button
            variant={selectedTab === "User" ? "contained" : "outlined"}
            sx={{ paddingX: "30px" }}
            onClick={() => handleTabClick("User")}
          >
            User
          </Button>
        )}
        {access_permission.includes("view_groups") && (
          <Button
            variant={selectedTab === "Group" ? "contained" : "outlined"}
            sx={{ paddingX: "30px" }}
            onClick={() => handleTabClick("Group")}
          >
            Group
          </Button>
        )}
      </div>
      {selectedTab === "Group" ? (
        <GroupTabMain />
      ) : selectedTab === "User" ? (
        <UserTab />
      ) : (
        <ProfileTabMain />
      )}
    </div>
  );
};

export default UserSettings;
