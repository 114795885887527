import React, { useState, useMemo, useCallback } from "react";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import "./../remote-monitoring.css";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import EditNvrModal from "../ConfigurableRhms/EditNvrModal";
import PropTypes from "prop-types";
import RemoteMonitoringRowDetails from "./RemoteMonitoringRowDetails";
import { nvrWithApi } from "../../../configs/dataConfig";
function Row({ row, index }) {
  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalId, setModalId] = useState(-1);
  const [access_permission] = useState(
    JSON.parse(localStorage.getItem("agx_permission")).Remote_monitoring ?? []
  );
  const profile = useMemo(
    () => JSON.parse(localStorage.getItem("profile")),
    []
  );

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
    setModalId(-1);
  }, []);

  const handleOpenModal = useCallback((id) => {
    setShowModal(true);
    setModalId(id);
  }, []);
  var formattedBrand = row.brand
    .replace(/[^\w\s]/gi, "")
    .replace(/\s+/g, "")
    .toLowerCase();
  const isEzvizBrand = row.brand === "ezviz";
  const isApiAvailable = nvrWithApi.includes(formattedBrand);
  return (
    <>
      <EditNvrModal
        addNew={false}
        open={showModal}
        id={modalId}
        handleClose={handleCloseModal}
      />
      <TableRow key={`row-ind-${index}`} onClick={() => setOpen(!open)}>
        <TableCell style={{ color: "var(--chatbot-welcome-text-color)" }}>
          {index + 1}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.nvr}
        </TableCell>
        <TableCell>
          <div
            className={
              row.nvr_online === "OFFLINE"
                ? "paraColorOfNvrRed"
                : "nvr_online_color"
            }
          >
            {row.nvr_online}
          </div>
        </TableCell>
        <TableCell>
          <div
            className={
              row.hdd_working === "ABNORMAL"
                ? "paraColorOfNvrRed"
                : "nvr_online_color"
            }
            style={{
              color: !isApiAvailable && "var(--chatbot-welcome-text-color)",
            }}
          >
            {!isApiAvailable ? "N/A" : row.hdd_working}
          </div>
        </TableCell>
        <TableCell>
          <div
            className={
              row.is_recording === "NOT RECORDING"
                ? "paraColorOfNvrRed"
                : "nvr_online_color"
            }
            style={{
              color: !isApiAvailable && "var(--chatbot-welcome-text-color)",
            }}
          >
            {!isApiAvailable ? "N/A" : row.is_recording}
          </div>
        </TableCell>

        <TableCell align="right">
          <div className="d-flex justify-content-center">
            <IconButton
              aria-label="expand row"
              size="small"
              sx={{ padding: 0, margin: 0 }}
              onClick={(e) => {
                e.stopPropagation();
                setOpen(!open);
              }}
            >
              {open ? (
                <KeyboardArrowUpIcon
                  sx={{ color: "var(--black-white-darkmode)!important" }}
                />
              ) : (
                <KeyboardArrowDownIcon
                  sx={{ color: "var(--black-white-darkmode)!important" }}
                />
              )}
            </IconButton>
            {profile.services_selected.Dashboard.includes(
              "configurable_rhms"
            ) &&
              access_permission.includes("edit_nvr_dvr") && (
                <IconButton
                  size="small"
                  style={{
                    marginLeft: "20px",
                    color: "var(--black-white-darkmode)",
                  }}
                  onClick={() => handleOpenModal(row.nvr_id)}
                >
                  <ModeEditIcon
                    sx={{
                      color: "var(--black-white-darkmode)!important",
                      fontSize: "16px",
                    }}
                  />
                </IconButton>
              )}
          </div>
        </TableCell>
      </TableRow>
      {
        <RemoteMonitoringRowDetails
          key={`rowNvr-${row.nvr}`}
          row={row}
          open={open}
        />
      }
    </>
  );
}
Row.propTypes = {
  row: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};
export default Row;
