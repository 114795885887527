import React from "react";
import { MenuItem, Select } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { getTableData } from "../../../pages/Alert/AlertChartData";
import EventTableAction from "../../../redux/Action/EventTableActions";
import { getTableDataEvent } from "../../../pages/Events/EventChartData";
import AlertTableAction from "../../../redux/Action/AlertTableActions";
import AlertDashAction from "../../../redux/Action/AlertDashAction";
import FaceRecognitionActions from "../../../redux/Action/FaceRegnitionAction";
import AnprActions from "../../../redux/Action/AnprActions";
const inputselect = {
  boxShadow: "none",
  borderRadius: "8px",
  ".MuiOutlinedInput-notchedOutline": { border: 0 },
  MenuProps: {
    PaperProps: {
      style: {
        backgroundColor: "var(--top-bar-bg-color)",
        maxHeight: 350,
        width: 50,
        overflowX: "auto",
      },
    },
  },
};

const DeviceFilter = (propTypes) => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const handleDeviceChange = (event) => {
    const value = event.target.value;
    if (propTypes.type === "AlertTable") {
      dispatch(AlertTableAction.updateDevice({ value: value }));
    } else if (propTypes.type === "AlertDashboard") {
      dispatch(AlertDashAction.updateDevice({ value: value }));
    } else if (propTypes.type === "AnprDashboard") {
      dispatch(AnprActions.updateDevice({ value: value }));
    } else {
      dispatch({
        type: "UPDATE_CURRENT_PAGE_FACE_RECOGNITION",
        payload: 1,
      });
      dispatch(FaceRecognitionActions.updateDevice({ value: value }));
    }
  };

  return (
    <div>
      <div className="select-input-lebel">Camera</div>

      <Select
        value={
          propTypes.type === "AlertTable"
            ? state.AlertTableStates.selectedDevice.value
            : propTypes.type === "AlertDashboard"
            ? state.AlertDashboardStates.selectedDevice.value
            : propTypes.type === "FaceRecognitionDashboard"
            ? state.faceRecognition.selectedCamera.value
            : propTypes.type === "AnprDashboard"
            ? state.anpr.selectedCamera.value
            : "all"
        }
        onChange={handleDeviceChange}
        sx={{
          color: "var(--card-text-color)",
          ...inputselect,
        }}
        className="select-input-field"
        MenuProps={inputselect.MenuProps}
      >
        {state.commonData.cameraList.map((item) => {
          return (
            <MenuItem
              key={item.value}
              value={item.value}
              sx={{
                paddingTop: "2px",
                paddingBottom: "2px",
                color: "var(--card-text-color)",
              }}
            >
              {item.text}
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
};

export default DeviceFilter;
