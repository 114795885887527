import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import ErrorIcon from "@mui/icons-material/Error";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  background: "white",
  padding: "16px",
  textAlign: "center",
  borderRadius: "10px",
  border: "2.5px solid rgba(255, 103, 103, 0.82)",
};

const FailureModal = ({ isOpen, onClose, error }) => {
  useEffect(() => {
    let timeoutId;
    if (isOpen) {
      timeoutId = setTimeout(() => {
        onClose();
      }, 5000);
    }
    // Clean up timeout on component unmount
    return () => clearTimeout(timeoutId);
  }, [isOpen, onClose]);

  const errorMessage =
    error === "invalid"
      ? "Sorry, the username or password you entered is not correct. Please try again."
      : error == "payment"
      ? "Oops! Your subscription plan has expired. Please renew it and contact the administrator."
      : "Oops, we're unable to connect to the server. Please try again in a moment...";

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      disableAutoFocus
    >
      <div
        style={modalStyle}
        className="col-10 col-sm-6 col-md-6 col-lg-5 col-xl-4"
      >
        <ErrorIcon color="error" style={{ fontSize: 60 }} />
        <Typography variant="h5" id="modal-title" style={{ marginTop: "16px" }}>
          Login Failed
        </Typography>
        <Typography
          variant="body1"
          id="modal-description"
          style={{ marginTop: "8px" }}
        >
          {errorMessage}
        </Typography>
      </div>
    </Modal>
  );
};

FailureModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  error: PropTypes.oneOf(["invalid", "server", ""]).isRequired, // If only 'invalid' or 'server' errors are possible
};

export default FailureModal;
