import React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import mainCss from "../../assets/css/mainCss";
import "../../assets/css/main.css";
import Pagination from "../../components/common/Pagination";
import { useSelector } from "react-redux";
import { MenuItem, Select } from "@mui/material";
import { changeTopbarTitle } from "../../redux/Action/ChangeTopbarTitle";
import Account from "../../redux/Action/CommonAction";
import { getProfileIdByClient } from "../../redux/Action/CommonAction";
import { getNVRsByStore } from "../../redux/Action/RhmsAction";
import { updateCurrentPage } from "../../redux/Action/RhmsAction";
import RemoteMonitoringTable from "./RhmsTable/RemoteMonitoringTable";
import RemoteMonitoringStatitics from "./RemoteMonitoringStatitics";
import CameraMonitoringStatistics from "./CameraMonitoringStatistic";
import { useNavigate } from "react-router-dom";
import DownloadAndEmailReport from "./DownloadEmailModal";
import cloudMonitoringAction from "../../redux/Action/CloudMonitoringAction";
const inputselect = {
  boxShadow: "none",
  borderRadius: "8px",
  ".MuiOutlinedInput-notchedOutline": { border: 0 },
  MenuProps: {
    PaperProps: {
      style: {
        backgroundColor: "var(--top-bar-bg-color)",
        maxHeight: 350,
        width: 50,
        overflowX: "auto",
      },
    },
  },
};
let PageSize = 50;
const RemoteMonitoringDash = () => {
  // hooks of this component

  const rhmsData = useSelector((state) => state.rhmsData);
  const rhmsDataLoading = useSelector((state) => state.rhmsData.isLoading);
  const clientList = useSelector((state) => state.commonData.clientList);
  const storeList = useSelector((state) => state.commonData.storeList);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedType, setSelectedType] = useState({ value: "all" });
  const [selectedStore, setSelectedStore] = useState({ value: "all" });
  const [selectedNvr, setSelectedNvr] = useState({ value: "all" });
  const [searchValue, setSearchValue] = useState("");
  const [searchValueLength, setSearchValueLength] = useState(0);
  const [selectedClient, setselectedClient] = useState({ value: "all" });
  var profile = JSON.parse(localStorage.getItem("profile"));
  var profile_id = JSON.parse(localStorage.getItem("profile_id"));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (profile.remote_monitoring !== true) {
      navigate("/unauthorized");
    }
    dispatch(changeTopbarTitle("Remote Monitoring"));
    if (profile.account.config["client"] == "True") {
      var obj = JSON.parse(localStorage.getItem("profile"));
      obj.id = localStorage.getItem("profile_id");
      localStorage.setItem("profile", JSON.stringify(obj));
      Account.getClientList(profile_id)(dispatch);
      Account.getStoresByAccount(profile.id)(dispatch);
    } else {
      Account.getStoresByAccount(profile.id)(dispatch);
    }
  }, []);

  useEffect(() => {
    setCurrentPage(rhmsData.currentPage);
  }, [rhmsData.currentPage]);

  useEffect(() => {
    var profile = JSON.parse(localStorage.getItem("profile"));
    profile.id = JSON.parse(localStorage.getItem("profile_id"));
    localStorage.setItem("profile", JSON.stringify(profile));
  }, []);

  // handel type change
  const handleTypeChange = (event) => {
    setSelectedType({ value: event.target.value });
    setCurrentPage(1);
  };

  // handle Store Change
  const handleStoreChange = (event) => {
    setSelectedStore({ value: event.target.value });
    setCurrentPage(1);
    if (event.target.value != "all") {
      setSelectedNvr({ value: "all" });
      dispatch(getNVRsByStore(event.target.value));
    }
  };

  const handleClientChange = (event) => {
    setselectedClient({ value: event.target.value });
    setSelectedStore({ value: "all" });
    setSelectedNvr({ value: "all" });
    setCurrentPage(1);
    const store = { value: "all" };
    const device = { value: "all" };
    if (event.target.value !== "all") {
      getProfileIdByClient(event.target.value).then((res) => {
        var profile = JSON.parse(localStorage.getItem("profile"));
        profile.id = res.data[0].account_owner;
        localStorage.setItem("profile", JSON.stringify(profile));
        Account.getStoresByAccount(res.data[0].account_owner)(dispatch);
        // getStatus(selectedType, store, device, 1);
      });
    } else {
      var obj = JSON.parse(localStorage.getItem("profile"));
      obj.id = localStorage.getItem("profile_id");
      localStorage.setItem("profile", JSON.stringify(obj));
      Account.getStoresByAccount(obj.id)(dispatch);
      // getStatus(selectedType, store, device, 1);
    }
  };

  // handle Nvr change

  const handleNvrChange = (event) => {
    const value = event.target;
    setSelectedNvr({ value: event.target.value });
    setCurrentPage(1);
    if (value !== "all") {
      // getStatus(selectedType, selectedStore, { value: event.target.value }, 1);
    } else {
      // getStatus(selectedType, selectedStore, { value: event.target.value }, 1);
    }
  };

  const handleKeyPress = (event, setSearchValue) => {
    if (event.key === "Enter") {
      setSearchValue(event.target.value);
      // Assuming setSearchValueLength is meant to update state based on the length of the search input
      setSearchValueLength(event.target.value.length);
    }
  };
  // handle pagination handlePagination
  const handlePagination = (page) => {
    setCurrentPage(page);
    dispatch(updateCurrentPage(Number(page)));
  };

  useEffect(() => {
    const profile = JSON.parse(localStorage.getItem("profile"));
    const params = {
      page_num: currentPage,
      user_profile_id: profile.id,
      state:
        selectedType.value == "hddabnormal"
          ? "hdd_abnormal"
          : selectedType.value,
      ...(selectedStore.value !== "all" &&
        selectedNvr.value == "all" && {
          facility_id: selectedStore.value,
        }),
      ...(selectedNvr.value !== "all" && { nvr_id: selectedNvr.value }),
      ...(searchValue !== "" && { search_value: searchValue }),
    };
    dispatch(cloudMonitoringAction.getNvrStatusWithParams(params));
    dispatch(cloudMonitoringAction.getCameraStats(params));
  }, [selectedType, selectedStore, selectedNvr, searchValue, currentPage]);

  return (
    <div style={mainCss.divBelowTopbar}>
      <div className="select-input-div card-custom card-stretch gutter-b">
        <div className="card-body-div">
          <div className="row">
            {profile.account.config["client"] == "True" && (
              <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 mt-2">
                <div className="select-input-lebel">Clients</div>
                <Select
                  value={selectedClient.value}
                  onChange={handleClientChange}
                  sx={{
                    color: "var(--card-text-color)",
                    ...inputselect,
                  }}
                  className="select-input-field"
                  MenuProps={inputselect.MenuProps}
                >
                  {clientList.map((item) => {
                    return (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        sx={{
                          paddingTop: "2px",
                          paddingBottom: "2px",
                          color: "var(--card-text-color)",
                        }}
                      >
                        {item.text}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
            )}
            <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 mt-2">
              <div className="select-input-lebel">Status</div>
              <Select
                value={selectedType.value}
                sx={{
                  color: "var(--card-text-color)",
                  ...inputselect,
                }}
                className="select-input-field"
                MenuProps={inputselect.MenuProps}
                onChange={handleTypeChange}
              >
                <MenuItem
                  value="all"
                  sx={{
                    color: "var(--card-text-color)",
                  }}
                >
                  All
                </MenuItem>
                <MenuItem
                  value="online"
                  sx={{
                    color: "var(--card-text-color)",
                  }}
                >
                  ONLINE
                </MenuItem>
                <MenuItem
                  value="offline"
                  sx={{
                    color: "var(--card-text-color)",
                  }}
                >
                  OFFLINE
                </MenuItem>
                <MenuItem
                  value="hddabnormal"
                  sx={{
                    color: "var(--card-text-color)",
                  }}
                >
                  ABNORMAL
                </MenuItem>
              </Select>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 mt-2">
              <div className="select-input-lebel">Facility</div>
              <Select
                value={selectedStore.value}
                onChange={handleStoreChange}
                sx={{
                  color: "var(--card-text-color)",
                  ...inputselect,
                }}
                className="select-input-field"
                MenuProps={inputselect.MenuProps}
              >
                {storeList.map((item) => {
                  return (
                    <MenuItem
                      key={item.value}
                      value={item.value}
                      sx={{
                        paddingTop: "2px",
                        paddingBottom: "2px",
                        color: "var(--card-text-color)",
                      }}
                    >
                      {item.text}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            {selectedStore.value !== "all" && (
              <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 mt-2">
                <div className="select-input-lebel">NVR</div>
                <Select
                  value={selectedNvr.value}
                  onChange={handleNvrChange}
                  sx={{
                    color: "var(--card-text-color)",
                    ...inputselect,
                  }}
                  className="select-input-field"
                  MenuProps={inputselect.MenuProps}
                >
                  {rhmsData.nvrList.map((item) => {
                    return (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        sx={{
                          paddingTop: "2px",
                          paddingBottom: "2px",
                          color: "var(--card-text-color)",
                        }}
                      >
                        {item.text}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
            )}

            <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 mt-2">
              <div className="select-input-lebel">Search</div>
              <input
                type="search"
                placeholder="Search..."
                className="search-input-field"
                onKeyDown={(e) => handleKeyPress(e, setSearchValue)}
                style={{
                  paddingTop: "2px",
                  paddingBottom: "2px",
                  color: "var(--card-text-color)",
                }}
              />
            </div>
            <DownloadAndEmailReport
              selectedType={selectedType}
              selectedStore={selectedStore}
              selectedNvr={selectedNvr}
              searchValue={searchValue}
            />
          </div>
        </div>
      </div>
      <RemoteMonitoringStatitics loading={rhmsDataLoading} />
      <CameraMonitoringStatistics />
      <RemoteMonitoringTable loading={rhmsDataLoading} />
      {!rhmsDataLoading && (
        <div className="pagination-div">
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={rhmsData.totalPages}
            pageSize={PageSize}
            onPageChange={(page) => handlePagination(page)}
            usedIn="rhms"
          />
        </div>
      )}
    </div>
  );
};
export default RemoteMonitoringDash;
