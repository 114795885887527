import EventAction from "../../redux/Action/EventChartAction";
import moment from "moment";
import EventTableAction from "../../redux/Action/EventTableActions";
import EventResolvedUnresolved from "../../redux/Action/EventResolvedUnresolved";
import AlertTableDataAction from "../../redux/Action/AlertTableDataAction";
const getChartData = (dateValue, store, device, Event_type, dispatch) => {
  const date = moment(dateValue);
  const formattedDate = date.format("YYYY-MM-DD");
  const profile = JSON.parse(localStorage.getItem("profile"));
  if (store == "all") {
    const obj = {
      id: profile.id,
      Event_type: Event_type,
      date: formattedDate,
    };
    EventAction.getTotalEventByHourlyByAccount(obj)(dispatch);
    EventResolvedUnresolved.getResolvedEventByHourlyByAccount(obj)(dispatch);
    EventResolvedUnresolved.getUnresolvedEventByHourlyByAccount(obj)(dispatch);
    EventAction.getWeeklyEventByHourlyByAccount(obj)(dispatch);
    EventAction.getTopSevenEvents(obj)(dispatch);
    EventAction.getTotalEventTypeByAccount(obj)(dispatch);
  } else if (device == "all") {
    const obj = {
      id: store,
      Event_type: Event_type,
      date: formattedDate,
    };
    EventAction.getTotalEventByHourlyByStore(obj)(dispatch);
    EventResolvedUnresolved.getResolvedEventByHourlyByStore(obj)(dispatch);
    EventResolvedUnresolved.getUnresolvedEventByHourlyByStore(obj)(dispatch);
    EventAction.getWeeklyEventByHourlyByStore(obj)(dispatch);
    EventTableAction.updateOpeningTime(obj)(dispatch);
    EventTableAction.updateClosingTime(obj)(dispatch);
  } else {
    const obj = {
      id: device,
      Event_type: Event_type,
      date: formattedDate,
    };
    EventAction.getTotalEventByHourlyByCamera(obj)(dispatch);
    EventResolvedUnresolved.getResolvedEventByHourlyByCamera(obj)(dispatch);
    EventResolvedUnresolved.getUnresolvedEventByHourlyByCamera(obj)(dispatch);
    EventAction.getWeeklyEventByHourlyByCamera(obj)(dispatch);
    EventAction.getTotalEventTypeByCamera(obj)(dispatch);
  }
};

export default getChartData;

export const getTableDataEvent = (
  dateValue,
  mode,
  event_type,
  store,
  device,
  time,
  dtRange,
  page,
  tabValue,
  searchValue,
  pageSize,
  selectedUseCase,
  dispatch
) => {
  time = time.map((item) => item.toString());
  const [startTime, endTime] = time.map((time) => {
    const timeStr = time.toString().includes(".")
      ? time.toString()
      : `${time.toString()}.0`;
    const [hours, minutes] = timeStr.split(".");
    return `${hours.padStart(2, "0")}:${minutes.padStart(2, "0")}:00`;
  });

  const date = moment(dateValue);
  const formattedDate = date.format("YYYY-MM-DD");
  const profile = JSON.parse(localStorage.getItem("profile"));
  if (searchValue.length > 3) {
    getTableDataSearch(
      dateValue,
      mode,
      event_type,
      store,
      device,
      time,
      page,
      searchValue,
      tabValue,
      pageSize,
      dtRange,
      dispatch
    );
  } else {
    if (mode === "day-wise") {
      const opMode =
        JSON.parse(localStorage.getItem("profile")).account.config[
          "Operation"
        ] == "True";
      if (selectedUseCase.value != "all" && opMode) {
        console.log(" filter", opMode, selectedUseCase);
        const obj = {
          selectedUseCase: selectedUseCase.value,
          date: formattedDate,
          start_time: startTime,
          end_time: endTime,
          type: "events",
          page_no: page,
          page_size: pageSize,
        };
        EventTableAction.getAlertViaUSecase(obj)(dispatch);
      } else {
        if (store == "all") {
          const obj = {
            id: profile.id,
            event_type: event_type,
            date: formattedDate,
            start_time: startTime,
            end_time: endTime,
            page_no: page,
            if_false: tabValue[0],
            is_resolve: tabValue[1],
            page_size: pageSize,
          };
          EventTableAction.getTableEventDataByAccount(obj)(dispatch);
        } else if (device == "all") {
          const obj = {
            id: store,
            event_type: event_type,
            date: formattedDate,
            start_time: startTime,
            end_time: endTime,
            page_no: page,
            if_false: tabValue[0],
            is_resolve: tabValue[1],
            page_size: pageSize,
          };
          EventTableAction.getTableEventDataByStore(obj)(dispatch);
        } else {
          const obj = {
            id: device,
            event_type: event_type,
            date: formattedDate,
            start_time: startTime,
            end_time: endTime,
            page_no: page,
            if_false: tabValue[0],
            is_resolve: tabValue[1],
            page_size: pageSize,
          };
          EventTableAction.getTableEventDataByCamera(obj)(dispatch);
        }
      }
    } else {
      const datestart = moment(dtRange.startDate);
      const startDate = datestart.format("YYYY-MM-DD");
      const dateEnd = moment(dtRange.endDate);
      const endate = dateEnd.format("YYYY-MM-DD");
      if (store == "all") {
        const obj = {
          id: profile.id,
          event_type: event_type,
          date: formattedDate,
          start_date: startDate,
          end_date: endate,
          page_no: page,
          if_false: tabValue[0],
          is_resolve: tabValue[1],
          page_size: pageSize,
        };
        EventTableAction.getTableEventDataByDateRangeByAccount(obj)(dispatch);
      } else if (device == "all") {
        const obj = {
          id: store,
          event_type: event_type,
          date: formattedDate,
          start_date: startDate,
          end_date: endate,
          page_no: page,
          if_false: tabValue[0],
          is_resolve: tabValue[1],
          page_size: pageSize,
        };
        EventTableAction.getTableEventDataByDateRangeByStore(obj)(dispatch);
      } else {
        const obj = {
          id: device,
          event_type: event_type,
          date: formattedDate,
          start_date: startDate,
          end_date: endate,
          page_no: page,
          if_false: tabValue[0],
          is_resolve: tabValue[1],
          page_size: pageSize,
        };
        EventTableAction.getTableDataEventByDateRangeByCamera(obj)(dispatch);
      }
    }
  }
};

export const getTableDataSearch = (
  dateValue,
  mode,
  event_type,
  store,
  device,
  time,
  page,
  searchItem,
  tabValue,
  pageSize,
  dtRange,
  dispatch
) => {
  if (mode === "day-wise") {
    time = time.map((item) => item.toString());
    const [startTime, endTime] = time.map((time) => {
      const timeStr = time.toString().includes(".")
        ? time.toString()
        : `${time.toString()}.0`;
      const [hours, minutes] = timeStr.split(".");
      return `${hours.padStart(2, "0")}:${minutes.padStart(2, "0")}:00`;
    });
    const date = moment(dateValue);
    const formattedDate = date.format("YYYY-MM-DD");
    const profile = JSON.parse(localStorage.getItem("profile"));
    if (store == "all") {
      const obj = {
        id: profile.id,
        event_type: event_type,
        search_item: searchItem,
        date: formattedDate,
        start_time: startTime,
        end_time: endTime,
        page_no: page,
        if_false: tabValue[0],
        is_resolve: tabValue[1],
        page_size: pageSize,
      };
      EventAction.getEventsSearchByAccount(obj)(dispatch);
    } else if (device == "all") {
      const obj = {
        id: store,
        event_type: event_type,
        search_item: searchItem,
        date: formattedDate,
        start_time: startTime,
        end_time: endTime,
        page_no: page,
        if_false: tabValue[0],
        is_resolve: tabValue[1],
        page_size: pageSize,
      };
      EventAction.getEventsSearchByStore(obj)(dispatch);
    } else {
      const obj = {
        id: device,
        event_type: event_type,
        search_item: searchItem,
        date: formattedDate,
        start_time: startTime,
        end_time: endTime,
        page_no: page,
        if_false: tabValue[0],
        is_resolve: tabValue[1],
        page_size: pageSize,
      };
      EventAction.getEventsSearchByCamera(obj)(dispatch);
    }
  } else {
    const datestart = moment(dtRange.startDate);
    const startDate = datestart.format("YYYY-MM-DD");
    const dateEnd = moment(dtRange.endDate);
    const endDate = dateEnd.format("YYYY-MM-DD");
    const profile = JSON.parse(localStorage.getItem("profile"));
    if (store == "all") {
      const obj = {
        id: profile.id,
        event_type: event_type,
        search_item: searchItem,
        start_date: startDate,
        end_date: endDate,
        page_no: page,
        if_false: tabValue[0],
        is_resolve: tabValue[1],
        page_size: pageSize,
      };
      EventAction.getEventsSearchByAccountDateRange(obj)(dispatch);
    } else if (device == "all") {
      const obj = {
        id: store,
        event_type: event_type,
        search_item: searchItem,
        start_date: startDate,
        end_date: endDate,
        page_no: page,
        if_false: tabValue[0],
        is_resolve: tabValue[1],
        page_size: pageSize,
      };
      EventAction.getEventsSearchByStoreDateRange(obj)(dispatch);
    } else {
      const obj = {
        id: device,
        event_type: event_type,
        search_item: searchItem,
        start_date: startDate,
        end_date: endDate,
        page_no: page,
        if_false: tabValue[0],
        is_resolve: tabValue[1],
        page_size: pageSize,
      };
      EventAction.getEventsSearchByCameraDateRange(obj)(dispatch);
    }
  }
};
