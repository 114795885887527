import axios from "axios";
import ApiSpec from "../features/EndPoints";
import moment from "moment";
import { nvrWithApi } from "../../configs/dataConfig";
import {
  notifiToasterSuccess,
  notifiToasterWarn,
} from "../../components/common/UseToast";
import OnboardingEndpoints from "../features/OnboardingEndpoints";
import AccessControlActions from "./AccessControlAction";
// get Nvr by store

export const getNVRsByStore = (data) => {
  return async (dispatch) => {
    var token = localStorage.getItem("token");
    let headers = {
      Authorization: `Token ${token}`,
    };
    await axios
      .get(ApiSpec.getNvrByStore(data), { headers })
      .then((response) => {
        let nvr = [{ text: "All", value: "all" }];
        response.data.forEach((element) => {
          nvr.push({ text: element.name, value: element.id });
        });

        dispatch({ type: "NVR_BY_STORE", payload: nvr });
      });
  };
};
export const getHeatmapDataByNvrNetworkUptime = (data) => {
  return async (dispatch) => {
    var token = localStorage.getItem("token");
    let headers = {
      Authorization: `Token ${token}`,
    };
    axios
      .get(ApiSpec.getHeatmapDataUsingNvrId(data), { headers })
      .then((response) => {
        dispatch({ type: "HEATMAP_DATA", payload: response.data.data });
        dispatch({
          type: "UPDATE_NVR_UPTIME",
          payload: response.data.nvr_uptime,
        });
      })
      .catch(() => {
        dispatch({ type: "HEATMAP_DATA", payload: [] });
      });
  };
};
export const getHeatmapDataOfNVRUptime = (data) => {
  return async (dispatch) => {
    var token = localStorage.getItem("token");
    let headers = {
      Authorization: `Token ${token}`,
    };
    await axios
      .get(ApiSpec.getUptimeHeatmapDataUsingNvrId(data), { headers })
      .then((response) => {
        dispatch({ type: "HEATMAP_DATA", payload: response.data });
      });
  };
};
export const removeHeatMapData = (data) => {
  return async (dispatch) => {
    dispatch({ type: "HEATMAP_DATA", payload: [] });
  };
};
export const resetRhmsData = () => {
  return async (dispatch) => {
    dispatch({ type: "RESET_RHMS_REDUCER" });
  };
};
export const updateCurrentPage = (data) => {
  return async (dispatch) => {
    dispatch({ type: "UPDATE_CURRENT_PAGE", payload: data });
  };
};
// get DeviceStatus Data by Account

let cancelTokenforNVRStatus;
let activeRequestCount = 0;

export const getNVRStatusByAccount = (ownerId, page) => async (dispatch) => {
  activeRequestCount++;
  dispatch({ type: "LOADING", payload: true });
  dispatch({ type: "RHMS_STATUS_DATA", payload: { data: [], count: 0 } });

  if (cancelTokenforNVRStatus) {
    cancelTokenforNVRStatus.cancel("Operation canceled due to new request.");
  }

  cancelTokenforNVRStatus = axios.CancelToken.source();

  try {
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Token ${token}` };
    const apiUrl = ApiSpec.nvrStatusByAccount(ownerId, page);
    const apiUrlByType = ApiSpec.nvrStatusByAccountByType(ownerId, 1, "online");

    const [statusResponse, onlineResponse] = await Promise.all([
      axios.get(apiUrl, {
        headers,
        cancelToken: cancelTokenforNVRStatus.token,
      }),
      axios.get(apiUrlByType, {
        headers,
        cancelToken: cancelTokenforNVRStatus.token,
      }),
    ]);

    statusResponse.data.data.forEach(restructureResponse);

    dispatch({ type: "RHMS_STATUS_DATA", payload: statusResponse.data });
    dispatch({ type: "TOTAL_NVR_COUNT", payload: statusResponse.data });
    dispatch({ type: "TOTAL_NVR_ONLINE", payload: onlineResponse.data.count });
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled:", error.message);
      return; // Early return to prevent decrementing the counter for a canceled request
    } else {
      console.error("Error fetching NVR status:", error);
    }
  } finally {
    activeRequestCount--;
    if (activeRequestCount === 0) {
      dispatch({ type: "LOADING", payload: false });
    }
  }
};

// get NVRStatus Data by Store

export const getNVRStatusByStore = (store, page) => async (dispatch) => {
  activeRequestCount++;
  dispatch({ type: "LOADING", payload: true });
  dispatch({ type: "RHMS_STATUS_DATA", payload: { data: [], count: 0 } });
  if (cancelTokenforNVRStatus) {
    cancelTokenforNVRStatus.cancel("Operation canceled due to new request.");
  }

  cancelTokenforNVRStatus = axios.CancelToken.source();
  try {
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Token ${token}` };
    const apiUrl = ApiSpec.nvrStatusByStore(store, page);
    const apiUrlByType = ApiSpec.nvrStatusByStoreByType(store, 1, "online");

    const [statusResponse, onlineResponse] = await Promise.all([
      axios.get(apiUrl, { headers }),
      axios.get(apiUrlByType, { headers }),
    ]);

    statusResponse.data.data.forEach(restructureResponse);

    dispatch({ type: "TOTAL_NVR_COUNT", payload: statusResponse.data });
    dispatch({ type: "RHMS_STATUS_DATA", payload: statusResponse.data });
    dispatch({ type: "TOTAL_NVR_ONLINE", payload: onlineResponse.data.count });
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled:", error.message);
      return; // Early return to prevent decrementing the counter for a canceled request
    } else {
      console.error("Error fetching NVR status:", error);
    }
  } finally {
    activeRequestCount--;
    if (activeRequestCount === 0) {
      dispatch({ type: "LOADING", payload: false });
    }
  }
};
export const getNVRStatusByStoreByType = (store, page) => async (dispatch) => {
  activeRequestCount++;
  dispatch({ type: "LOADING", payload: true });
  dispatch({ type: "RHMS_STATUS_DATA", payload: { data: [], count: 0 } });
  if (cancelTokenforNVRStatus) {
    cancelTokenforNVRStatus.cancel("Operation canceled due to new request.");
  }

  cancelTokenforNVRStatus = axios.CancelToken.source();
  try {
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Token ${token}` };
    const apiUrl = ApiSpec.nvrStatusByStore(store, page);
    const apiUrlByType = ApiSpec.nvrStatusByStoreByType(store, 1, "online");

    const [statusResponse, onlineResponse] = await Promise.all([
      axios.get(apiUrl, { headers }),
      axios.get(apiUrlByType, { headers }),
    ]);

    statusResponse.data.data.forEach(restructureResponse);

    dispatch({ type: "TOTAL_NVR_COUNT", payload: statusResponse.data });
    // dispatch({ type: "RHMS_STATUS_DATA", payload: statusResponse.data });
    dispatch({ type: "TOTAL_NVR_ONLINE", payload: onlineResponse.data.count });
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled:", error.message);
      return; // Early return to prevent decrementing the counter for a canceled request
    } else {
      console.error("Error fetching NVR status:", error);
    }
  } finally {
    activeRequestCount--;
    if (activeRequestCount === 0) {
      dispatch({ type: "LOADING", payload: false });
    }
  }
};
// get DeviceStatus Data by Camera

export const getNVRStatusByNVR = (data) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING", payload: true });
    dispatch({ type: "RHMS_STATUS_DATA", payload: { data: [], count: 0 } });
    var token = localStorage.getItem("token");
    let headers = {
      Authorization: `Token ${token}`,
    };
    axios.get(ApiSpec.nvrStatusByNVR(data), { headers }).then((response) => {
      response.data.data.map((el) => {
        restructureResponse(el);
      });

      if (response.data.data[0].nvr_online == "OFFLINE") {
        dispatch({ type: "TOTAL_NVR_COUNT", payload: { count: 1 } });
        dispatch({
          type: "TOTAL_NVR_ONLINE",
          payload: 0,
        });
      } else {
        dispatch({ type: "TOTAL_NVR_COUNT", payload: { count: 1 } });
        dispatch({
          type: "TOTAL_NVR_ONLINE",
          payload: 1,
        });
      }
      dispatch({ type: "RHMS_STATUS_DATA", payload: response.data });

      dispatch({ type: "LOADING", payload: false });
    });
  };
};

// api for search nvr for account and store

export const getNVRStatusSearchByAccount = (ownerId, page, searchItem) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "LOADING", payload: true });
      dispatch({ type: "RHMS_STATUS_DATA", payload: { data: [], count: 0 } });

      const token = localStorage.getItem("token");
      const headers = { Authorization: `Token ${token}` };

      const response = await axios.get(
        ApiSpec.nvrStatusSearchByAccount(ownerId, page, searchItem),
        { headers }
      );

      const processedData = response.data.data.map(restructureResponse);
      const onlineCount = processedData.filter(
        (item) => item.nvr_online === "ONLINE"
      ).length;
      console.log(response.data, onlineCount);
      dispatch({ type: "RHMS_STATUS_DATA", payload: response.data });
      dispatch({ type: "TOTAL_NVR_COUNT", payload: response.data });
      dispatch({ type: "TOTAL_NVR_ONLINE", payload: onlineCount });
    } catch (error) {
      console.error("Error fetching NVR status:", error);
    } finally {
      dispatch({ type: "LOADING", payload: false });
    }
  };
};

export const getNVRStatusSearchByStore = (store, page, searchItem) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING", payload: true });
    dispatch({ type: "RHMS_STATUS_DATA", payload: { data: [], count: 0 } });
    var token = localStorage.getItem("token");
    let headers = {
      Authorization: `Token ${token}`,
    };
    axios
      .get(ApiSpec.nvrStatusSearchByStore(store, page, searchItem), { headers })
      .then((response) => {
        response.data.data.map((el) => {
          restructureResponse(el);
        });
        const onlineCount = response.data.data.filter(
          (item) => item.nvr_online === "ONLINE"
        ).length;
        dispatch({ type: "RHMS_STATUS_DATA", payload: response.data });
        dispatch({ type: "TOTAL_NVR_COUNT", payload: response.data });
        dispatch({ type: "TOTAL_NVR_ONLINE", payload: onlineCount });
        dispatch({ type: "LOADING", payload: false });
      });
  };
};

// api ONLINE Api

export const getNVROnlineStatusByAccount = (ownerId, page, type) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING", payload: true });
    dispatch({ type: "RHMS_STATUS_DATA", payload: { data: [], count: 0 } });
    var token = localStorage.getItem("token");
    let headers = {
      Authorization: `Token ${token}`,
    };
    try {
      const response = await axios.get(
        ApiSpec.nvrStatusByAccountByType(ownerId, page, type),
        {
          headers,
        }
      );
      response.data.data.map((el) => {
        restructureResponse(el);
      });
      dispatch({ type: "LOADING", payload: false });
      dispatch({ type: "RHMS_STATUS_DATA", payload: response.data });
    } catch (error) {
      console.error("Error fetching NVR online status:", error);
      dispatch({ type: "RHMS_STATUS_DATA", payload: { data: [], count: 0 } });
      // In case of error, set loading to false and keep the data array empty
      dispatch({ type: "LOADING", payload: false });
    }
  };
};

export const getNVROnlineStatusByStore = (store, page, type) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING", payload: true });
    dispatch({ type: "RHMS_STATUS_DATA", payload: { data: [], count: 0 } });
    var token = localStorage.getItem("token");
    let headers = {
      Authorization: `Token ${token}`,
    };
    try {
      const response = await axios.get(
        ApiSpec.nvrStatusByStoreByType(store, page, type),
        {
          headers,
        }
      );
      response.data.data.forEach((el) => {
        restructureResponse(el);
      });
      console.log("data dispatched", response.data);
      dispatch({
        type: "RHMS_STATUS_DATA",
        payload: { data: response.data.data, count: response.data.count },
      });
    } catch (error) {
      dispatch({ type: "RHMS_STATUS_DATA", payload: { data: [], count: 0 } });
      console.error("Error fetching NVR online status by store:", error);
      // No need to explicitly dispatch an empty data action since it's already done at the start.
    } finally {
      // Always dispatch LOADING false in finally block to ensure it runs regardless of try or catch execution
      dispatch({ type: "LOADING", payload: false });
    }
  };
};

export const totalNvrDataForLocalDownload = () => {
  return new Promise((resolve, reject) => {
    var token = localStorage.getItem("token");
    const profile = JSON.parse(localStorage.getItem("profile"));
    let headers = {
      Authorization: `Token ${token}`,
    };
    axios
      .get(ApiSpec.totalNvrDataForLocalDownload(profile.id), { headers })
      .then((response) => {
        notifiToasterSuccess("Success");
        resolve(response);
      })
      .catch((error) => {
        notifiToasterWarn("failed");
        reject(error);
      });
  });
};

//  ONLINE OFFLINE FILTER
const restructureResponse = (el) => {
  var formattedBrand = el.brand
    .replace(/[^\w\s]/gi, "")
    .replace(/\s+/g, "")
    .toLowerCase();

  const currentTime = moment()
    .subtract(15, "minutes")
    .format("YYYY-MM-DD HH:mm:ss");
  const last_online = moment(new Date(el.last_online))
    .utc()
    .format("YYYY-MM-DD HH:mm:ss");

  // Determine if the device is offline
  const isOffline = currentTime > last_online || el.last_online === "NIL";

  // Update last_online value
  el.last_online =
    el.last_online !== "NIL"
      ? moment(new Date(el.last_online)).utc().format("DD-MM-YYYY HH:mm:ss")
      : el.last_online;

  // Update HDD working status
  el.hdd_working = !nvrWithApi.includes(formattedBrand)
    ? "N/A"
    : el.hdd_working && !isOffline
    ? "NORMAL"
    : "ABNORMAL";

  // Update recording status
  el.is_recording = !nvrWithApi.includes(formattedBrand)
    ? "N/A"
    : el.is_recording && !isOffline
    ? "RECORDING"
    : "NOT RECORDING";

  // Update NVR online status
  // if (el.brand !== "ezviz") {
  //   el.nvr_online = !isOffline ? "ONLINE" : "OFFLINE";
  // } else {
  //   el.nvr_online = el.nvr_online ? "ONLINE" : "OFFLINE";
  // }
  el.nvr_online = !isOffline ? "ONLINE" : "OFFLINE";
  return el;
};
export const updateNVRById = (finalData, id) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.patch(ApiSpec.getNvrById(id), finalData, {
        headers: { Authorization: `Token ${token}` },
      });

      if (response.status === 200) {
        notifiToasterSuccess("NVR Details Updated");
        await editTheCameraOnEditingNVR(id); // Ensure that the function is called after the NVR update is successful
      } else {
        notifiToasterWarn("Unable to update NVR");
      }
    } catch (error) {
      // Handle any errors that occurred during the PATCH request
      console.error("PATCH request error:", error);
      notifiToasterWarn("Unable to update NVRs");
    }
  };
};

export const createNVR = (finalData) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        ApiSpec.createNVRWithLimit(),
        finalData,
        { headers: { Authorization: `Token ${token}` } }
      );

      if (response.status === 201) {
        notifiToasterSuccess("NVR Added");

        const cameras = await createAutoCamera(response.data.nvr.id);
        console.log(cameras);
        const profile_id = JSON.parse(localStorage.getItem("profile")).id;

        const group_data = {
          user_profile_id: profile_id,
          nvrs: [response.data.nvr.id],
          cameras: cameras,
        };

        dispatch(AccessControlActions.updateGroupData(group_data));
      } else {
        notifiToasterWarn(response.data.facility);
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        notifiToasterWarn(error.response.data.message);
      } else {
        console.error("POST request error:", error);
        notifiToasterWarn("Unable to add NVR");
      }
    }
  };
};

export const checkProblemInNvr = async (nvr_id) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(ApiSpec.runDiagonisticEndpoint(nvr_id), {
      headers: { Authorization: `Token ${token}` },
    });

    if (response.data.extras) {
      return response.data.extras;
    } else {
      return "Unable to find the problem.";
    }
  } catch (error) {
    console.error(error);
    return "Unable to run diagnostic at this moment.";
  }
};
export const getCameraStats = (user_profile_id, facility_id, nvr_id) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    try {
      dispatch({ type: "CAMERA_STATS_LOADING", payload: true });
      const response = await axios.get(
        ApiSpec.getCameraStatsByUserProfile(
          user_profile_id,
          facility_id,
          nvr_id
        ),
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
      dispatch({
        type: "UPDATE_CAMERA_COUNT_DATA",
        payload: {
          total: response.data.total_cameras,
          online: response.data.total_online_cameras,
          offline: response.data.total_offline_cameras,
        },
      });
    } catch (error) {
      console.error(error);
      return "Unable to run diagnostic at this moment.";
    } finally {
      dispatch({ type: "CAMERA_STATS_LOADING", payload: false });
    }
  };
};
export const addNewFacility = (data, profile_id) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    try {
      const response = await axios.post(
        OnboardingEndpoints.createFacilityIndependently(),
        data,
        config
      );
      if (response.status === 201) {
        notifiToasterSuccess("Facility Added Successfully");

        const facility_id = response.data.id;
        const data2 = { userprofile: profile_id, Facility: [facility_id] };

        await axios.post(
          OnboardingEndpoints.createFacilityProfileMapping(),
          data2,
          config
        );
        let group_data = {
          user_profile_id: profile_id,
          facilities: [response.data.id],
        };
        dispatch(AccessControlActions.updateGroupData(group_data));
      } else {
        notifiToasterWarn("Failed To add Facility");
      }
    } catch (err) {
      console.error(err);
      notifiToasterWarn("Failed To add Facility");
    }
  };
};

export const createAutoCamera = async (nvr_id) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      ApiSpec.createCameraFromNvrAutomated(nvr_id),
      {},
      { headers: { Authorization: `Token ${token}` } }
    );

    if (response.status === 201) {
      notifiToasterSuccess("Camera Created Automatically");
      return response.data.created_cameras.map((item) => item.id);
    } else {
      notifiToasterWarn(response.data.facility);
      return [];
    }
  } catch (error) {
    notifiToasterWarn("Unable to Create Cameras. Please do it manually.");
    return [];
  }
};
export const editTheCameraOnEditingNVR = async (nvr_id) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.put(
      ApiSpec.editCameraFromNvrAutomated(nvr_id),
      {},
      { headers: { Authorization: `Token ${token}` } }
    );

    if (response.status === 200) {
      notifiToasterSuccess("Camera Details Updated");
    } else {
      return [];
    }
  } catch (error) {
    notifiToasterWarn("Unable to Modify Cameras. Please do it manually.");
    return [];
  }
};
