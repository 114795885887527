import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Autocomplete,
  IconButton,
  Box,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import PropTypes from "prop-types";

const initialCameraState = {
  camera_name: "",
  channel_number: "",
  connected_to: null,
  main_rtsp_url: "",
  sub_rtsp_url: "",
};

const CameraDialog = ({
  open,
  handleClose,
  handleSave,
  cameraData,
  nvrList,
}) => {
  const [camera, setCamera] = useState(initialCameraState);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (cameraData) {
      setCamera(cameraData);
    } else {
      setCamera(initialCameraState);
    }
  }, [cameraData]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCamera({ ...camera, [name]: value });

    // Clear error when user starts typing
    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
  };

  const handleAutocompleteChange = (event, newValue) => {
    setCamera({ ...camera, connected_to: newValue ? newValue.value : null });

    if (errors.connected_to) {
      setErrors({ ...errors, connected_to: null });
    }
  };

  const validate = () => {
    let tempErrors = {};
    if (!camera.camera_name) {
      tempErrors.camera_name = "Camera name is required";
    }
    if (!camera.channel_number) {
      tempErrors.channel_number = "Channel number is required";
    }
    if (!camera.connected_to) {
      tempErrors.connected_to = "Connected NVR is required";
    }
    setErrors(tempErrors);

    // Check if there are no errors
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validate()) {
      handleSave(camera);
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "sm",
        },
      }}
    >
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          {cameraData ? "Edit Camera" : "Add Camera"}
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box mb={2} mt={2}>
          <TextField
            margin="dense"
            label="Camera Name"
            name="camera_name"
            value={camera.camera_name}
            onChange={handleChange}
            fullWidth
            error={Boolean(errors.camera_name)}
            helperText={errors.camera_name}
          />
        </Box>
        <Box mb={2}>
          <TextField
            margin="dense"
            label="Channel Number"
            name="channel_number"
            type="number"
            value={camera.channel_number}
            onChange={handleChange}
            fullWidth
            error={Boolean(errors.channel_number)}
            helperText={errors.channel_number}
          />
        </Box>
        <Box mb={2}>
          <Autocomplete
            options={nvrList.slice(1)}
            getOptionLabel={(option) => option.label}
            onChange={handleAutocompleteChange}
            value={
              nvrList.find((item) => item.value === camera.connected_to) || null
            }
            renderInput={(params) => (
              <TextField
                {...params}
                margin="dense"
                label="Connected NVR"
                fullWidth
                error={Boolean(errors.connected_to)}
                helperText={errors.connected_to}
              />
            )}
          />
        </Box>
        <Box mb={2}>
          <TextField
            margin="dense"
            label="Main RTSP URL"
            name="main_rtsp_url"
            value={camera.main_rtsp_url}
            onChange={handleChange}
            fullWidth
          />
        </Box>
        <Box mb={2}>
          <TextField
            margin="dense"
            label="Sub RTSP URL"
            name="sub_rtsp_url"
            value={camera.sub_rtsp_url}
            onChange={handleChange}
            fullWidth
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CameraDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  cameraData: PropTypes.any,
  nvrList: PropTypes.array.isRequired,
  handleSave: PropTypes.func.isRequired,
};

export default CameraDialog;
