import React from "react";
import HomePage from "../pages/home/HomePage";
import Alert from "../pages/Alert/Alert";
import Table from "../pages/Alert/Table";
import Dashboard from "../pages/Alert/Dashboard";
import EqualizerRoundedIcon from "@mui/icons-material/EqualizerRounded";
import DashboardIcon from "@mui/icons-material/Dashboard";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import RemoteMonitoringDash from "../pages/Remote Monitoring/RemoteMonitoringDash";
import OnlinePredictionOutlinedIcon from "@mui/icons-material/OnlinePredictionOutlined";
import MessageIcon from "@mui/icons-material/Message";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import SettingMainPage from "../pages/Alert/Settings/SettingMainPage";
import SystemParameters from "../pages/SystemParameters/SystemParameters";
import FacilityTable from "../pages/SystemParameters/FacilityManagement/FacilityTable";
import CameraTable from "../pages/SystemParameters/CameraManagement/CameraTable";
import StoreIcon from "@mui/icons-material/Store";
import { MdiCctv } from "../assets/icons/MdiCctv";
import { TablerListDetails } from "../assets/icons/ManageDetails";

const appRoutes = [
  {
    index: true,
    element: <HomePage />,
    state: "home",
  },

  {
    path: "/alerts",
    element: <Alert />,
    state: "alerts",
    sidebarProps: {
      displayText: "Alerts",
      icon: <OnlinePredictionOutlinedIcon />,
    },
    child: [
      {
        index: true,
        path: "/alerts",
        element: <Dashboard />,
        state: "alerts",
        sidebarProps: {
          displayText: "Dashboard",
          icon: <EqualizerRoundedIcon />,
        },
      },
      {
        path: "/alerts/table",
        element: <Table />,
        state: "alerts",
        sidebarProps: {
          displayText: "Table",
          icon: <DashboardIcon />,
        },
      },
    ],
  },

  {
    path: "/remote_monitoring",
    element: <RemoteMonitoringDash />,
    state: "remote_monitoring",
    sidebarProps: {
      displayText: "Remote Monitoring",
      icon: <RemoveRedEyeIcon />,
    },
  },

  {
    path: "/alerts/setting",
    element: <SettingMainPage />,
    state: "alert_customisation",
    sidebarProps: {
      displayText: "Alert Customization",
      icon: <SettingsSuggestIcon />,
    },
  },

  {
    path: "/Customization",
    element: <SystemParameters />,
    state: "customization",
    sidebarProps: {
      displayText: "Configurations",
      icon: <TablerListDetails style={{ fontSize: "26px" }} />,
    },
    child: [
      {
        path: "/Customization/facility",
        element: <FacilityTable />,
        state: "customization",
        sidebarProps: {
          displayText: "Facility",
          icon: <StoreIcon />,
        },
      },
      {
        path: "/Customization/camera",
        element: <CameraTable />,
        state: "customization",
        sidebarProps: {
          displayText: "Cameras",
          icon: <MdiCctv style={{ fontSize: "26px" }} />,
        },
      },
    ],
  },
];

export default appRoutes;
