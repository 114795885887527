import React, { useEffect, useState } from "react";
import AlertTableCard from "./AlertTableCard";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { getTableData } from "../Alert/AlertChartData";
import EventsCard from "../Events/EventTable/EventsCard";
import AlertTableAction from "../../redux/Action/AlertTableActions";
import Account from "../../redux/Action/CommonAction";
import AlertTableActions from "../../redux/Action/AlertTableActions";
import useWindowDimensions from "./../../components/common/useWindowDimensions";
import VmsHelperActions from "../../redux/Action/VmsHelperActions";
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff";

import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Paper,
} from "@mui/material";
// import {
//   updateEventsObjByAccount,
//   updateEventsObjByStore,
//   updateEventsObjByCamera,
// } from "./../../redux/Action/EventChartAction";
import ModalLoader from "../../components/common/Loader/ModalLoader";
const AlertTableTabs = () => {
  const dispatch = useDispatch();
  const alertCardLoading = useSelector(
    (state) => state.AlertTableStates.loadingTableData
  );
  const state = useSelector((state) => state);
  const [selectionRange, setselectionRange] = useState({
    startDate: new Date(state.commonData.dateRange.startDate),
    endDate: new Date(state.commonData.dateRange.endDate),
    key: "selection",
  });
  const { width } = useWindowDimensions();
  useEffect(() => {
    setselectionRange({
      startDate: new Date(state.commonData.dateRange.startDate),
      endDate: new Date(state.commonData.dateRange.endDate),
      key: "selection",
    });
  }, [state.commonData.dateRange]);
  useEffect(() => {
    dispatch(VmsHelperActions.getAllFacilitiesCameraForPlayback(profileId));
    return () => {
      dispatch(AlertTableAction.updateTabValue([false, false]));
      dispatch(AlertTableAction.updateTabName("alert"));
    };
  }, []);
  const [time] = useState(state.AlertTableStates.timeRange);
  const [profileId] = useState(JSON.parse(localStorage.getItem("profile_id")));
  const [access_permission] = useState(
    JSON.parse(localStorage.getItem("agx_permission")).Alerts ?? []
  );
  const [tabname, settabName] = useState(
    profileId === 63 ? "resolved" : "alert"
  );
  const pageSizeLocal = localStorage.getItem("pageSize");
  //If profileId is 63(DPS) then set active tab to "resolved"
  const [tabValue, settabValue] = useState(
    profileId === 63 ? [false, true] : [false, false]
  );

  // const handleUpdateEvent = (data, item) => {
  //   if (data == "change_event") {
  //     const updateItem = {
  //       ...item,
  //       facility: item.facility[1],
  //       camera: item.camera[1],
  //     };
  //     updateObjEvents(updateItem);
  //   }
  //   if (data == "modify_event_operation_mode") {
  //     const updateItem = {
  //       ...item,
  //     };
  //     updateObjEvents(updateItem);
  //   } else if (data == "remark") {
  //     const updatedItem = {
  //       ...item,
  //       facility: item.facility[1],
  //       camera: item.camera[1],
  //     };
  //     updateObjEvents(updatedItem);
  //   } else if (data == "resolve") {
  //     const updatedItem = {
  //       ...item,
  //       is_resolved: true,
  //       facility: item.facility[1],
  //       camera: item.camera[1],
  //     };
  //     updateObjEvents(updatedItem);
  //   } else if (data == "revert") {
  //     const updatedItem = {
  //       ...item,
  //       is_resolved: false,
  //       is_false: false,
  //       facility: item.facility[1],
  //       camera: item.camera[1],
  //     };
  //     updateObjEvents(updatedItem);
  //   } else if (data == "dismiss") {
  //     const updatedItem = {
  //       ...item,
  //       is_false: true,
  //       facility: item.facility[1],
  //       camera: item.camera[1],
  //     };
  //     updateObjEvents(updatedItem);
  //   }
  // };
  const getTableDataFunc = (showLoading) => {
    getTableData(
      state.commonData.alerteventDate,
      state.AlertTableStates.selectedMode.value,
      state.AlertTableStates.selectedAlertType.value,
      state.AlertTableStates.selectedFacility.value,
      state.AlertTableStates.selectedDevice.value,
      state.AlertTableStates.timeRange,
      selectionRange,
      state.AlertTableStates.currentPage,
      state.AlertTableStates.tabValue,
      state.AlertTableStates.searchValue,
      state.commonData.pageSize,
      state.AlertTableStates.selectedUseCase,
      state.AlertTableStates.selectedPriority.value,
      state.AlertTableStates.alertOrEvent,
      dispatch,
      showLoading
    );
  };
  useEffect(() => {
    getTableDataFunc(true);
  }, [
    tabValue,
    state.commonData.pageSize,
    state.commonData.alerteventDate,
    state.AlertTableStates.selectedMode,
    state.AlertTableStates.selectedDevice.value,
    state.AlertTableStates.selectedAlertType.value,
    state.AlertTableStates.selectedFacility.value,
    state.AlertTableStates.selectedAlertType.value,
    state.AlertTableStates.timeRange,
    state.AlertTableStates.currentPage,
    state.AlertTableStates.searchValue,
    state.AlertTableStates.selectedUseCase,
    state.AlertTableStates.selectedPriority.value,
  ]);

  const updateObjAlerts = async (data, id) => {
    await AlertTableAction.updateAlertEventPatch(data, id, "alert");
    getTableDataFunc(false);
  };
  const updateObjEvents = async (data, id) => {
    await AlertTableAction.updateAlertEventPatch(data, id, "event");
    getTableDataFunc(false);
  };
  const tabToggled = (newValue) => {
    // dispatch({ type: "TABLE_DATA_ALERT", payload: { data: [], count: 0 } });
    if (newValue === "alert") {
      settabValue([false, false]);
      dispatch(AlertTableAction.updateTabValue([false, false]));
      dispatch(AlertTableAction.updateTabName(newValue));
    } else if (newValue === "dismissed") {
      settabValue([true, false]);
      dispatch(AlertTableAction.updateTabValue([true, false]));
      dispatch(AlertTableAction.updateTabName(newValue));
    } else if (newValue === "resolved") {
      settabValue([false, true]);
      dispatch(AlertTableAction.updateTabValue([false, true]));
      dispatch(AlertTableAction.updateTabName(newValue));
    }
  };
  const [selectedTab, setSelectedTab] = useState("alert");

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
    tabToggled(newValue);
  };

  function handleGridChange(event) {
    let grid = event.target.value;
    dispatch(Account.updatePageSize(grid));
    localStorage.setItem("pageSize", grid);
    dispatch(AlertTableActions.updateCurrentPage(1));
  }

  return (
    <Paper
      elevation={0}
      className="custom-tab"
      sx={{
        backgroundColor: "transparent",
        color: "var(--side-item-color)",
      }}
    >
      <Box sx={{ marginTop: "10px", paddingX: "15px" }}>
        <Box
          sx={{
            // borderBottom: 1,
            backgroundColor: "transparent",
            color: "var(--black-white-darkmode)",
          }}
          className="row "
        >
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            aria-label="event tabs"
            className="col-12 col-md-10"
          >
            <Tab
              value="alert"
              label={`Alert ${
                selectedTab === "alert" && !alertCardLoading
                  ? "(" + state.AlertData.tableAlertCount + ")"
                  : ""
              }`}
              sx={{
                color: selectedTab !== "alert" && "var(--black-white-darkmode)",
              }}
            />
            {access_permission.includes("view_dismissed_alerts") && (
              <Tab
                value="dismissed"
                label={`Dismissed ${
                  selectedTab === "dismissed" && !alertCardLoading
                    ? "(" + state.AlertData.tableAlertCount + ")"
                    : ""
                }`}
                sx={{
                  color:
                    selectedTab !== "dismissed" &&
                    "var(--black-white-darkmode)",
                }}
              />
            )}
            {access_permission.includes("view_resolved_alerts") && (
              <Tab
                value="resolved"
                label={`Resolved ${
                  selectedTab === "resolved" && !alertCardLoading
                    ? "(" + state.AlertData.tableAlertCount + ")"
                    : ""
                }`}
                sx={{
                  color:
                    selectedTab !== "resolved" && "var(--black-white-darkmode)",
                }}
              />
            )}
          </Tabs>
          <Box
            sx={{
              width: 80,
              paddingTop: "10px",
              marginLeft: "auto",
              marginRight: "10px",
            }}
            className="col-12 col-md-2"
          >
            <FormControl>
              <InputLabel id="demo-simple-select-label">Grid</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={state.commonData.pageSize}
                label="No of Grid"
                onChange={handleGridChange}
                sx={{ height: "30px" }}
              >
                <MenuItem value={12}>12</MenuItem>
                <MenuItem value={24}>24</MenuItem>
                <MenuItem value={36}>36</MenuItem>
                <MenuItem value={48}>48</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
        {alertCardLoading ? (
          <ModalLoader open={alertCardLoading} />
        ) : !alertCardLoading && state.AlertData.alertTableData.length == 0 ? (
          <div
            className="col-12 d-flex justify-content-center align-items-center"
            style={{ marginTop: "10%" }}
          >
            <div className="d-flex justify-content-center align-items-center">
              <div>
                <center>
                  <CreditCardOffIcon
                    sx={{
                      fontSize: "100px",
                      color: "var(--black-white-darkmode)",
                    }}
                  />
                </center>
                <h2 style={{ color: "var(--black-white-darkmode)" }}>
                  No Alerts to show{" "}
                </h2>
              </div>
            </div>
          </div>
        ) : (
          <div>
            {selectedTab === "alert" && (
              <div className="row">
                {state.AlertData.alertTableData.map((item) => {
                  return (
                    <div
                      className={
                        width > 4000
                          ? "col-1"
                          : width > 1800
                          ? "col-2"
                          : "col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-md-12 col-sm-12"
                      }
                      key={item.id}
                    >
                      {item.notification_type == "alerts" ? (
                        <AlertTableCard
                          incrementcount={updateObjAlerts}
                          title="alert"
                          item={item}
                        />
                      ) : (
                        <EventsCard
                          incrementCount={updateObjEvents}
                          title="event"
                          item={item}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            )}
            {selectedTab === "dismissed" && (
              <div className="row">
                {state.AlertData.alertTableData.map((item) => {
                  return (
                    <div
                      className={
                        width > 4000
                          ? "col-1"
                          : width > 1800
                          ? "col-2"
                          : "col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-12"
                      }
                      key={item.id}
                    >
                      {item.notification_type == "alerts" ? (
                        <AlertTableCard
                          incrementcount={updateObjAlerts}
                          title="dissmiss"
                          item={item}
                        />
                      ) : (
                        <EventsCard
                          incrementCount={updateObjEvents}
                          title="dissmiss"
                          item={item}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            )}
            {selectedTab === "resolved" && (
              <div className="row">
                {" "}
                {state.AlertData.alertTableData.map((item) => {
                  return (
                    <div
                      className={
                        width > 4000
                          ? "col-1"
                          : width > 1800
                          ? "col-2"
                          : "col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-12"
                      }
                      key={item.id}
                    >
                      {item.notification_type == "alerts" ? (
                        <AlertTableCard
                          incrementcount={updateObjAlerts}
                          title="dissmiss"
                          item={item}
                        />
                      ) : (
                        <EventsCard
                          incrementCount={updateObjEvents}
                          title="dissmiss"
                          item={item}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </Box>
    </Paper>
  );
};

export default AlertTableTabs;
