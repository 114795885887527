import moment from "moment";
import * as DateFns from "date-fns";
const initialState = {
  totalAlertHourly: [],
  resolvedAlertHourly: [],
  unresolvedAlertHourly: [],
  totalWeeklyAlerts: [],
  topSevenSiteAlertData: [],
  tenSitesAlerts: [],
  totalAlertTypeData: [],
  alertTableData: [],
  tableAlertCount: 0,
  alertTypeStatics: [],
  totalAlertDaywise: [],
  resolvedAlertDaywise: [],
  unresolvedAlertDaywise: [],
  topSevenSiteAlertDataDateRange: [],
  totalAlertTypeDataDateRange: [],
  tenSitesAlertsDateRange: [],
  yumFoodsChartData: [],
  yumFoodsChartDataDateRange: [],
};

export default function AlertReducer(state = initialState, action) {
  switch (action.type) {
    case "TOTAL_ALERT_HOURLY":
      return {
        ...state,
        totalAlertHourly: action.payload.map((datapoint) => {
          let dater = DateFns.setHours(
            new Date(action.date),
            new Date(
              datapoint.alert_recieved_at__hour ??
                datapoint.event_recieved_at__hour
            )
          );
          datapoint.date = moment(dater)
            .subtract(30, "minutes")
            .toLocaleString();
          delete datapoint.alert_recieved_at__hour ??
            datapoint.event_recieved_at__hour;
          return datapoint;
        }),
      };
    case "TOTAL_ALERT_DATE_RANGE":
      return {
        ...state,
        totalAlertDaywise: action.payload.map((datapoint) => {
          datapoint.date = new Date(
            datapoint.alert_recieved_at__date ??
              datapoint.event_recieved_at__date
          ).toISOString();
          delete datapoint.alert_recieved_at__date ??
            datapoint.event_recieved_at__date;
          return datapoint;
        }),
      };
    case "RESOLVED_ALERT_DATE_RANGE":
      return {
        ...state,
        resolvedAlertDaywise: action.payload.map((datapoint) => {
          datapoint.date = new Date(
            datapoint.alert_recieved_at__date ??
              datapoint.event_recieved_at__date
          ).toISOString();
          delete datapoint.alert_recieved_at__date ??
            datapoint.event_recieved_at__date;
          return datapoint;
        }),
      };
    case "UNRESOLVED_ALERT_DATE_RANGE":
      return {
        ...state,
        unresolvedAlertDaywise: action.payload.map((datapoint) => {
          datapoint.date = new Date(
            datapoint.alert_recieved_at__date ??
              datapoint.event_recieved_at__date
          ).toISOString();
          delete datapoint.alert_recieved_at__date ??
            datapoint.event_recieved_at__date;
          return datapoint;
        }),
      };
    case "RESOLVED_ALERT_HOURLY":
      return {
        ...state,
        resolvedAlertHourly: action.payload.map((datapoint) => {
          let dater = DateFns.setHours(
            new Date(action.date),
            new Date(
              datapoint.alert_recieved_at__hour ??
                datapoint.event_recieved_at__hour
            )
          );
          datapoint.date = moment(dater)
            .subtract(30, "minutes")
            .toLocaleString();
          delete datapoint.alert_recieved_at__hour ??
            datapoint.event_recieved_at__hour;
          return datapoint;
        }),
      };
    case "UNRESOLVED_ALERT_HOURLY":
      return {
        ...state,
        unresolvedAlertHourly: action.payload.map((datapoint) => {
          let dater = DateFns.setHours(
            new Date(action.date),
            new Date(
              datapoint.alert_recieved_at__hour ??
                datapoint.event_recieved_at__hour
            )
          );
          datapoint.date = moment(dater)
            .subtract(30, "minutes")
            .toLocaleString();
          delete datapoint.alert_recieved_at__hour ??
            datapoint.event_recieved_at__hour;
          return datapoint;
        }),
      };
    case "ALERT_TYPE_STATICS_HOURLY":
      return {
        ...state,
        alertTypeStatics: action.payload.map((datapoint) => {
          let dater = DateFns.setHours(
            new Date(action.date),
            new Date(
              datapoint.alert_recieved_at__hour ??
                datapoint.event_recieved_at__hour
            )
          );
          datapoint.date = moment(dater)
            .subtract(30, "minutes")
            .toLocaleString();
          delete datapoint.alert_recieved_at__hour ??
            datapoint.event_recieved_at__hour;
          return datapoint;
        }),
      };
    case "TOTAL_WEEKLY_ALERTS":
      return {
        ...state,
        totalWeeklyAlerts: action.payload.map((datapoint) => {
          datapoint.date = new Date(
            datapoint.alert_recieved_at__date ??
              datapoint.event_recieved_at__date
          ).toISOString();
          return datapoint;
        }),
      };
    case "TOP_SEVEN_SITE_ALERTS":
      return {
        ...state,
        topSevenSiteAlertData: action.payload,
      };
    case "TOP_SEVEN_SITE_ALERTS_DATE_RANGE":
      return {
        ...state,
        topSevenSiteAlertDataDateRange: action.payload,
      };

    case "TOP_TEN_SITES_ALERTS":
      return {
        ...state,
        tenSitesAlerts: action.payload,
      };
    case "TOP_TEN_SITES_ALERTS_DATE_RANGE":
      return {
        ...state,
        tenSitesAlertsDateRange: action.payload,
      };

    case "TOTAL_ALERTS_TYPE_DATA":
      return {
        ...state,
        totalAlertTypeData: action.payload,
      };
    case "TOTAL_ALERTS_TYPE_DATA_DATE_RANGE":
      return {
        ...state,
        totalAlertTypeDataDateRange: action.payload,
      };
    case "TABLE_DATA_ALERT":
      return {
        ...state,
        alertTableData: action.payload.data,
        tableAlertCount: action.payload.count,
      };
    case "YUM_FOODS_CHART_DATA":
      return {
        ...state,
        yumFoodsChartData: action.payload.map((datapoint) => {
          let dater = DateFns.setHours(
            new Date(action.date),
            new Date(datapoint.received_at_hour)
          );
          datapoint.date = moment(dater)
            .subtract(30, "minutes")
            .toLocaleString();
          delete datapoint.received_at_hour;
          return datapoint;
        }),
      };
    case "YUM_FOODS_CHART_DATA_DATE_RANGE":
      return {
        ...state,
        yumFoodsChartDataDateRange: action.payload.map((datapoint) => {
          datapoint.date = new Date(datapoint.received_at_date).toISOString();
          return datapoint;
        }),
      };

    default:
      return state;
  }
}
